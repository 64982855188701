import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { LiveAnnouncer } from "react-aria-live";
import { useHistory, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

// import logger from "Libs/logger";
import { addUIKitToTheme } from "Libs/themes";

import useDecodedParams from "Hooks/useDecodedParams";
import NavBar from "Containers/NavBar";
import SkipToContentLink from "./components/SkipToContentLink";
import ErrorBoundary from "Components/ErrorBoundary";
import GlobalStyles from "../../global-style";
import PageLayout from "Components/PageLayout";

import withReducers from "../../common/hocs/withReducers";

const CreateProjectLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > header.create-project {
    flex: 0 0;
    min-height: auto;
  }
  > main.create-project#main {
    flex: 1 0;
    min-height: auto;
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  #page-header {
    min-height: 98px;
  }

  .version {
    margin-top: auto;
  }
`;

const App = ({ init, loadTheme, announcementsInit, me, theme, children }) => {
  const [userHasLoaded, setUserHasLoaded] = useState(false);
  const [localTheme, setLocalTheme] = useState({});

  const { push, replace } = useHistory();
  const { projectId, organizationId, environmentId } = useDecodedParams();
  const location = useLocation();

  useEffect(() => {
    const redirectUri = localStorage.getItem("auth-redirect-uri");
    if (redirectUri) {
      push(redirectUri);
      // Redirect only once
      localStorage.removeItem("auth-redirect-uri");
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      // We remove the code and state from the URL
      if (urlParams.has("code")) {
        replace("/");
      }
    }

    init();
    loadTheme();
    announcementsInit();
  }, []);

  useEffect(
    () => {
      if (me?.size > 1 && !userHasLoaded) {
        setUserHasLoaded(true);
        TagManager.dataLayer({
          dataLayer: {
            event: "userLoaded",
            user: me
          }
        });
      }
    },
    [me]
  );

  useEffect(
    () => {
      let isCanceled = false;
      async function getTheme() {
        const hybridTheme = await addUIKitToTheme(theme.toJS());
        if (isCanceled) {
          return;
        }
        setLocalTheme(hybridTheme);
      }
      if (theme) {
        getTheme();
      }

      return () => (isCanceled = true);
    },
    [theme]
  );

  if (!me || !theme) {
    return false;
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={localTheme}>
        <GlobalStyles />
        <LiveAnnouncer>
          <SkipToContentLink href="#main" tabIndex="0">
            Skip to main content
          </SkipToContentLink>

          {location.pathname.includes("/create-project") ? (
            <CreateProjectLayout>
              <NavBar
                push={push}
                projectId={projectId}
                organizationId={organizationId}
                environmentId={environmentId}
                role="navigation"
                currentPathName={location.pathname}
                user={me.toJS()}
                me={me}
              />
              <PageLayout className="create-project">{children}</PageLayout>
            </CreateProjectLayout>
          ) : (
            <>{children}</>
          )}
        </LiveAnnouncer>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = dispatch => ({
  init: () =>
    import("Reducers/app").then(appReducer => dispatch(appReducer.init())),
  loadTheme: () =>
    import("Reducers/app/theme").then(themeReducer =>
      dispatch(themeReducer.loadTheme())
    ),
  announcementsInit: () =>
    import("Reducers/announcements").then(announcementsReducer =>
      dispatch(announcementsReducer.init())
    )
});

const mapStateToProps = state => ({
  me: state.app.get("me"),
  theme: state.theme.get("data")
});

App.propTypes = {
  children: PropTypes.node,
  init: PropTypes.func,
  loadTheme: PropTypes.func,
  announcementsInit: PropTypes.func,
  me: PropTypes.object,
  theme: PropTypes.object,
  project: PropTypes.object,
  search: PropTypes.string,
  setSearch: PropTypes.func
};

export default withReducers({
  app: () => import("Reducers/app"),
  project: () => import("Reducers/project"),
  theme: () => import("Reducers/app/theme"),
  organization: () => import("Reducers/organization"),
  announcements: () => import("Reducers/announcements"),
  subscription: () => import("Reducers/subscription"),
  search: () => import("Reducers/app/search")
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
