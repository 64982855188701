import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";

import withReducers from "Hocs/withReducers";

import PageMeta from "Components/PageMeta";
import ErrorBoundary from "Components/ErrorBoundary";
import PageLayout from "Components/PageLayout";

import SubNavBarContainer from "Containers/SubNavBarContainer";
import NavBar from "Containers/NavBar";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const OrganizationSettings = ({ children }) => {
  const { organizationId } = useDecodedParams();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const intl = useIntl();

  return (
    <>
      <PageMeta title={intl.formatMessage({ id: "settings.documenttitle" })} />
      <NavBar
        push={push}
        organizationId={organizationId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout className="organization-settings" footer={false}>
        <SubNavBarContainer
          currentPathName={pathname}
          organizationId={organizationId}
        />
        <ErrorBoundary>
          <Layout>{children}</Layout>
        </ErrorBoundary>
      </PageLayout>
    </>
  );
};

OrganizationSettings.propTypes = {
  children: PropTypes.node.isRequired
};

export default withReducers({
  organization: () => import("Reducers/organization")
})(OrganizationSettings);
