import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { DOCS_METRICS_DEDICATED } from "Constants/documentationUrls";
import InfoDialog from "Components/InfoDialog";
import useMediaQuery from "Hooks/useMediaQuery";

import CardSkeleton from "../components/CardSkeleton";
import Chart from "../components/Chart";

import formatBytes from "../utils/formatBytes";
import formatPercentage from "../utils/formatPercentage";

import * as S from "../styles";

const getTextMessage = (mountpoint, intl) => {
  let id = "metrics.disk.dialog.text.generic";

  if (mountpoint === "/") {
    id = "metrics.disk.dialog.text.root";
  } else if (mountpoint === "/data/exports") {
    id = "metrics.disk.dialog.text.exports";
  }

  return intl.formatMessage({ id }, { mountpoint });
};

const Disk = ({
  hosts,
  isLoading,
  data,
  diskPerRow,
  mountpoint,
  timeframe,
  onBrush
}) => {
  const intl = useIntl();
  const showAlternateInfoLayout = useMediaQuery(
    "(min-width: 1240px) and (max-width: 1350px)"
  );

  if (isLoading || !data) {
    return <CardSkeleton diskPerRow={diskPerRow} Wrapper={S.DiskLayout} />;
  }

  return (
    <S.DiskLayout diskPerRow={diskPerRow}>
      <S.Container style={{ margin: 0 }}>
        <S.ChartHeading>
          {showAlternateInfoLayout ? (
            <S.DiskRow>
              <S.ChartTitle>
                {intl.formatMessage({ id: "metrics.disk.title" })}
              </S.ChartTitle>{" "}
              <S.Mountpoint>{mountpoint}</S.Mountpoint>
              <S.InfoDialogWrapper>
                <InfoDialog
                  title={intl.formatMessage(
                    { id: "metrics.disk.dialog.title" },
                    { mountpoint }
                  )}
                  text={getTextMessage(mountpoint, intl)}
                  to={DOCS_METRICS_DEDICATED}
                  linkText="Learn more"
                />
              </S.InfoDialogWrapper>
              <S.DiskPercentage>
                {formatPercentage(data.last[`average@percentage`])}
              </S.DiskPercentage>
            </S.DiskRow>
          ) : (
            <S.Row>
              <S.ChartTitle>
                {intl.formatMessage({ id: "metrics.disk.title" })}
              </S.ChartTitle>{" "}
              <S.Mountpoint>{mountpoint}</S.Mountpoint>
              <S.InfoDialogWrapper>
                <InfoDialog
                  title={intl.formatMessage(
                    { id: "metrics.disk.dialog.title" },
                    { mountpoint }
                  )}
                  text={getTextMessage(mountpoint, intl)}
                  to={DOCS_METRICS_DEDICATED}
                  linkText="Learn more"
                />
              </S.InfoDialogWrapper>
            </S.Row>
          )}

          {showAlternateInfoLayout ? (
            <S.DiskInfo>
              <S.Current>
                {formatBytes(data.last[`average@value`]?.toFixed(2) || 0, "GB")}
              </S.Current>/{formatBytes(data.last[`max`] || 0, "GB")}{" "}
              <S.Description as="span">
                {intl.formatMessage({ id: "metrics.currentaverage" })}
              </S.Description>
            </S.DiskInfo>
          ) : (
            <S.Info>
              <S.Current>
                {formatBytes(data.last[`average@value`]?.toFixed(2) || 0, "GB")}
              </S.Current>/{formatBytes(data.last[`max`] || 0, "GB")}{" "}
              <S.Percentage>
                {formatPercentage(data.last[`average@percentage`])}
              </S.Percentage>
              <S.Description>
                {intl.formatMessage({ id: "metrics.currentaverage" })}
              </S.Description>
            </S.Info>
          )}
        </S.ChartHeading>
        <Chart
          yFormatter={value => formatBytes(value, "GB")}
          tooltipFormatter={value => formatBytes(value, "GB")}
          hosts={hosts}
          data={data.data}
          max={data.max}
          timeframe={timeframe}
          onBrush={onBrush}
        />
      </S.Container>
    </S.DiskLayout>
  );
};

Disk.propTypes = {
  mountpoint: PropTypes.string,
  hosts: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  diskPerRow: PropTypes.number,
  queryParams: PropTypes.object,
  timeframe: PropTypes.object,
  onBrush: PropTypes.func
};

export default Disk;
