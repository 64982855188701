import React from "react";
import PropTypes from "prop-types";

import { Skeleton, Square } from "Components/Skeleton";
import * as S from "./CardSkeleton.styles";

const CardSkeleton = ({ Wrapper, ...props }) => {
  const Layout = Wrapper || React.Fragment;

  return (
    <Layout {...props}>
      <S.Layout hasWrapper={!!Wrapper}>
        <Skeleton synchronize>
          <S.Row>
            <Square height="24px" width="64px" />
            <S.Content>
              <Square height="24px" width="110px" />
              <Square height="24px" width="32px" margin="0 0 0 12px" />
            </S.Content>
          </S.Row>
          <Square height="206px" width="100%" margin="16px 0 0 0" />
        </Skeleton>
      </S.Layout>
    </Layout>
  );
};

CardSkeleton.propTypes = {
  Wrapper: PropTypes.node
};

export default CardSkeleton;
