import styled, { css } from "styled-components";

import Description from "Components/fields/Description";

export const Layout = styled.div`
  margin-bottom: 16px;
  margin-right: 32px;
  max-width: 200px;
  width: 100%;
`;

export const FileInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

export const FileDescription = styled(Description)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  margin-top: 32px;
  color: ${props => props.theme.label};
`;

export const PreviewWrapper = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 32px 18px 0;
  box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
  border-radius: 2px;
  height: 200px;

  ${props =>
    props.empty &&
    css`
      svg {
        width: 80px;
        height: auto;
      }
    `};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
