import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import copyToClipboard from "copy-to-clipboard";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getOrganizationDescriptionIdFromProject,
  interpolateURL
} from "Libs/utils";
import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import IconMore from "Icons/IconMore";
import ActionDropdown from "Components/ActionDropdown";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

import { organizationsSelector } from "Reducers/organization";
import { deleteSubscription } from "Reducers/subscription";

import * as S from "./styles";

const ProjectMoreLinks = ({
  iconColor = "white",
  from,
  subscription,
  toggleProjectWizard
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [modalIsOpen, setOpenModal] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const subscriptionStatus = useSelector(({ subscription }) =>
    subscription?.get("status")
  );
  const organizations = useSelector(organizationsSelector);

  useEffect(
    () => {
      let isCanceled = false;
      let timer;
      if (isCopied) {
        timer = setTimeout(() => !isCanceled && setIsCopied(false), 500);
      }
      return () => {
        isCanceled = true;
        clearTimeout(timer);
      };
    },
    [isCopied]
  );

  useEffect(
    () => {
      if (subscriptionStatus === "deleted")
        window.location.replace(window.location.origin);
    },
    [subscriptionStatus]
  );

  const copyId = e => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    setIsCopied(true);
    copyToClipboard(subscription.project_id);
  };

  const openModal = e => {
    if (e) e.preventDefault();
    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId: subscription.project_id }
      );
    } else {
      setOpenModal(true);
    }
  };

  const handleDelete = e => {
    e?.preventDefault();

    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId: subscription.project_id }
      );
    } else {
      dispatch(deleteSubscription({ subscription }));
    }
  };

  const hasPermission =
    subscription?.hasPermission &&
    subscription.hasPermission("#manage-subscription");

  if (!hasPermission && !toggleProjectWizard) {
    return false;
  }

  const organizationId = getOrganizationDescriptionIdFromProject(
    subscription,
    organizations.toJS()
  );
  const subscriptionEditUrl = process.env.ENABLE_CUSTOM_SUBSCRIPTION_URL
    ? subscription && subscription.data._links.plan_uri.href
    : `/-/users/${organizationId}/billing/plan/${subscription.id}`;

  const isSubscriptionEditExternal = /^https?:\/\//.test(subscriptionEditUrl);

  return (
    <S.Wrapper>
      <ActionDropdown
        icon={<IconMore color={iconColor} />}
        ariaLabel="More"
        withArrow={false}
        className="more"
        id={subscription.id}
      >
        <ul>
          <li role="menuitem">
            <S.IdProject onClick={copyId}>
              {intl.formatMessage(
                { id: "project.morelinks.id", defaultMessage: "ID {id}" },
                {
                  id: subscription.project_id
                }
              )}
              <S.Icon>
                {isCopied ? (
                  <SuccessStateIcon aria-hidden="true" />
                ) : (
                  <CopyIcon aria-hidden="true" />
                )}
              </S.Icon>
            </S.IdProject>
          </li>

          {hasPermission &&
            subscriptionEditUrl && (
              <li role="menuitem">
                {isSubscriptionEditExternal ? (
                  <a href={subscriptionEditUrl}>
                    {intl.formatMessage({
                      id: "project.morelinks.edit",
                      defaultMessage: "Edit plan"
                    })}
                  </a>
                ) : (
                  <Link
                    to={{
                      pathname: subscriptionEditUrl,
                      state: { from }
                    }}
                  >
                    {intl.formatMessage({
                      id: "project.morelinks.edit",
                      defaultMessage: "Edit plan"
                    })}
                  </Link>
                )}
              </li>
            )}

          {toggleProjectWizard && (
            <li role="menuitem">
              <S.Button onClick={toggleProjectWizard}>
                {intl.formatMessage({
                  id: "project.morelinks.wizard",
                  defaultMessage: "Setup wizard"
                })}
              </S.Button>
            </li>
          )}

          {hasPermission && (
            <li role="menuitem">
              <S.Button onClick={openModal}>
                {intl.formatMessage({
                  id: "project.morelinks.delete",
                  defaultMessage: "Delete"
                })}
              </S.Button>
            </li>
          )}
        </ul>
      </ActionDropdown>

      <ModalConfirmDelete
        title={intl.formatMessage({
          id: "project.morelinks.delete_modal.title",
          defaultMessage: "Delete project"
        })}
        deleteFunction={handleDelete}
        isOpen={modalIsOpen}
        closeModal={() => setOpenModal(false)}
        itemId={`subscription-${subscription.project_id}`}
        size="medium"
        body={intl.formatMessage(
          {
            id: "project.morelinks.delete_modal.sentence"
          },
          {
            sub: subscription.project_title,
            br: <br />,
            b: (...chunks) => <strong>{chunks}</strong> //eslint-disable-line
          }
        )}
      />
    </S.Wrapper>
  );
};

ProjectMoreLinks.propTypes = {
  iconColor: PropTypes.string,
  from: PropTypes.string,
  project: PropTypes.object,
  subscription: PropTypes.object,
  toggleProjectWizard: PropTypes.func
};

export default ProjectMoreLinks;
