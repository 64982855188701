import React from "react";

import Overview from "./containers/Overview";

class OverviewPage extends React.Component {
  render() {
    return <Overview {...this.props} />;
  }
}

export default OverviewPage;
