import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { projectSelector } from "Reducers/project";

import LockIcon from "Icons/LockIcon";
import Screenshot from "../../../../common/components/Screenshot";
import ProjectMoreLinks from "../../components/ProjectMoreLinks";
import ProjectListItem from "./ProjectListItem";

import { getRegionLabel, getOwnerInfoLabel } from "Libs/utils";

import * as S from "./styles";

const ProjectCard = ({
  children,
  gridLayout,
  organizationId,
  subscription,
  organizations
}) => {
  const intl = useIntl();
  let isWarning = false;
  const me = useSelector(state => state.app.get("me"));

  const regionsByUrl = useSelector(state =>
    state.region?.get("byUrl", new Map())
  );

  const region = (() => {
    const regionLabel = regionsByUrl?.get(subscription?.project_region);
    return getRegionLabel(regionLabel);
  })();

  const hasGitProjectAccess = useSelector(state =>
    projectSelector(state, {
      organizationId,
      projectId: subscription.project_id
    })
  );

  return (
    <S.ProjectCardLayout
      className={gridLayout ? "project-card" : "project-list-item"}
    >
      {gridLayout ? (
        <Link
          to={`/${organizationId}/${subscription.project_id}`}
          id={`project-card-${subscription.project_id}`}
        >
          <S.ScreenshotWrapper>
            <Screenshot
              imagePath={`/${subscription.project_id}/${
                /*project?.default_branch ||*/ "master"
              }/screenshot.png`}
            />
          </S.ScreenshotWrapper>

          <S.Details>
            <S.Row>
              <S.Org>
                {getOwnerInfoLabel(subscription, organizations?.toJS())}
              </S.Org>

              {subscription && (
                <ProjectMoreLinks from="projects" subscription={subscription} />
              )}
            </S.Row>

            <S.ProjectName>
              {subscription?.project_title ||
                intl.formatMessage({ id: "no_title" })}
            </S.ProjectName>

            <S.Row>
              <S.Region>
                {region && (
                  <>
                    {region.title}
                    <S.RegionSuffix>{region.suffix}</S.RegionSuffix>
                  </>
                )}
              </S.Region>

              {!hasGitProjectAccess && (
                <S.NoAccessTag>
                  <div style={{ width: 22, height: 13, marginRight: 4 }}>
                    <LockIcon />
                  </div>
                  {intl.formatMessage({
                    id: "project.card.tag.no-access",
                    defaultMessage: "No Access"
                  })}
                </S.NoAccessTag>
              )}

              {hasGitProjectAccess && subscription?.status !== "active" ? (
                <S.Tag>
                  {intl.formatMessage({
                    id: "project.card.tag.suspended",
                    defaultMessage: "Suspended"
                  })}
                </S.Tag>
              ) : (
                subscription?.plan?.startsWith("trial") && (
                  <S.Trial>
                    {intl.formatMessage({
                      id: "project.card.tag.trial",
                      defaultMessage: "Free trial"
                    })}
                  </S.Trial>
                )
              )}
            </S.Row>
          </S.Details>
          {children}
        </Link>
      ) : (
        <ProjectListItem
          hasWarning={isWarning}
          organizationId={organizationId}
          subscription={subscription}
          region={region}
          organizations={organizations}
          me={me}
        />
      )}
    </S.ProjectCardLayout>
  );
};

ProjectCard.propTypes = {
  children: PropTypes.node,
  gridLayout: PropTypes.bool,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  organizations: PropTypes.object,
  subscription: PropTypes.object
};

export default ProjectCard;
