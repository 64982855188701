import styled from "styled-components";

import { themeHelpers } from "Libs/theme";

import getWizardImages from "../img";
import Button from "UI/Button";

export const Wrapper = styled.div`
  display: flex;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29% 32px 32px 32px;
  background: transparent url(${props => getWizardImages(props.theme).endImage})
    0 0 / 100% no-repeat;

  h2 {
    margin-bottom: 8px;
    line-height: 33px;
  }

  p {
    flex: 1;
    margin: 0 0 5px;
    color: ${props => props.theme.sectionText};
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 900px) {
    padding-top: 19%;
  }

  @media (min-width: 1280px) {
    padding-top: 180px;
  }

  @media (min-width: 1680px) {
    padding-top: 236px;
  }

  @media (min-width: 1920px) {
    padding-top: 214px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  margin-top: 32px;
  align-items: center;

  ${Button} {
    padding: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    line-height: 24px;
    span {
      transition: all 0.1s ease;
    }
    &:hover {
      color: ${props => themeHelpers(props.theme, "buttonHover")};
      svg path {
        fill: ${props => themeHelpers(props.theme, "buttonHover")};
      }
      span {
        margin-left: 10px;
      }
      &:after {
        display: none;
      }
    }
    &:focus {
      margin: -3px -1px -1px;
    }
    &:active {
      margin: 0;
    }
    &.back-link {
      position: relative;
      margin-right: 25px;
      padding: 0;
      &:hover span {
        margin-left: -10px;
        margin-right: 10px;
      }
      &:after {
        display: inline-block;
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        height: 24px;
        width: 1px;
        content: "";
        background: ${props => props.theme.environmentTreeLine};
      }
    }
  }
`;
