import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { themeHelpers } from "Libs/theme";
import Label from "Components/fields/Label";
import Link from "../Link";

const ConnectedLabel = styled(Label)`
  margin-top: 8px;

  @media (min-width: 768px) {
    flex-grow: 1;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: flex-start;
  background: ${props => props.theme.sectionBg};
  border-radius: 4px;
  padding: 18px 24px;
  margin-bottom: 8px;

  &:hover {
    background: ${props =>
      props.theme.name === "dark"
        ? props.theme.dark
        : themeHelpers(props.theme, "linkHover")};
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0px 10px 20px rgba(75, 97, 128, 0.08)"};
  }
  @media (min-width: 768px) {
    align-items: center;
    padding: 10px 24px;
  }
`;

const Info = styled.div`
  margin-left: 32px;
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

const ProviderName = styled.span`
  font-weight: 400;

  & strong {
    font-weight: 700;
  }
  @media (min-width: 768px) {
    min-width: 260px;
  }
`;

const ConnectedAccountLine = ({
  provider,
  connectedAccount,
  disconnectAccount,
  isLoading
}) => {
  return (
    <Line>
      <img src={provider.image} alt={`${provider.name}-logo`} />
      <Info>
        <ProviderName id={`${provider.name}-label`}>
          <FormattedMessage id="provider" /> <strong>{provider.label}</strong>
        </ProviderName>
        <ConnectedLabel id={`${provider.name}-connected-info`}>
          {connectedAccount ? (
            <FormattedMessage id="connected" />
          ) : (
            <FormattedMessage id="not_connected" />
          )}
        </ConnectedLabel>
        <Link
          disconnectAccount={disconnectAccount}
          provider={provider}
          isConnected={!!connectedAccount}
          isLoading={isLoading}
        />
      </Info>
    </Line>
  );
};

ConnectedAccountLine.propTypes = {
  provider: PropTypes.object,
  connectedAccount: PropTypes.object,
  disconnectAccount: PropTypes.func,
  isLoading: PropTypes.bool
};

export default ConnectedAccountLine;
