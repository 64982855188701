const KEY_VALUE_SEPARATOR = "=";
const PAIR_SEPARATOR = "/";

/**
 * Given a dimension string, transform it into a hash
 *
 * dimesionString format => `hostname=id-123/key=value`
 * { hostname: "id-123", key: "value" }
 *
 * @param {string} dimensionString
 * @return {object} the parsed dimension string
 */
const parseDimensions = dimensionString => {
  if (typeof dimensionString !== "string") {
    return undefined;
  }

  const pairs = dimensionString.split(PAIR_SEPARATOR);
  return pairs
    .filter(pair => !!pair)
    .map(pair => pair.split(KEY_VALUE_SEPARATOR))
    .filter(([key]) => !!key)
    .reduce((dimensions, [key, value]) => {
      dimensions[key] = decodeURIComponent(value);
      return dimensions;
    }, {});
};

export default parseDimensions;
