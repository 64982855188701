import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Button from "UI/Button";
import DeleteIcon from "Icons/DeleteIcon";
import UserIcon from "Icons/UserIcon";

import { Layout, PreviewWrapper, FileInput, FileDescription } from "./styles";

const ImageUpload = ({ username, picture, onChange, className }) => (
  <Layout className={className}>
    <PreviewWrapper empty={!picture}>
      {picture ? (
        <img data-test="user-picture" src={picture} alt={username} />
      ) : (
        <UserIcon color="#f0f2f5" />
      )}
    </PreviewWrapper>
    <Button as="label" id="image-upload-button" htmlFor="image-upload">
      <FormattedMessage id="settings.profile.choosefile" />
    </Button>
    <FileInput
      type="file"
      accept="image/*"
      id="image-upload"
      name="image-upload"
      onChange={event => {
        if (event?.target?.files.length) {
          onChange(event?.target?.files[0]);
        }
      }}
    />
    <FileDescription>
      <FormattedMessage id="settings.profile.maxfilesize" />
      <Button
        icon
        variant="secondary"
        data-test="delete-picture"
        onClick={() => onChange(null)}
      >
        <DeleteIcon />
      </Button>
    </FileDescription>
  </Layout>
);

ImageUpload.propTypes = {
  username: PropTypes.string,
  picture: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string
};

export default ImageUpload;
