import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import { isGitUrl, getOwnerInfoName } from "Libs/utils";
import logger from "Libs/logger";
import client from "Libs/platform";

import useInterval from "Hooks/useInterval";
import useTimeout from "Hooks/useTimeout";

import { addSubcription } from "Reducers/subscription";

import Heading2 from "Components/styleguide/Heading2";
import Error from "Components/Error";
import Spinner from "Components/Spinner";

import * as S from "./styles";

const SUBSCRIPTION_DEFAULTS = {
  storage: 5120,
  environments: 3,
  plan: "development"
};

const Building = ({ goToStep }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isPolling, setIsPolling] = useState(false);

  const organizations = useSelector(({ organization }) =>
    organization?.get("data", new Map())
  );
  const config = useSelector(({ setup }) => setup?.get("config"))?.toJS();
  const options = useSelector(({ setup }) =>
    setup?.getIn(["options", "data"], Map())
  );
  const status = useSelector(({ subscription }) => subscription?.get("status"));
  const errors = useSelector(({ subscription }) => subscription?.get("errors"));
  const lastAdded = useSelector(({ subscription }) =>
    subscription?.get("lastAdded")
  );

  useEffect(() => {
    if (config) {
      dispatch(addSubcription({ config: getConfig() }));
    } else {
      goToStep("/");
    }
  }, []);

  useEffect(
    () => {
      if (status === "added") {
        setIsPolling(true);
      }
    },
    [status]
  );

  useInterval(
    () => {
      getSubscription();
    },
    // Delay in milliseconds or null to stop it
    isPolling ? 10000 : null
  );

  useTimeout(
    () => {
      setIsPolling(false);
    },
    900000 // 15mins
  );

  // HOTFIX: currently the api doesn't return sort plan.
  // For this reason we searhc if we find a development plan
  // if we can't find, we take the first in the list or the default
  const getPlan = () => {
    const plans = options.get("plans");
    return (
      plans?.find(p => p.includes(SUBSCRIPTION_DEFAULTS.plan)) ||
      plans?.first() ||
      SUBSCRIPTION_DEFAULTS.plan
    );
  };

  const getConfig = () => {
    const { defaultBranch, region, template, title, organization } = config;
    let data = {
      defaultBranch,
      title,
      region: region.id,
      vendor: process.env.VENDOR_NAME || undefined,
      storage: SUBSCRIPTION_DEFAULTS.storage,
      environments: SUBSCRIPTION_DEFAULTS.environments,
      plan: getPlan()
    };

    if (template) {
      if (isGitUrl(template)) {
        data.optionsCustom = {
          initialize: {
            repository: template,
            profile: "Custom"
          }
        };
      } else {
        data.optionsUrl = template;
      }
    }
    if (process.env.ENABLE_ORGANIZATION) {
      data.organizationId = organization.value;
    }

    return data;
  };

  const getSubscription = async () => {
    try {
      const { organizationId } = getConfig();
      let subscription;
      // If we have an organization id and the organization flag is enabled
      // We need to call the organization API
      if (process.env.ENABLE_ORGANIZATION && organizationId) {
        subscription = await client.getOrganizationSubscription(
          organizationId,
          lastAdded
        );
      } else {
        subscription = await client.getSubscription(lastAdded);
      }
      const options = subscription.data.project_options.initialize;

      // If the project setup is complete proceed to the next
      // steps in the workflow.
      if (subscription.status === "active") {
        // @todo: possible remove this because we may not need
        // any project info outside the redirect.
        const redirect = () => {
          setIsPolling(false);
          window.location.href = `/${getOwnerInfoName(
            subscription,
            organizations.toJS()
          )}/${subscription.project_id}`;
        };

        // If there are no options listed skip the initialization process.
        if (!options) {
          redirect();
          return;
        }

        // No need to dispatch() and store these in redux because redirect()
        // will reset the state anyway.
        const project = await client.getProject(subscription.project_id);
        const defaultEnvironment = await client.getEnvironment(
          subscription.project_id,
          project.data.default_branch
        );
        if (defaultEnvironment.has_code) {
          redirect();
          return;
        }
        const activities = await client.getProjectActivities(
          subscription.project_id
        );
        const hasInitializeActivity =
          typeof activities.find(
            activity => activity.type === "environment.initialize"
          ) !== "undefined";
        if (hasInitializeActivity === true) {
          redirect();
          return;
        }
        client
          .initializeEnvironment(subscription.project_id, options)
          .then(() => {
            redirect();
          })
          .catch(err => {
            logger(err);
            redirect();
          });
      }
    } catch (err) {
      logger(err);
    }
  };

  return (
    <S.Wrapper>
      {!errors ? (
        <S.Loading>
          <Heading2>
            {intl.formatMessage({ id: "setup.building.title" })}
          </Heading2>
          <p>{intl.formatMessage({ id: "setup.building.description" })}</p>
          <p className="close-link">
            <Link to="/projects">{intl.formatMessage({ id: "close" })}</Link>
          </p>
          <Spinner alt="Building your project" />
        </S.Loading>
      ) : (
        <>
          <S.ErrorHelpText>
            {intl.formatMessage(
              { id: "setup.building.errors" },
              {
                count: errors?.length,
                link: txt => (
                  <a
                    href={
                      process.env.CUSTOM_SUPPORT_URL
                        ? process.env.CUSTOM_SUPPORT_URL
                        : `${process.env.ACCOUNTS_URL}/support`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {txt}
                  </a>
                )
              }
            )}
          </S.ErrorHelpText>
          {errors?.map((errMsg, i) => <Error key={i}>{errMsg}</Error>)}
        </>
      )}
    </S.Wrapper>
  );
};

Building.propTypes = {
  goToStep: PropTypes.func
};

export default Building;
