import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";

import { timeFormats } from "../settings";

import * as S from "./TimeTooltip.styles";

const defaultTooltipContent = Date.now();

const calculatePosition = (pointerX, text) => {
  if (!text) {
    return [{ transform: `translate(${pointerX}px, 0)` }];
  }

  const PADDING = 4;
  const { width, height } = text.getBoundingClientRect();

  return [
    { transform: `translate(${pointerX}px, 0)` },
    {
      transform: `translate(-${width / 2 + PADDING / 2}px, -${height}px)`,
      height: height + PADDING,
      width: width + PADDING
    },
    {
      transform: `translate(-${width / 2}px, 0)`
    }
  ];
};

const TimeTooltip = ({
  pointerX,
  timestamp = defaultTooltipContent,
  timeframe,
  isVisible
}) => {
  const text = useRef();

  const tooltipFormat = useMemo(
    () =>
      !timeframe || timeframe === "short"
        ? timeFormats.long
        : timeFormats.short,
    [timeframe]
  );

  const [layoutStyle, backgroundStyle, textStyle] = useMemo(
    () => calculatePosition(pointerX, text.current),
    [pointerX, text]
  );
  return (
    <S.Layout isVisible={isVisible && timestamp} style={layoutStyle}>
      <S.Background style={backgroundStyle} />
      <S.Text ref={text} style={textStyle}>
        {tooltipFormat(timestamp)}
      </S.Text>
    </S.Layout>
  );
};

TimeTooltip.propTypes = {
  pointerX: PropTypes.number,
  timestamp: PropTypes.instanceOf(Date),
  timeframe: PropTypes.string,
  isVisible: PropTypes.bool
};

export default React.memo(TimeTooltip);
