import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import withReducers from "Hocs/withReducers";

import { getPlans } from "Reducers/plan";
import Loading from "Components/Loading";
import Screenshot from "../../../../common/components/Screenshot";
import { getRegionLabel, interpolateURL } from "Libs/utils";

import * as S from "./styles";

const getMaxDevelopementEnvironmentNumber = project => {
  if (!project || !project.subscription) {
    return "";
  }
  const env =
    project.subscription.resources?.development?.max_environments ||
    project.subscription.environments;
  if (env) return env + 1;

  return "";
};

const ProjectDetails = () => {
  const { organizationId, projectId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId], new Map())
  );
  const regionsByUrl = useSelector(state =>
    state.region?.get("byUrl", new Map())
  );
  const environments = useSelector(state =>
    state.environment?.getIn(["data", organizationId, projectId], new Map())
  );
  const plan = useSelector(state => {
    // Leave the field for the plan blank until .plan has been populated by a response from the API.
    // This ensures we don't have a "flash" of the machine name before the label is loaded from the API.
    if (state.plan.has("data") === false) {
      return " ";
    }
    return (
      state.plan?.getIn(
        ["data", project?.subscription?.plan, "label"],
        false
      ) || project?.subscription?.plan
    );
  });

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  const regionLabel = regionsByUrl.get(project.region);
  const region = getRegionLabel(regionLabel);

  if (!project) return <Loading />;

  return (
    <S.Wrapper>
      <S.Header>
        <S.Url url={project.default_domain}>
          <Screenshot
            imagePath={`/${projectId}/${project?.default_branch ||
              "master"}/screenshot.png`}
          />
        </S.Url>
      </S.Header>

      <S.Details>
        <S.ProjectName>
          {project.title || intl.formatMessage({ id: "no_title" })}
        </S.ProjectName>

        <S.Infos displayValues={environments.size > 0}>
          {/* Nb of environments */}
          <S.Info>
            <S.Value
              className="environments"
              aria-labelledby="projectoverview-environments"
            >
              {intl.formatMessage(
                {
                  id: "project.details.environments_used",
                  defaultMessage: "{used} out of {total}"
                },
                {
                  used: environments?.filter(env => env.status === "active")
                    .size,
                  total: getMaxDevelopementEnvironmentNumber(project)
                }
              )}
            </S.Value>
            <S.Label id="projectoverview-environments">
              {intl.formatMessage({
                id: "project.details.environments",
                defaultMessage: "Environments"
              })}
            </S.Label>
          </S.Info>

          {/* Storage */}
          <S.Info>
            <S.Value
              className="storage"
              aria-labelledby="projectoverview-storage"
            >
              {project.subscription &&
                `${project.subscription.storage / 1024} GB`}
            </S.Value>
            <S.Label id="projectoverview-storage">
              {intl.formatMessage({
                id: "project.details.disk",
                defaultMessage: "Disk"
              })}
            </S.Label>
          </S.Info>

          {/* Nb of Members */}
          <S.Info>
            <S.Value
              className="members"
              aria-labelledby="projectoverview-members"
            >
              {project.subscription?.user_licenses}
            </S.Value>
            <S.Label id="projectoverview-members">
              {intl.formatMessage({
                id: "project.details.members",
                defaultMessage: "Members"
              })}
            </S.Label>
          </S.Info>

          {/* Region */}
          <S.Info>
            <S.Value
              className="region"
              aria-labelledby="projectoverview-region"
            >
              {region && (
                <>
                  {region.title}{" "}
                  <S.RegionSuffix>{region.suffix}</S.RegionSuffix>
                </>
              )}
            </S.Value>
            <S.Label id="projectoverview-region">
              {intl.formatMessage({
                id: "project.details.region",
                defaultMessage: "Region"
              })}
            </S.Label>
          </S.Info>

          {/* Plan */}
          <S.Info>
            <S.Value className="plan" aria-labelledby="projectoverview-plan">
              {plan}{" "}
              {project?.hasPermission &&
                project.hasPermission("#subscription_change") &&
                (process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL ? (
                  <a
                    href={interpolateURL(
                      process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL,
                      { projectId: project.id }
                    )}
                  >
                    {intl.formatMessage({
                      id: "project.details.plan.upgrade",
                      defaultMessage: "Upgrade"
                    })}
                  </a>
                ) : (
                  <Link
                    to={`/-/users/${organizationId}/billing/plan/${
                      project.subscription_id
                    }`}
                  >
                    {intl.formatMessage({
                      id: "project.details.plan.upgrade",
                      defaultMessage: "Upgrade"
                    })}
                  </Link>
                ))}
            </S.Value>
            <S.Label id="projectoverview-region">
              {intl.formatMessage({
                id: "project.details.plan",
                defaultMessage: "Plan"
              })}
            </S.Label>
          </S.Info>

          {/* Project ID*/}
          <S.Info>
            <S.Value className="id" aria-labelledby="projectoverview-id">
              {project.id}
            </S.Value>
            <S.Label id="projectoverview-id">
              {intl.formatMessage({
                id: "project.details.id",
                defaultMessage: "Project ID"
              })}
            </S.Label>
          </S.Info>
        </S.Infos>
      </S.Details>
    </S.Wrapper>
  );
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  plan: () => import("Reducers/plan"),
  project: () => import("Reducers/project"),
  region: () => import("Reducers/project/region")
})(ProjectDetails);
