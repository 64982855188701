import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import CustomScroll from "react-custom-scroll";
import { List } from "immutable";

import { saveConfig } from "Reducers/project/setup";

import withReducers from "Hocs/withReducers";

import PageMeta from "Components/PageMeta";
import Heading2 from "Components/styleguide/Heading2";
import Button from "Components/Button";
import Dropdown from "Components/Dropdown";
import SearchInput from "Components/SearchInput";

import TemplateItem from "./TemplateItem";
import TemplateSelectModal from "./TemplateSelectModal";

import * as S from "./styles";

const TemplateStep = ({ goToStep }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [templates, setTemplates] = useState([]);
  const [language, setLanguage] = useState();
  const [modal, setModal] = useState({ isOpen: false, template: null });
  const [search, setSearch] = useState();
  const [type, setType] = useState();

  const catalog = useSelector(({ setup }) =>
    setup?.getIn(["catalog", "data"], List())
  );

  useEffect(
    () => {
      if (catalog.size) setTemplates(catalog.toJS());
    },
    [catalog.size]
  );

  const [langList, typeList] = (() => {
    let langList = [];
    let typeList = [];

    templates.forEach(tpl => {
      const tags = tpl.info?.tags;

      if (tags) {
        const lang = tags[0];
        if (!langList.find(elt => elt.label === lang)) {
          langList.push({ value: lang, label: lang });
        }

        tags.forEach(tag => {
          if (
            !typeList.find(elt => elt.label === tag) &&
            !langList.find(elt => elt.label === tag)
          ) {
            typeList.push({ value: tag, label: tag });
          }
        });
      }
    });

    langList.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    langList.unshift({ value: "all", label: "All languages" });

    typeList.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    typeList.unshift({ value: "all", label: "All types" });

    return [langList, typeList];
  })();

  const isInList = tpl => {
    const tags = tpl.info?.tags || [];

    if (type && !tags.includes(type)) return false;
    if (language && !tags.includes(language)) return false;
    if (search && !tpl.info?.name.toLowerCase().includes(search.toLowerCase()))
      return false;
    return true;
  };

  const filteredList = templates
    .sort((a, b) => {
      if (a.info.name.toLowerCase() < b.info.name.toLowerCase()) return -1;
      if (a.info.name.toLowerCase() > b.info.name.toLowerCase()) return 1;
      return 0;
    })
    .filter(tpl => isInList(tpl));

  const handleSelectTemplate = tpl => {
    dispatch(saveConfig({ template: tpl.template }));
    goToStep("info");
  };

  const handleGoBack = e => {
    e?.preventDefault();
    goToStep("");
  };

  return (
    <S.Wrapper
      id="template-list"
      role="tabpanel"
      aria-labelledby="tab-template"
    >
      <PageMeta title={intl.formatMessage({ id: `setup.template.title` })} />
      <S.Header>
        <Heading2>
          {intl.formatMessage({ id: `setup.template.title` })}
        </Heading2>
      </S.Header>

      <LiveMessage
        message={intl.formatMessage({ id: "tab_template" })}
        aria-live="polite"
      />
      {language && (
        <LiveMessage
          message={`filtering list by ${language}`}
          aria-live="polite"
        />
      )}
      {type && (
        <LiveMessage message={`filtering list by ${type}`} aria-live="polite" />
      )}
      {search && (
        <LiveMessage
          message={`filtering list by ${search}`}
          aria-live="polite"
        />
      )}

      <S.FiltersWrapper>
        <SearchInput
          id="template-search-input"
          placeholder={intl.formatMessage({ id: "search_templates" })}
          iconSize={18}
          onChange={event => setSearch(event.target.value)}
          className="float"
        />
        <div>
          <S.SelectorWrapper className="filter-tags filter-type">
            <Dropdown
              options={typeList}
              name="select_type"
              onChange={elt => setType(elt.value === "all" ? null : elt.value)}
              searchable={false}
              defaultValue={{ value: "all", label: "All types" }}
              clearable={false}
              className="outlined"
            />
          </S.SelectorWrapper>
          <S.SelectorWrapper className="filter-tags filter-language">
            <Dropdown
              options={langList}
              name="select_language"
              onChange={elt =>
                setLanguage(elt.value === "all" ? null : elt.value)
              }
              searchable={false}
              defaultValue={{ value: "all", label: "All languages" }}
              clearable={false}
              className="outlined"
            />
          </S.SelectorWrapper>
        </div>
      </S.FiltersWrapper>

      <CustomScroll addScrolledClass={true} heightRelativeToParent="100%">
        <S.ResultsWrapper className="templates-wrapper">
          {filteredList.map((tpl, i) => {
            return (
              <TemplateItem
                key={`template-${i}`}
                template={tpl}
                onClick={() => handleSelectTemplate(tpl)}
                openModal={() => {
                  setModal({ isOpen: true, template: tpl });
                }}
                modalOpen={modal.isOpen}
              />
            );
          })}
        </S.ResultsWrapper>
      </CustomScroll>

      <S.ActionButtons>
        <Button className="secondary" type="button" onClick={handleGoBack}>
          {intl.formatMessage({ id: "back" })}
        </Button>
      </S.ActionButtons>

      {modal.isOpen && (
        <TemplateSelectModal
          isOpen={modal.isOpen}
          template={modal.template}
          onSave={() => handleSelectTemplate(modal.template)}
          closeModal={() => setModal({ isOpen: false, template: null })}
        />
      )}
    </S.Wrapper>
  );
};

TemplateStep.propTypes = {
  goToStep: PropTypes.func
};

export default withReducers({
  setup: () => import("Reducers/project/setup")
})(TemplateStep);
