import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import useLocalForage from "Hooks/useLocalForage";

import ActivitySection from "../../../../../../common/containers/ActivitySection";
import { Details, Header, NavBar } from "../../components";

import * as S from "./styles";

const ListIntegrations = ({ integrations }) => {
  const { push } = useHistory();
  const { integrationId, projectId, organizationId } = useDecodedParams();
  const [current, setCurrent] = useState(() => {
    if (integrationId && integrations.find(elt => elt.id === integrationId)) {
      return integrationId;
    }
    return integrations[0].id;
  });

  const [gettingStartedIsClosed] = useLocalForage(
    "integration_getting_started"
  );

  useEffect(
    () => {
      push(`/${organizationId}/${projectId}/-/integrations/${current}`);
    },
    [current]
  );

  const currentIntegration = integrations.find(elt => elt.id === current);

  return (
    <S.Wrapper>
      <NavBar
        integrations={integrations}
        current={current}
        onSelect={id => setCurrent(id)}
      />
      <S.Content
        belowGettingStarted={
          integrations.length === 1 && gettingStartedIsClosed !== true
        }
      >
        <Header integration={currentIntegration} />
        <Details integration={currentIntegration} />
        <ActivitySection context={`integration.${currentIntegration.type}`} />
      </S.Content>
    </S.Wrapper>
  );
};

ListIntegrations.propTypes = {
  integrations: PropTypes.array
};

export default ListIntegrations;
