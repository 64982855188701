import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import PageMeta from "Components/PageMeta";
import Heading2 from "Components/styleguide/Heading2";

import { resetSetup } from "Reducers/project/setup";

import Card from "./Card";

import CreateProjectBlank from "Components/illustrations/CreateProjectBlank";
import CreateProjectTemplate from "Components/illustrations/CreateProjectTemplate";

import * as S from "./styles";

const TypeStep = ({ goToStep }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const user = useSelector(({ app }) => app?.get("me"));

  useEffect(() => {
    dispatch(resetSetup());
  }, []);

  const handleClick = (e, step) => {
    e.preventDefault();
    goToStep(step);
  };

  const title = (() => {
    if (
      user.trial &&
      user.data?.current_trial?.active &&
      user.projects.length === 0
    ) {
      return intl.formatMessage({ id: `setup.type.trial.title` });
    }
    return intl.formatMessage({ id: `setup.type.title` });
  })();

  return (
    <S.Wrapper>
      <PageMeta title={title} />

      <S.Header>
        <Heading2>{title}</Heading2>
        <p>{intl.formatMessage({ id: `setup.type.description` })}</p>
      </S.Header>

      <S.Cards>
        <Card
          image={<CreateProjectTemplate aria-hidden="true" />}
          title={intl.formatMessage({ id: "setup.type.template.title" })}
          description={intl.formatMessage({
            id: "setup.type.template.description"
          })}
          onClick={e => handleClick(e, "template")}
        />

        <Card
          image={<CreateProjectBlank aria-hidden="true" />}
          title={intl.formatMessage({ id: "setup.type.empty.title" })}
          description={intl.formatMessage({
            id: "setup.type.empty.description"
          })}
          onClick={e => handleClick(e, "info")}
        />
      </S.Cards>
    </S.Wrapper>
  );
};

TypeStep.propTypes = {
  goToStep: PropTypes.func
};

export default TypeStep;
