import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { CardElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import client from "Libs/platform";
import logger from "Libs/logger";

import Loading from "Components/Loading";
import Button from "Components/Button";
import Label from "Components/fields/Label";
import InputField from "Components/fields/InputField";
import RequiredTag from "Components/fields/RequiredTag";

const Layout = styled.div`
  max-width: 100%;
  .StripeElement {
    background: ${props => props.theme.inputBg};
    color: ${props => props.theme.inputText};
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 32px;
  }
  .setting-line {
    background: ${props => props.theme.inputBg};
    border: 1px solid ${props => props.theme.inputBg};
    color: ${props => props.theme.inputText};
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
`;

const CardSection = ({
  number,
  edit,
  onEdit,
  onCancel,
  stripe,
  hideButtons,
  onSuccess,
  name_line,
  organizationId // The organization.id
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState();
  // const [error, setError] = useState({});

  const intl = useIntl();
  const theme = useTheme();

  useEffect(
    () => {
      let isCanceled = false;
      const createIntent = async () => {
        try {
          const s = await client.createOrganizationPaymentSourceIntent(
            organizationId
          );
          if (isCanceled) {
            return;
          }
          setSecret(s?.client_secret);
        } catch (err) {
          // setError(err);
        }
      };

      createIntent();
      return () => (isCanceled = true);
    },
    [organizationId]
  );

  const handleSubmit = async ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (edit) {
      setIsLoading(true);

      try {
        const response = await stripe.handleCardSetup(secret, {
          payment_method_data: {
            billing_details: { name: name_line }
          }
        });

        const cardTokenId = response.setupIntent.payment_method;

        const creditcard = await client.addOrganizationPaymentSource(
          organizationId,
          "credit-card",
          cardTokenId
        );
        setIsLoading(false);
        onSuccess && onSuccess(creditcard);
      } catch (err) {
        logger(err);
        setIsLoading(false);
      }
    } else {
      onSuccess && onSuccess();
    }
  };

  return (
    <Layout>
      <form aria-labelledby="edit-card" id="stripe-payment-form">
        {isLoading && <Loading />}
        {!edit && number ? (
          <React.Fragment>
            <LiveMessage
              message="click update credit card to edit"
              aria-live="polite"
            />
            <InputField
              label="credit card"
              className="card-number"
              isDisabled={true}
              value={number.replace(/X/g, "•").replace(/-/g, " ")}
            />
            <div className="">
              <Button
                onClick={onEdit}
                type="button"
                aria-label={intl.formatMessage({ id: "update_credit_card" })}
              >
                {intl.formatMessage({ id: "update_credit_card" })}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div className="new-card">
            <LiveMessage
              message="enter credit card information"
              aria-live="polite"
            />
            <Label>
              {intl.formatMessage({ id: "credit_card_number" })} <RequiredTag />
            </Label>
            <CardElement
              className="stripe-card"
              style={{
                base: {
                  fontSize: "14px",
                  iconColor: theme.inputText,
                  color: theme.inputText,
                  "::placeholder": {
                    fontSize: "14px",
                    color: theme.inputPlaceholder
                  }
                }
              }}
              iconStyle="solid"
            />
            {!hideButtons && (
              <React.Fragment>
                <Button
                  type="button"
                  aria-label={intl.formatMessage({ id: "save_changes" })}
                  id="stripe_cardsection_submit"
                  className="primary"
                  onClick={handleSubmit}
                >
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                {number && (
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_cancel"
                    className="secondary"
                    onClick={onCancel}
                  >
                    {"cancel"}
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </form>
    </Layout>
  );
};

CardSection.propTypes = {
  name: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  organizationId: PropTypes.string,
  onCancel: PropTypes.func,
  name_line: PropTypes.string
};

export default injectStripe(CardSection);
