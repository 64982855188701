import { fromJS, Map } from "immutable";

import { PROJECT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";
import { hasHtml, isJson } from "Libs/utils";
import { updateProject } from "Reducers/project";

const LOAD_DOMAINS_START = "app/projectDomains/load_domains_start";
const LOAD_DOMAINS_SUCCESS = "app/projectDomains/load_domains_success";
const LOAD_DOMAINS_FAILURE = "app/projectDomains/load_domains_failure";

const ADD_DOMAIN_START = "app/projectDomain/add_domain_start";
const ADD_DOMAIN_SUCCESS = "app/projectDomain/add_domain_success";
const ADD_DOMAIN_FAILURE = "app/projectDomain/add_domain_failure";
const ADD_DOMAIN_CANCEL = "app/projectDomain/add_domain_cancel";

const UPDATE_DOMAIN_START = "app/projectDomain/update_domain_start";
const UPDATE_DOMAIN_SUCCESS = "app/projectDomain/update_domain_success";
const UPDATE_DOMAIN_FAILURE = "app/projectDomain/update_domain_failure";

const EDIT_LINE = "app/projectDomain/edit_line";

const DELETE_DOMAIN_START = "app/projectDomain/delete_domain_start";
const DELETE_DOMAIN_SUCCESS = "app/projectDomain/delete_domain_success";
const DELETE_DOMAIN_FAILURE = "app/projectDomain/delete_domain_failure";

export const editLine = (index, isNew) => ({
  type: EDIT_LINE,
  payload: index,
  meta: { isNew }
});

export const cancelAddDomain = () => ({ type: ADD_DOMAIN_CANCEL });

export const loadDomains = (organizationDescriptionId, project) => {
  return async dispatch => {
    if (!project) {
      return false;
    }

    dispatch({ type: LOAD_DOMAINS_START });

    try {
      const projectDomains = await project.getDomains();
      dispatch({
        type: LOAD_DOMAINS_SUCCESS,
        payload: {
          projectDomains
        },
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project[PROJECT_ID_FIELD]
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load domains.";
        logger(errorMessage, {
          action: "loadDomains",
          meta: {
            organizationDescriptionId,
            projectDescriptionId: project[PROJECT_ID_FIELD]
          }
        });
        dispatch({ type: LOAD_DOMAINS_FAILURE, error: true, payload: err });
      }
    }
  };
};

export const addDomain = (
  organizationDescriptionId,
  project,
  domain,
  setAsDefault = false
) => {
  return async dispatch => {
    if (!project) {
      return false;
    }
    dispatch({ type: ADD_DOMAIN_START });

    try {
      let result = await project.addDomain(domain.name, domain.ssl);

      if ((result.data && result.data.code / 100) === 4) {
        return dispatch({
          type: ADD_DOMAIN_FAILURE,
          payload: result.detail,
          meta: { isNew: true }
        });
      }

      const newDomain = await result.getEntity();

      dispatch({
        type: ADD_DOMAIN_SUCCESS,
        payload: newDomain,
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project[PROJECT_ID_FIELD]
        }
      });

      if (setAsDefault) {
        dispatch(
          updateProject(organizationDescriptionId, project.id, {
            default_domain: domain.name
          })
        );
      }
    } catch (err) {
      if (![400, 403, 404].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "addDomain",
          project,
          domain,
          meta: {
            organizationDescriptionId,
            projectDescriptionId: project[PROJECT_ID_FIELD]
          }
        });
      }

      if (err.detail === "This domain is already claimed by another service") {
        err.detail =
          "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support.";
      }

      dispatch({
        type: ADD_DOMAIN_FAILURE,
        payload: err.detail || err,
        meta: { isNew: true }
      });
    }
  };
};

export const updateDomain = () => async dispatch => {
  dispatch({ type: UPDATE_DOMAIN_START });
  try {
    dispatch({ type: UPDATE_DOMAIN_SUCCESS });
  } catch (error) {
    logger(error, {
      type: "updateDomain"
    });

    if (error.detail === "This domain is already claimed by another service") {
      error.detail =
        "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support.";
    }

    dispatch({ type: UPDATE_DOMAIN_FAILURE, payload: error.detail || error });
  }
};

export const deleteDomain = (
  organizationDescriptionId,
  projectDescriptionId,
  domain
) => async dispatch => {
  if (!domain) {
    return false;
  }

  dispatch({ type: DELETE_DOMAIN_START });

  try {
    await domain.delete();

    dispatch({
      type: DELETE_DOMAIN_SUCCESS,
      payload: domain,
      meta: {
        organizationDescriptionId,
        projectDescriptionId
      }
    });
  } catch (err) {
    logger(err, {
      action: "deleteDomain",
      payload: domain,
      meta: {
        organizationDescriptionId,
        projectDescriptionId
      }
    });
    dispatch({
      type: DELETE_DOMAIN_FAILURE,
      payload: err.detail || err,
      meta: {}
    });
  }
};

export default function projectDomainReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_DOMAINS_START:
      return state.set("loading", true).delete("errors");
    case ADD_DOMAIN_START:
      return state.set("updateLoading", true);
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case DELETE_DOMAIN_SUCCESS:
      return state
        .deleteIn([
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.payload.id
        ])
        .set("editedLine", false);
    case ADD_DOMAIN_CANCEL:
      return state.set("editedLine", false).set("isNew", false);
    case ADD_DOMAIN_SUCCESS:
      return state
        .delete("errors")
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .set("editedLine", false)
        .set("isNew", false)
        .set("updateLoading", false);
    case UPDATE_DOMAIN_SUCCESS:
      return state
        .delete("errors")
        .set("editedLine", false)
        .set("updateLoading", false);
    case LOAD_DOMAINS_SUCCESS:
      return state
        .delete("errors")
        .set("loading", false)
        .set(
          "data",
          fromJS(
            action.payload.projectDomains.reduce(
              (organizationsProjectsEnvironements, projectDomain) => {
                if (
                  !organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ]
                ) {
                  organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ] = {};
                }
                if (
                  !organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ][action.meta.projectDescriptionId]
                ) {
                  organizationsProjectsEnvironements[
                    action.meta.organizationDescriptionId
                  ][action.meta.projectDescriptionId] = {};
                }

                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId][
                  projectDomain.id
                ] = projectDomain;

                return organizationsProjectsEnvironements;
              },
              {}
            )
          )
        );
    case LOAD_DOMAINS_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    case UPDATE_DOMAIN_FAILURE:
    case ADD_DOMAIN_FAILURE:
      return state.set("updateLoading", false).set("errors", action.payload);
    default:
      return state;
  }
}
