import styled from "styled-components";

import BaseButtonWrapper from "Components/ButtonWrapper";

export const ButtonWrapper = styled(BaseButtonWrapper)`
  margin-top: 48px;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 24px;
  background-color: white;
  background: ${props => props.theme.sectionBg};

  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ImageBox = styled.div`
  padding-right: 24px;
  padding-bottom: 16px;
  min-width: 200px;

  @media (min-width: 960px) {
    padding-left: 0;
  }
`;

export const FormBox = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 2px;
  padding-bottom: 32px;
  @media (min-width: 960px) {
    min-width: 264px;
    max-width: 620px;
    padding: 8px 32px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 27px;
  color: ${props => props.theme.sectionText};
  text-transform: capitalize;
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${props.theme.sectionBorder};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const FieldLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${props => props.theme.links};
  display: inline-block;
`;

const HelpText = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.sectionText};
`;

export const ThemeHelpText = styled(HelpText)`
  margin-bottom: 23px;
`;

export const InlineHelpText = styled(HelpText)`
  margin-bottom: 10px;
`;
