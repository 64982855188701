import React, { Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  useLocation
} from "react-router-dom";
import { useIntl } from "react-intl";

import getUrls from "../../common/containers/NavBar/UserMenu/getUrls";

import OpenTicketPage from "./open/Page";
import ViewTicketPage from "./view/Page";
import ListTicketsPage from "./list/Page";

import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import PageMeta from "Components/PageMeta";

const TicketsLayout = styled(PageLayout)`
  padding-top: 32px;
`;

const Routes = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const username = useSelector(state => state.app?.getIn(["me", "username"]));

  return (
    <Fragment>
      <PageMeta title={intl.formatMessage({ id: "tickets.documenttitle" })} />
      <NavBar role="navigation" currentPathName={pathname} />
      <TicketsLayout>
        <Switch>
          <Route path="/support">
            <Redirect
              to={getUrls(process.env.ACCOUNTS_URL, username).supportUrl}
            />
          </Route>
          <Route path={`${path}/open`}>
            <OpenTicketPage />
          </Route>
          {!process.env.ZENDESK_URL && (
            <Route path={`${path}/:ticketId`}>
              <ViewTicketPage />
            </Route>
          )}
          <Route path={path}>
            <ListTicketsPage />
          </Route>
        </Switch>
      </TicketsLayout>
    </Fragment>
  );
};

export default Routes;
