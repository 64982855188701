import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { LiveMessage } from "react-aria-live";
import { semiBoldAlias, themeHelpers } from "Libs/theme";
import { capitalize } from "Libs/utils";

import Button from "UI/Button";
import CertificateForm from "../CertificateForm";
import SettingLine from "Components/SettingLine";
import Heading2 from "Components/styleguide/Heading2";
import EmptyText from "Components/EmptyText";
import ButtonAdd from "Components/ButtonAdd";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import Label from "Components/fields/Label";
import PageDescription from "Components/PageDescription";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageMeta from "Components/PageMeta";

const InfoLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .domains {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 504px;
    white-space: nowrap;
  }
  .expires {
    font-size: 13px;
    color: #4b618;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${semiBoldAlias};
  }
  @media (min-width: 900px) {
    flex-direction: row;
    .expires {
      width: 275px;
      padding-left: 20px;
    }
  }
`;

const CertificateExpanded = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;

const CertificateWrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
  button.delete,
  button.edit {
    margin: 0;
  }
  button.outline {
    float: right;
    &:hover {
      border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
      margin: -1px;
    }
  }

  .setting-content {
    width: calc(100% - 60px);
  }
`;

const FieldGroup = styled.div`
  margin-bottom: 16px;
  display: flex;
  color: ${props =>
    props.theme.name === "dark"
      ? props.theme.label
      : themeHelpers(props.theme, "darkTint")};
  font-size: 15px;
  line-height: 20px;
  label {
    width: 90px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .domain + .domain,
  .issuer + .issuer {
    margin-top: 5px;
  }
`;

const DeleteButton = styled(Button)`
  float: right;
`;

class ProjectCertificateListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewCertificate = this.addNewCertificate.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.expand = this.expand.bind(this);
    this.closeExpand = this.closeExpand.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this.state = {
      certificates: [
        ...(props.certificates && props.certificates.valueSeq().toJS())
      ],
      isModalLeaveOpen: false,
      isChanged: false,
      changeIndex: false,
      index: ""
    };
  }

  componentDidMount() {
    this.props.loadCertificates(this.props.project);
    this.setState(() => ({
      certificates: [...this.props.certificates.valueSeq().toJS()]
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.project !== this.props.project) {
      this.props.loadCertificates(nextProps.project);
    }
    if (nextProps.certificates !== this.props.certificates) {
      this.setState(() => ({
        certificates: [...nextProps.certificates.valueSeq().toJS()]
      }));
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.isChanged = true;
      nextState.certificates[index] = {
        ...(this.state.certificates[index] || this.props.certificates[index]),
        [field]: value
      };

      return nextState;
    });
  }

  save({ certificate, chain, key }) {
    let chainArray = [];
    if (chain && typeof chain === "string") {
      // Add SPLIT to know where to separate the field.
      const split = chain.replace(
        /-----END CERTIFICATE-----/g,
        "-----END CERTIFICATE-----___SPLIT___"
      );
      // Seperate text into an array at the SPLIT.
      chainArray = split.split("___SPLIT___");
      chainArray.splice(-1, 1);
    }
    const newCertificate = { key, certificate, chain: chainArray };

    this.setState({
      isChanged: false,
      changeIndex: false,
      index: "",
      isNew: false
    });
    this.props.addCertificate(this.props.project, newCertificate);
  }
  expand(index) {
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        changeIndex: true,
        index: index
      });
    } else {
      if (this.props.isNew) {
        this.cancel();
      } else if (this.state.expandedLine === index) {
        this.setState({
          expandedLine: false
        });
      } else {
        this.setState({
          expandedLine: index,
          isChanged: false
        });
      }
    }
  }

  closeExpand() {
    this.setState({
      expandedLine: false
    });
  }

  addNewCertificate() {
    this.setState(prevState => {
      const nextState = prevState;
      if (!nextState.isNew) {
        nextState.isNew = true;
        nextState.certificates.unshift({});
        nextState.expandedLine = 0;
      }
      this.props.editLine(0, true);

      return nextState;
    });
  }

  cancel() {
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true
      });
    } else {
      if (this.props.isNew) {
        this.setState(prevState => {
          const nextState = prevState;
          nextState.isNew = false;
          nextState.isChanged = false;
          nextState.expandedLine = false;
          nextState.changeIndex = false;
          nextState.index = "";
          nextState.certificates.splice(0, 1);
          return nextState;
        });
      }
      this.props.cancelAddCertificate();
    }
  }

  openModal(certificate) {
    this.setState({
      isModalOpen: true,
      certificate
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false
    });
  }

  isChanged() {
    this.setState({
      isChanged: true
    });
  }

  render() {
    const { isLoading, isUpdateLoading, intl } = this.props;

    return (
      <CertificateWrapper>
        <LiveMessage
          message={`${this.props.project &&
            this.props.project.title} certificate settings`}
          aria-live="polite"
        />
        <PageMeta
          title={`Certificates | ${this.props.project &&
            this.props.project.title}`}
        />
        <ButtonAdd
          id="add-new-certificate"
          css={{ float: "right" }}
          onClick={this.addNewCertificate}
        />
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          <FormattedMessage id="certificates" />
        </Heading2>
        <PageDescription>
          Platform.sh automatically generates TLS certificates for all of your
          sites to enable HTTPS.<br />You can also provide custom certificates
          on this page.
        </PageDescription>
        <div aria-labelledby="settings-heading">
          {isLoading && <Loading />}
          {!isLoading &&
            this.state.certificates.length === 0 && (
              <EmptyText>No certificates.</EmptyText>
            )}
          {(this.state.certificates || this.props.certificates.valueSeq())
            .sort((a, b) => {
              if (a.expires_at < b.expires_at) return 1;
              if (a.expires_at > b.expires_at) return -1;
              return 0;
            })
            .map((certificate, index) => {
              return (
                <div key={`${certificate.id}-${index}-read`}>
                  <SettingLine
                    id={`project-certificate-list-${(
                      (certificate.domains && certificate.domains[0]) ||
                      ""
                    ).replace(/\./g, "")}`}
                    info={
                      <InfoLayout>
                        <span
                          className="domains"
                          title={
                            certificate.domains &&
                            certificate.domains.join(", ")
                          }
                        >
                          {certificate.domains &&
                            certificate.domains.join(", ")}
                        </span>
                        <span
                          className="expires"
                          title={moment(certificate.expires_at).format(
                            "MMMM D, YYYY"
                          )}
                        >
                          Exp:{" "}
                          {moment(certificate.expires_at).format(
                            "MMMM D, YYYY"
                          )}
                        </span>
                      </InfoLayout>
                    }
                    isNew={!certificate.id}
                    addNewTitle="Add certificate"
                    isOpen={this.state.expandedLine === index}
                    openText={intl.formatMessage({ id: "view" })}
                    onClick={() => this.expand(index)}
                  >
                    {this.state.expandedLine === index &&
                      (this.props.isNew ? (
                        <CertificateForm
                          key={`certificate-${index}-edit`}
                          certificate={certificate}
                          isNew={this.props.isNew}
                          onSave={this.save}
                          onCancel={this.cancel}
                          errors={this.props.errors}
                          isLoading={isUpdateLoading}
                          onChange={this.isChanged}
                        />
                      ) : (
                        <CertificateExpanded
                          key={`${certificate.id}-${index}-edit`}
                        >
                          <FieldGroup>
                            <Label>
                              <FormattedMessage id="domains" />
                            </Label>
                            <div>
                              {certificate.domains &&
                                certificate.domains.map(domain => (
                                  <div className="domain" key={domain}>
                                    {domain}
                                  </div>
                                ))}
                            </div>
                          </FieldGroup>
                          <FieldGroup>
                            <Label>
                              <FormattedMessage id="issuer_info" />
                            </Label>
                            <div>
                              {certificate.issuer &&
                                certificate.issuer.map(issuer => (
                                  <div className="issuer" key={issuer.oid}>
                                    {issuer.value}
                                  </div>
                                ))}
                            </div>
                          </FieldGroup>
                          <FieldGroup className="inline">
                            <Label>
                              <FormattedMessage id="created" />
                            </Label>
                            <div>
                              {moment(certificate.created_at).format(
                                "MMMM D YYYY"
                              )}
                            </div>
                          </FieldGroup>
                          <FieldGroup className="inline">
                            <Label>
                              <FormattedMessage id="updated" />
                            </Label>
                            <div>
                              {moment(certificate.updated_at).format(
                                "MMMM D YYYY"
                              )}
                            </div>
                          </FieldGroup>
                          <FieldGroup className="inline">
                            <Label>
                              <FormattedMessage id="expires" />
                            </Label>
                            <div>
                              {moment(certificate.expires_at).format(
                                "MMMM D YYYY"
                              )}
                            </div>
                          </FieldGroup>
                          <div className="actions">
                            {certificate.hasLink &&
                              certificate.hasLink("#delete") && (
                                <DeleteButton
                                  variant="tertiary"
                                  id={`delete-certificate-${(
                                    (certificate.domains &&
                                      certificate.domains[0]) ||
                                    ""
                                  ).replace(/\./g, "")}`}
                                  type="button"
                                  aria-label={intl.formatMessage({
                                    id: "delete"
                                  })}
                                  onClick={() => this.openModal(certificate)}
                                >
                                  {capitalize(
                                    intl.formatMessage({ id: "delete" })
                                  )}
                                </DeleteButton>
                              )}
                          </div>
                        </CertificateExpanded>
                      ))}
                  </SettingLine>
                </div>
              );
            })}
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            deleteFunction={() =>
              this.props.deleteCertificate(this.state.certificate)
            }
            itemType="certificate for the domains"
            itemName={
              this.state.certificate &&
              this.state.certificate.domains.join(", ")
            }
            itemId={this.state.certificate && this.state.certificate.id}
          />
          <ModalConfirmLeaveForm
            isOpen={this.state.isModalLeaveOpen}
            closeModal={this.closeModal}
            continueFunction={
              this.state.changeIndex
                ? () => {
                    this.expand(this.state.index);
                  }
                : () => {
                    this.cancel();
                  }
            }
            cancelFunction={this.closeModal}
          />
        </div>
      </CertificateWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadCertificates: project =>
    import("Reducers/project/settings/certificate").then(reducer =>
      dispatch(reducer.loadCertificates(props.organizationId, project))
    ),
  addCertificate: (project, certificate) =>
    import("Reducers/project/settings/certificate").then(reducer =>
      dispatch(
        reducer.addCertificate(props.organizationId, project, certificate)
      )
    ),
  deleteCertificate: certificate =>
    import("Reducers/project/settings/certificate").then(reducer =>
      dispatch(
        reducer.deleteCertificate(
          props.organizationId,
          props.projectId,
          certificate
        )
      )
    ),
  editLine: (index, isNew) =>
    import("Reducers/project/settings/certificate").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  cancelAddCertificate: () =>
    import("Reducers/project/settings/certificate").then(reducer =>
      dispatch(reducer.cancelAddCertificate())
    )
});

const mapStateToProps = (state, props) => {
  const certificate = state.projectCertificate || new Map();
  const environment = state.environment || new Map();
  const project = state.project || new Map();

  return {
    certificates: certificate.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    editedLine: certificate.get("editedLine"),
    project: project.getIn(["data", props.organizationId, props.projectId]),
    isNew: certificate.get("isNew"),
    errors: certificate.get("errors", {}),
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    isLoading: certificate.get("loading"),
    isUpdateLoading: certificate.get("updateLoading")
  };
};

ProjectCertificateListField.propTypes = {
  loadCertificates: PropTypes.func,
  onChange: PropTypes.func,
  addCertificate: PropTypes.func,
  editLine: PropTypes.func,
  cancelAddCertificate: PropTypes.func,
  project: PropTypes.object,
  organizationId: PropTypes.string,
  errors: PropTypes.object,
  certificates: PropTypes.object,
  environmentId: PropTypes.string,
  isNew: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  intl: PropTypes.object,
  deleteCertificate: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProjectCertificateListField));
