import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getOrganizationId } from "Libs/utils";

export const getOrganizationPaymentSource = createAsyncThunk(
  "app/organization/paymentSource",
  async ({ organizationId }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const paymentSource = await client.getOrganizationPaymentSource(
      getOrganizationId(getState, organizationId)
    );

    return paymentSource;
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const organizationPaymentSource = createSlice({
  name: "organizationPaymentSource",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {},
  extraReducers: {
    [getOrganizationPaymentSource.pending]: state => state.set("loading", true),
    [getOrganizationPaymentSource.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;
      return state
        .setIn(["data", organizationId], fromJS(action.payload))
        .set("loading", false);
    },
    [getOrganizationPaymentSource.rejected]: (state, action) =>
      setError(state, action)
  }
});

export default organizationPaymentSource.reducer;

export const oganizationPaymentSourceSelector = (state, props) => {
  return state.organizationPaymentSource.getIn(["data", props.organizationId]);
};
