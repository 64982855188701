import React, { useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import * as s from "./styles";
import { organizationDetailFields } from "./options";
import { capitalize } from "Libs/utils";

import InputField from "Components/fields/InputField";
import Loading from "Components/Loading";
import InfoDialog from "Components/InfoDialog";
import InfoIcon from "Icons/InfoIcon";
import Button from "UI/Button";

import { editOrganizationName } from "Reducers/organization";
import { meSelector } from "Reducers/app";

const BasicSettings = ({
  onSave,
  onDelete,
  organization,
  isLoading = false
}) => {
  const initialFormValues = {
    name: organization?.name,
    label: organization?.label
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [changedValues, setChangedValues] = useState({});

  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const inputNameRef = useRef();
  let inputLabelRef = useRef();

  let getInputLabelRef = ref => {
    if (!ref) {
      return false;
    }
    inputLabelRef.current = ref;
  };

  const isEdit = useSelector(state =>
    state.organization.get("organizationEdit")
  );

  const me = useSelector(meSelector);

  const onFieldChange = (value, fieldName) => {
    const fieldUpdates = {
      ...changedValues,
      [fieldName]: value
    };
    const updatedFormValues = {
      ...formValues,
      ...fieldUpdates
    };
    setFormValues(updatedFormValues);
    setChangedValues(fieldUpdates);
  };

  const onCancel = () => {
    setFormValues(initialFormValues);
    setChangedValues({});
    dispatch(editOrganizationName(false));
  };

  const isChanged = Object.keys(changedValues).length > 0;

  const canEdit =
    organization && me && organization.owner_id === me.get("uuid");

  return (
    <s.Layout>
      <s.OrgniazationLabelValue>
        {isEdit ? (
          <s.LabelInputField>
            <InputField
              getRef={getInputLabelRef}
              onChange={e => onFieldChange(e.target.value, "label")}
              value={formValues?.label || organization?.label}
            />
          </s.LabelInputField>
        ) : (
          <s.OrganizationLabel>{organization?.label}</s.OrganizationLabel>
        )}
        {!isEdit &&
          canEdit && (
            <s.EditLink
              variant="link"
              id="edit-organization-label"
              type="button"
              onClick={() => {
                changedValues.label = formValues?.label;
                dispatch(editOrganizationName(!isEdit));
                // Waiting for the input to mount and the ref to be setted
                setTimeout(() => {
                  inputLabelRef.current.focus();
                }, 200);
              }}
            >
              Edit
            </s.EditLink>
          )}
      </s.OrgniazationLabelValue>
      <s.LabelInfo>
        <s.Label>{intl.formatMessage({ id: "organization_name" })}</s.Label>
        <InfoDialog
          icon={<InfoIcon />}
          iconColor={theme.envTreeInactiveIcon}
          text={intl.formatMessage({
            id: "settings.details.informations"
          })}
        />
      </s.LabelInfo>
      <s.Separator />
      <s.SectionTitle>
        <FormattedMessage id="settings.details" />
      </s.SectionTitle>
      <s.Form>
        <InputField
          id={`organization-name`}
          label={intl.formatMessage({ id: "organization_url" })}
        >
          <s.NameInputField>
            <input
              ref={inputNameRef}
              value={formValues?.name || organization?.name}
              onChange={e => onFieldChange(e.target.value, "name")}
              disabled={!canEdit}
            />
          </s.NameInputField>
          <s.NameInputPrefix onClick={() => inputNameRef.current.focus()}>
            {`https://${window.location.host}/`}
            <s.NameInputPrefixValue>
              {formValues?.name || organization?.name || "[your-url]"}
            </s.NameInputPrefixValue>
          </s.NameInputPrefix>
        </InputField>
        {organizationDetailFields.map(value => (
          <InputField
            onChange={e => onFieldChange(e.target.value, value)}
            key={value}
            id={`organization-${value}`}
            label={intl.formatMessage({ id: value })}
            value={formValues[value] || organization[value]}
            disabled={!canEdit}
          />
        ))}
      </s.Form>
      {isChanged ? (
        <s.ButtonWrapper>
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              id="save_organization_general_settings_button"
              variant="primary"
              onClick={() => onSave(formValues)}
              aria-label={intl.formatMessage({ id: "save" })}
            >
              {capitalize(intl.formatMessage({ id: "save" }))}
            </Button>
          )}
          <Button
            id="cancel-organization-name-btn"
            onClick={onCancel}
            variant="secondary"
            aria-label={intl.formatMessage({ id: "cancel" })}
          >
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
        </s.ButtonWrapper>
      ) : (
        /* TODO: Enable that when the API is ready*/ false && (
          <s.DeleteButton
            variant="tertiary"
            id="access-delete"
            type="button"
            aria-label={intl.formatMessage({
              id: "delete"
            })}
            onClick={onDelete}
          >
            {capitalize(intl.formatMessage({ id: "delete_organization" }))}
          </s.DeleteButton>
        )
      )}
    </s.Layout>
  );
};

BasicSettings.propTypes = {
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  organization: PropTypes.object,
  isLoading: PropTypes.bool
};

export default BasicSettings;
