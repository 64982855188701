import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import * as S from "./Timeframe.styles";

const timeFormat = "HH:mm";
const dateFormat = "MMM D, YYYY";

const getDateFormat = (from, to) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);

  const fromTime = momentFrom.format(timeFormat);
  const toTime = momentTo.format(timeFormat);
  const fromDate = momentFrom.format(dateFormat);
  const toDate = momentTo.format(dateFormat);

  return `${fromTime} - ${toTime}, ${fromDate} - ${toDate}`;
};

const getNowFormat = () => moment().format("MMM DD, YYYY");

const TimeFrame = ({
  timeframes = [],
  selectedTimeframe,
  from,
  to,
  onChange
}) => (
  <S.Layout>
    <S.List>
      {timeframes.map(timeframe => (
        <S.ListItem key={timeframe.label}>
          <S.Toggle
            isActive={timeframe.id === selectedTimeframe.id}
            onClick={() => onChange(timeframe)}
          >
            {timeframe.label}
          </S.Toggle>
        </S.ListItem>
      ))}
    </S.List>
    {selectedTimeframe.id ? getNowFormat() : getDateFormat(from, to)}
  </S.Layout>
);

TimeFrame.propTypes = {
  onChange: PropTypes.func,
  selectedTimeframe: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    range: PropTypes.number,
    id: PropTypes.string
  }),
  timeframes: PropTypes.array,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default TimeFrame;
