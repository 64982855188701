import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import * as S from "./Lines.styles";

const getShape = (data, xAxis, yAxis) =>
  d3
    .line()
    .defined(point => !!point?.value)
    .x(point => xAxis(point.timestamp))
    .y(point => yAxis(point.value))(data);

const Lines = ({
  data,
  hosts,
  xAxis,
  yAxis,
  activeLine,
  onActiveChange,
  ...props
}) => {
  const lines = useMemo(
    () =>
      hosts.sort((first, last) => last.localeCompare(first)).map(host => {
        const values = data.map(point => ({
          value: point[`${host}@value`],
          timestamp: point.timestamp
        }));

        return {
          id: host,
          shape: getShape(values, xAxis, yAxis),
          setActive: () => onActiveChange(host)
        };
      }),
    [data, hosts, xAxis, yAxis, onActiveChange]
  );

  const clearActive = useCallback(() => onActiveChange(undefined), [
    onActiveChange
  ]);

  return (
    <g {...props}>
      {lines.map(({ id, shape, setActive }) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <S.Line
          key={id}
          isAverage={id === "average"}
          anyActive={!!activeLine}
          isActive={activeLine === id}
          onMouseOver={setActive}
          onMouseOut={clearActive}
          d={shape}
        />
      ))}
    </g>
  );
};

Lines.propTypes = {
  data: PropTypes.array,
  hosts: PropTypes.array,
  xAxis: PropTypes.func,
  yAxis: PropTypes.func,
  activeLine: PropTypes.string,
  onActiveChange: PropTypes.func
};

export default React.memo(Lines);
