import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";
import {
  addVariable,
  cancelUpdateVariable,
  deleteVariable,
  editLine,
  getVariables,
  updateVariable
} from "Reducers/environment/settings/variable";

import { DOCS_CONFIG_APP_VARIABLES_URL } from "Constants/documentationUrls";

import ButtonAdd from "Components/ButtonAdd";
import EmptyText from "Components/EmptyText";
import InfoDialog from "Components/InfoDialog";

import SettingLine from "Components/SettingLine";
import VariableForm from "../../../../../../../common/components/VariableForm";
import Heading2 from "Components/styleguide/Heading2";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";

import * as S from "./styles";

const getLabels = variable => {
  let labels = [];
  variable.is_json && labels.push("Json");
  variable.is_sensitive && labels.push("Sensitive");
  variable.visible_build && labels.push("Build");
  variable.visible_runtime && labels.push("Runtime");

  return labels.join(", ");
};

const sortListById = list => {
  return list.sort((a, b) => {
    return a.id !== b.id ? (a.id < b.id ? -1 : 1) : 0;
  });
};

const EnvironmentVariableListField = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { environmentId, organizationId, projectId } = useDecodedParams();

  const [listVariables, setListVariables] = useState(new Map());
  const [isModalLeaveOpen, setIsModalLeaveOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVar, setCurrentVar] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [isChangeIndex, setIsChangeIndex] = useState();
  const [currentIndex, setCurrentIndex] = useState();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const environment = useSelector(state =>
    state.environment?.getIn(["data", organizationId, projectId, environmentId])
  );
  const envVariables = useSelector(
    state => state.environmentVariable || new Map()
  );
  const editedLine = envVariables.get("editedLine");
  const isNewVar = envVariables.get("isNew", false);
  const isLoading = envVariables.get("loading", true);
  const errors = envVariables.get("errors", {});
  const status = envVariables.get("status");
  const variables = envVariables
    .getIn(["data", organizationId, projectId, environmentId], new Map())
    ?.valueSeq()
    ?.toJS();

  useEffect(
    () => {
      dispatch(getVariables({ organizationId, projectId, environmentId }));
    },
    [environmentId]
  );

  useEffect(
    () => {
      if (!isNewVar) setListVariables(sortListById(variables));
    },
    [envVariables]
  );

  useEffect(
    () => {
      if (status === "fulfilled") {
        setIsChanged(false);
        setIsChangeIndex(false);
        setCurrentIndex(null);
        dispatch(cancelUpdateVariable());
      }
    },
    [status]
  );

  const addNewVariable = () => {
    if (!isNewVar) {
      listVariables.unshift({});
      setListVariables(listVariables);
    }
    dispatch(editLine({ index: 0, isNew: true }));
  };

  const save = data => {
    setIsChanged(false);
    setIsChangeIndex(false);
    setCurrentIndex(null);

    if (isNewVar) {
      dispatch(addVariable({ organizationId, projectId, environment, data }));
    } else {
      const variable = listVariables.find(elt => elt.id === data.id);
      dispatch(
        updateVariable({
          organizationId,
          projectId,
          environmentId,
          variable,
          data
        })
      );
    }
  };

  const handleCancel = () => {
    if (isChanged && !isModalLeaveOpen) {
      setIsModalLeaveOpen(true);
      setIsChanged(true);
    } else {
      if (isNewVar) {
        const list = listVariables.splice(0, 1);
        setListVariables(list);
      }
      setIsChanged(false);
      setIsChangeIndex(false);
      setCurrentIndex(null);

      dispatch(cancelUpdateVariable());
    }
  };

  const expand = index => {
    if (editedLine === index) {
      handleCancel();
    } else {
      if (isNewVar) {
        handleCancel();
        // if  new var form was open, the list had an empty object
        index--;
      }
      if (isChanged && !isModalLeaveOpen) {
        setIsModalLeaveOpen(true);
        setIsChanged(true);
        setIsChangeIndex(true);
      } else {
        dispatch(editLine({ index, isNew: false }));
        setIsChanged(false);
        setIsChangeIndex(false);
      }
      setCurrentIndex(index);
    }
  };

  const openModal = variable => {
    setCurrentVar(variable);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalLeaveOpen(false);
    setIsModalOpen(false);
  };

  return (
    <div>
      <LiveMessage
        message={`${environment?.title} environment-level variable settings`}
        aria-live="polite"
      />
      <PageMeta
        title={`Variables | ${environment?.title} | ${project?.title}`}
      />
      {environment?.hasPermission &&
        environment.hasPermission("#manage-variables") && (
          <ButtonAdd
            id="add-new-variable"
            css={{ float: "right" }}
            onClick={addNewVariable}
          />
        )}
      <Heading2 id="settings-heading">
        {intl.formatMessage({ id: "variables" })}
      </Heading2>

      <PageDescription>
        <InfoDialog
          title="Learn more"
          linkText="Learn more"
          to={DOCS_CONFIG_APP_VARIABLES_URL}
          text="Set variables at both project or environment levels."
        />
        <p>
          {intl.formatMessage({
            id: "settings.environment.variables.description",
            defaultMessage:
              "Environment variables are available in this environment and its children only. They are not available in the build process."
          })}
        </p>
        {intl.formatMessage(
          {
            id: "settings.variables.details",
            defaultMessage:
              "• Variables beginning with <code>env:</code> will be exposed as Unix environment variables{br}• Variables beginning with <code>php:</code> will be interpreted as php.ini directives.{br}• All other variables will be part of the environment <code>$PLATFORM_VARIABLES</code> variable"
          },
          {
            br: <br />,
            code: txt => <code>{txt}</code> // eslint-disable-line react/display-name
          }
        )}
      </PageDescription>

      <section aria-labelledby="settings-heading">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {listVariables.length === 0 ? (
              <EmptyText>
                {intl.formatMessage({
                  id: "settings.environment.variables.empty",
                  defaultMessage: "no environment variables."
                })}
              </EmptyText>
            ) : (
              <>
                {listVariables.map((variable, index) => {
                  return (
                    <S.SettingLineWrapper
                      key={`${variable.name}-${index}-read`}
                    >
                      <SettingLine
                        id={`environment-variable-list-${variable.name}`}
                        info={
                          <div className="variable-info">
                            <span className="variable-info-main">
                              <span className="variable-name">
                                {variable.name}
                              </span>{" "}
                              ={" "}
                              <span className="variable-value">
                                {variable.is_sensitive
                                  ? "*****"
                                  : variable.value}
                              </span>
                            </span>
                            <span className="labels">
                              {getLabels(variable)}
                            </span>
                          </div>
                        }
                        isNew={!variable.id}
                        addNewTitle={intl.formatMessage({
                          id: "settings.variables.add.title",
                          defaultMessage: "Add variable"
                        })}
                        isOpen={editedLine === index}
                        openText={intl.formatMessage({ id: "edit" })}
                        onClick={() => expand(index)}
                      >
                        {editedLine === index && (
                          <VariableForm
                            key={`var-${variable ? variable.id : "new"}-form`}
                            onSave={save}
                            onCancel={handleCancel}
                            onDelete={() => openModal(variable)}
                            errors={errors}
                            isLoading={status === "pending"}
                            variable={variable}
                            environmentForm={true}
                            isChanged={isChanged}
                            isChangedUpdate={() => setIsChanged(true)}
                          />
                        )}
                      </SettingLine>
                    </S.SettingLineWrapper>
                  );
                })}
              </>
            )}
          </>
        )}

        <ModalConfirmDelete
          isOpen={isModalOpen}
          closeModal={closeModal}
          deleteFunction={() =>
            dispatch(
              deleteVariable({
                organizationId,
                projectId,
                environmentId,
                variable: currentVar
              })
            )
          }
          itemType="variable"
          itemName={currentVar?.name}
          itemId={currentVar?.name}
        />

        <ModalConfirmLeaveForm
          isOpen={isModalLeaveOpen}
          closeModal={closeModal}
          continueFunction={
            isChangeIndex
              ? () => {
                  expand(currentIndex);
                }
              : () => {
                  handleCancel();
                }
          }
          cancelFunction={closeModal}
        />
      </section>
    </div>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  environment: () => import("Reducers/environment"),
  environmentVariable: () => import("Reducers/environment/settings/variable")
})(EnvironmentVariableListField);
