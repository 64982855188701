import React, { useEffect, useState } from "react";
import client from "Libs/platform";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import Heading2 from "Components/styleguide/Heading2";
import EmptyVouchers from "./EmptyVouchers";
import VouchersList from "./VouchersList";

const Vouchers = ({ organizationId }) => {
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    let isCanceled = false;
    const getVouchers = async () => {
      const voucherData = await client.getOrganizationVouchers(organizationId);
      if (isCanceled) {
        return;
      }
      setVouchers(voucherData.vouchers);
    };

    getVouchers();

    return () => (isCanceled = true);
  }, []);
  const hasVouchers = vouchers.length === 0;

  return (
    <div>
      <Heading2>
        <FormattedMessage id="vouchers" />
      </Heading2>
      {hasVouchers ? <EmptyVouchers /> : <VouchersList vouchers={vouchers} />}
    </div>
  );
};

Vouchers.propTypes = {
  organizationId: PropTypes.string
};

export default Vouchers;
