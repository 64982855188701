import React from "react";
import styled from "styled-components";

import { Skeleton, Card, Square } from "Components/Skeleton";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 32px;
  grid-column-gap: 64px;
`;

const ProjectOverviewSkeleton = () => (
  <Skeleton synchronize>
    <Card>
      <Square height="200px" width="100%" />

      <Card padding="24px 32px">
        <Square height="24px" width="226px" margin="0 0 32px 0" />

        <Grid>
          <Square height="24px" width="116px" />
          <Square height="24px" width="116px" />
          <Square height="24px" width="116px" />
          <Square height="24px" width="116px" />
          <Square height="24px" width="116px" />
          <Square height="24px" width="116px" />
        </Grid>
      </Card>
    </Card>
  </Skeleton>
);

export default ProjectOverviewSkeleton;
