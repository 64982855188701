import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import jsonToYaml from "Libs/jsonToYaml";

import useDecodedParams from "Hooks/useDecodedParams";
import useLocalForage from "Hooks/useLocalForage";

import CopyableArea from "Components/CopyableArea";
import ExpandIcon from "Components/icons/ExpandIcon";
import Label from "Components/fields/Label";
import Loading from "Components/Loading";
import ModalWrapper from "Components/Modal";
import DropdownMultiPick from "Components/DropdownMultiPick";

import * as S from "../styles";

const Application = ({ filterImageType }) => {
  const intl = useIntl();
  const [selectedServices] = useLocalForage("wizardconfig");

  const { projectId } = useDecodedParams();

  const [modalOpen, setModal] = useState(false);
  const [app, setApp] = useState([]);
  const [list, setList] = useState([]);
  const [template, setTemplate] = useState("");

  const config = useSelector(state =>
    state.projectWizard?.getIn(["config", "data"])?.toJS()
  );
  const configLoading = useSelector(state =>
    state.projectWizard?.getIn(["config", "loading"], false)
  );
  const registry = useSelector(state =>
    state.projectWizard?.getIn(["registry", "data"])?.toJS()
  );
  const registryLoading = useSelector(state =>
    state.projectWizard?.getIn(["registry", "loading"], false)
  );

  useEffect(
    () => {
      if (!registry) return;
      const keys = Object.keys(registry) || [];
      const values = keys.reduce((acc, cu) => {
        if (registry[cu]?.name && registry[cu].runtime && filterImageType(cu)) {
          acc.push({ value: cu, label: registry[cu].name });
        }
        return acc;
      }, []);
      setList(values);
    },
    [Object.keys(registry).length]
  );

  const handleChange = selected => {
    const services = selectedServices[projectId] || [];

    if (app.value === selected.value) {
      setApp({});
      setTemplate("");
      return;
    }

    let tpl = `
      ${intl.formatMessage({ id: "yaml_app_description" })}${window.atob(
      config[selected.value].app
    )}
      ${intl.formatMessage({ id: "yaml_mount_description" })}
    `;

    let diskSize = "";
    let diskSizeValue = 0;
    let relationship = "";
    let web = registry[selected.value]?.data?.docs?.web;
    let hooks = registry[selected.value]?.data?.docs?.hooks;
    if (services.length !== 0) {
      relationship = intl.formatMessage({
        id: "yaml_relationship_description"
      });
      services.forEach(service => {
        const registryValue = registry[service.value];
        if (!registryValue) return;
        if (registryValue.disk) {
          if (!diskSize) {
            diskSize = intl.formatMessage({
              id: "yaml_disk_description"
            });
          }
          if (registryValue.min_disk_size > diskSizeValue) {
            diskSizeValue = registryValue.min_disk_size;
          }
        }
        if (
          registryValue.docs?.relationship_name &&
          registryValue.docs?.service_name &&
          registryValue.endpoint
        ) {
          let relationshipValue = `    ${
            registryValue.docs.relationship_name
          }: '${registryValue.docs.service_name}:${registryValue.endpoint}'\n`;
          relationship = relationship.concat(relationshipValue);
        }
      });

      if (diskSizeValue) {
        tpl = tpl.concat(diskSize).concat(diskSizeValue);
      }
      tpl = tpl.concat(relationship);
    }

    if (hooks) {
      const hookKeys = Object.keys(hooks);
      tpl = tpl.concat(`\n\nhooks:`);
      hookKeys.forEach(key => {
        tpl = tpl.concat(`\n    ${key}:`);
        tpl = tpl.concat(
          hooks[key].map(v => (v === "|" ? ` ${v}` : `\n      ${v}`)).join("")
        );
      });
    }

    if (web) {
      const webYaml = jsonToYaml(web);
      tpl = tpl.concat(`\n\nweb:\n  ${webYaml.replace(/\n/gm, "\n  ")}`);
    }

    setApp(selected);
    setTemplate(tpl);
  };

  return (
    <>
      <S.Wrapper>
        {!registryLoading && !configLoading && list.length > 0 ? (
          <DropdownMultiPick
            defaultText={
              app?.label
                ? app.label
                : intl.formatMessage({ id: "select_runtime" })
            }
            className="outlined"
            onChange={handleChange}
            value={app && [app]}
            options={list}
            maxHeight="186px"
          />
        ) : (
          <Loading />
        )}

        <S.FileExampleLabelWrapper>
          <Label>
            {intl.formatMessage({ id: "example_file" })}: .platform.app.yaml
          </Label>
          <S.ExpandButton onClick={() => setModal(!modalOpen)}>
            <ExpandIcon />
          </S.ExpandButton>
        </S.FileExampleLabelWrapper>

        <CopyableArea
          id="application-yaml"
          className={template ? "" : "empty-copy"}
          content={
            template ? template : intl.formatMessage({ id: "yaml_app_default" })
          }
          iconAbove={true}
          linkStyle={true}
        >
          {template ? template : intl.formatMessage({ id: "yaml_app_default" })}
        </CopyableArea>
      </S.Wrapper>

      <ModalWrapper
        isOpen={modalOpen}
        closeModal={() => setModal(!modalOpen)}
        modalClass="modal-build-log modal-large"
        title={`${intl.formatMessage({
          id: "example_file"
        })}: .platform.app.yaml`}
        copyText={template}
      >
        <pre>
          {template ? template : intl.formatMessage({ id: "yaml_app_default" })}
        </pre>
      </ModalWrapper>
    </>
  );
};

Application.propTypes = {
  filterImageType: PropTypes.func
};

export default Application;
