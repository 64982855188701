import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Map } from "immutable";

import { capitalize, goToEnvironment } from "Libs/utils";
import {
  CLI_COMMAND_NAME,
  DOCS_CLI_GETTING_STARTED_URL
} from "Constants/documentationUrls";
import AccessibleTooltip from "Components/AccessibleTooltip";
import ModalWrapper from "Components/Modal";
import CheckboxField from "Components/fields/CheckboxField";
import Button from "UI/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";

import { sync } from "Reducers/environment/actions/sync";

class EnvironmentSyncPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      syncCode: false,
      syncData: false
    };
    this.toggleSyncData = this.toggleSyncData.bind(this);
    this.toggleSyncCode = this.toggleSyncCode.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  toggleSyncData() {
    this.setState(prevState => ({
      syncData: !prevState.syncData
    }));
  }

  toggleSyncCode() {
    this.setState(prevState => ({
      syncCode: !prevState.syncCode
    }));
  }

  render() {
    const {
      environment,
      parent,
      isLoading,
      push,
      organizationId,
      projectId,
      environmentId,
      intl,
      sync,
      errors,
      isEnvironmentLoading
    } = this.props;

    const tooltipOffset = (
      { left, top },
      currentEvent,
      currentTarget,
      node
    ) => {
      const d = document.documentElement;
      left = Math.min(d.clientWidth - node.clientWidth, left);
      top = Math.min(d.clientHeight - node.clientHeight, top);

      left = Math.max(0, left) - 45;
      top = Math.max(0, top);
      return { top, left };
    };

    const { syncData, syncCode } = this.state;

    const disableSyncButton = !syncData && !syncCode;

    if (!isLoading && !parent) {
      return <div>To sync an env need to have a parent</div>;
    }

    const command = `${CLI_COMMAND_NAME} sync code data`;

    return (
      <ModalWrapper
        id="environment-sync-modal"
        shouldCloseOnOverlayClick={true}
        isOpen={true}
        title={[
          intl.formatMessage({ id: "sync" }),
          " ",
          <strong key="parent">{parent.title}</strong>,
          " ",
          intl.formatMessage({ id: "into" }),
          " ",
          <strong key="name">{environment.title}</strong>
        ]}
        onRequestClose={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        closeModal={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        modalClass="modal-environment-action modal-environment-sync"
        announceTitle={
          intl.formatMessage({ id: "sync" }) +
          " " +
          parent.name +
          " " +
          intl.formatMessage({ id: "into" }) +
          " " +
          environment.name
        }
      >
        <ContentPaneLayout className="modal-body">
          {this.state.errors &&
            this.state.errors.message && (
              <Error>{this.state.errors.message}</Error>
            )}
          <p>
            Select what you’d like to include in your sync of your{" "}
            <strong>{parent.title}</strong> environment to your{" "}
            <strong>{environment.title}</strong> environment.
          </p>
          <div>
            <CheckboxField
              id="environment-sync-modal-data-switch"
              forId="environment-sync-modal-data"
              label={
                <span>
                  {intl.formatMessage({ id: "sync.data.replace" })}{" "}
                  <strong>{environment.name}</strong>{" "}
                  {intl.formatMessage({ id: "sync.data.with" })}{" "}
                  <strong>{parent.name}</strong>.
                </span>
              }
              onChange={this.toggleSyncData}
              value={syncData}
              error={errors && errors.detail}
            />
            <CheckboxField
              id="environment-sync-modal-code-switch"
              forId="environment-sync-modal-code"
              label={
                <span>
                  {intl.formatMessage({ id: "sync.code.merge" })}{" "}
                  <strong>{parent.name}</strong>{" "}
                  {intl.formatMessage({ id: "sync.code.into" })}{" "}
                  <strong>{environment.name}</strong>.
                </span>
              }
              onChange={this.toggleSyncCode}
              value={syncCode}
              error={errors && errors.detail}
            />
          </div>

          <Heading4 style={{ marginBottom: 16 }}>
            CLI{" "}
            <InfoDialog
              title="Learn more"
              text={`You can also use this terminal command to sync from ${
                parent.title
              } to ${environment.title}`}
              to={DOCS_CLI_GETTING_STARTED_URL}
              linkText="Help"
            />
          </Heading4>
          <CopyableArea
            id="environment-sync-cmd"
            content={command}
            singleLine={true}
          >
            {command}
          </CopyableArea>
          {isEnvironmentLoading || isLoading ? (
            <Loading />
          ) : (
            <ButtonWrapper className="modal-buttons">
              <AccessibleTooltip
                tooltipProps={{
                  children: intl.formatMessage({ id: "sync.select" }),
                  className: "rightArrowDown",
                  place: "top",
                  disable: !disableSyncButton,
                  overridePosition: tooltipOffset
                }}
              >
                <Button
                  id="environment-sync-save-btn"
                  type="submit"
                  aria-label={intl.formatMessage({ id: "sync.button" })}
                  onClick={e => {
                    e.preventDefault();
                    sync(syncData, syncCode);
                  }}
                  disabled={disableSyncButton}
                >
                  {capitalize(intl.formatMessage({ id: "sync.button" }))}
                </Button>
              </AccessibleTooltip>
              <Button
                id="environment-sync-cancel-btn"
                variant="secondary"
                aria-label={intl.formatMessage({ id: "cancel" })}
                onClick={() =>
                  goToEnvironment(
                    push,
                    organizationId,
                    projectId,
                    environmentId
                  )
                }
              >
                {capitalize(intl.formatMessage({ id: "cancel" }))}
              </Button>
            </ButtonWrapper>
          )}
        </ContentPaneLayout>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn(
    ["data", props.organizationId, props.projectId, props.environmentId],
    {}
  );

  const synchronisation = state.synchronisation || new Map();

  return {
    environment,
    parent: state.environment.getIn(
      ["data", props.organizationId, props.projectId, environment.parent],
      {}
    ),
    isEnvironmentLoading: state.environment.get("loading"),
    isLoading: synchronisation.get("loading"),
    errors: synchronisation.get("errors", {})
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  sync: (syncData, syncCode) =>
    dispatch(
      sync(props.organizationId, props.projectId, props.environmentId, {
        syncData,
        syncCode
      })
    )
});

EnvironmentSyncPane.propTypes = {
  environment: PropTypes.object,
  parent: PropTypes.object,
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  push: PropTypes.func,
  sync: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired,
  errors: PropTypes.object,
  isEnvironmentLoading: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentSyncPane));
