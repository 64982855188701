import styled, { css } from "styled-components";
import { themeHelpers } from "Libs/theme";

export const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background: ${props => themeHelpers(props.theme, "sectionColor")};
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
  .description {
    font-size: 15px;
    color: ${props => props.theme.descriptionText};
    margin-bottom: 24px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    .info-dialog {
      margin: -2px 8px 0 -4px;
      min-width: 24px;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
`;

export const DomainFormLayout = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
  .primary {
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
`;
