import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";

import { entities } from "Libs/platform";
import BasicFormSettings from "./components/BasicFormSettings";
import Heading2 from "Components/styleguide/Heading2";
import Error from "Components/LargeInlineError";
import styled from "styled-components";

import {
  clearErrors,
  pictureError,
  loadUserProfile,
  updateUserProfile
} from "Reducers/profile";

const ErrorBox = styled.div`
  max-width: 100%;
  padding-right: 24px;
`;

const FlexibleHeading2 = styled(Heading2)`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const BasicSettingsContainer = ({ organizationId }) => {
  const [newOrganizationId, setNewOrganizationId] = useState(null);
  const [apiErrors, setApiErrors] = useState();
  const dispatch = useDispatch();
  const {
    me,
    emailReset,
    errors,
    profile,
    cacheKey,
    isLoadingProfile
  } = useSelector(({ app, profile }) => ({
    me: app.get("me", new Map()).toJS(),
    emailReset: profile.get("emailReset"),
    errors: profile.get("errors"),
    profile: profile.getIn(["data", organizationId], new Map()).toJS(),
    isLoadingProfile: profile.get("loading"),
    cacheKey: profile.getIn(["cacheKeys", organizationId], null)
  }));

  useEffect(
    () => {
      let isCanceled = false;
      (async () => {
        const shouldLoadOtherUser = me.username !== organizationId;
        if (shouldLoadOtherUser) {
          try {
            const otherUser = await entities.AuthUser.getUserByUsername(
              organizationId
            );

            dispatch(loadUserProfile(otherUser.id));
          } catch (error) {
            if (isCanceled) {
              return;
            }
            setApiErrors(JSON.parse(error));
          }
        }

        return () => (isCanceled = true);
      })();
    },
    [organizationId, profile.id]
  );

  useEffect(
    () => {
      if (!isLoadingProfile && newOrganizationId && !errors) {
        setNewOrganizationId(null);
        window.location = `/-/users/${newOrganizationId}/settings`;
      }
    },
    [isLoadingProfile, newOrganizationId]
  );

  const onSave = async data => {
    dispatch(updateUserProfile(profile.id, data));
    setNewOrganizationId(
      data.username !== organizationId ? data.username : null
    );
  };

  let errorMessage =
    errors?.message ||
    errors?.title ||
    apiErrors?.title ||
    JSON.stringify(errors);

  if (errorMessage?.includes("exceeding the maximum file size")) {
    errorMessage = (
      <FormattedMessage
        id="settings.profile.maxfilesizeerror"
        values={{ name: errors.name, size: (errors.size / 1000000).toFixed(2) }}
      />
    );
  }

  return (
    <>
      {errorMessage && (
        <ErrorBox>
          <Error onClose={() => dispatch(clearErrors())}>{errorMessage}</Error>
        </ErrorBox>
      )}
      <FlexibleHeading2 id="settings-heading">
        <FormattedMessage id="account_settings" />
      </FlexibleHeading2>
      <BasicFormSettings
        onSave={onSave}
        className="organization-basic-form"
        {...profile}
        emailReset={emailReset}
        cacheKey={cacheKey}
        onPictureError={(error, picture) =>
          dispatch(pictureError(error, picture))
        }
      />
    </>
  );
};

BasicSettingsContainer.propTypes = {
  organizationId: PropTypes.string
};

export default BasicSettingsContainer;
