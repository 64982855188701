import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Custom = ({ integration }) => {
  const intl = useIntl();

  const getTags = kind => {
    if (!integration[kind]?.length) return;

    return (
      <p key={`l-${kind}`}>
        <label>
          {intl.formatMessage({
            id: `integration.custom.${kind}`,
            defaultMessage: kind
          })}
        </label>
        {integration[kind].map((elt, i) => (
          <S.Tag key={`${kind}-${i}`}>{elt}</S.Tag>
        ))}
      </p>
    );
  };

  return (
    <S.Wrapper>
      {integration.url && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.custom.url",
              defaultMessage: "URL"
            })}
          </label>
          <a href={integration.url} target="_blank" rel="noopener noreferrer">
            {integration.url}
          </a>
        </p>
      )}

      {["events", "states", "environments", "excluded_environments"].map(kind =>
        getTags(kind)
      )}
    </S.Wrapper>
  );
};

Custom.propTypes = {
  integration: PropTypes.object
};

export default Custom;
