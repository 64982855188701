import React from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { getEnvironmentURI } from "Libs/utils";

import PageMeta from "Components/PageMeta";
import ReportPopup from "Components/ReportPopup";
import ContentLayout from "Components/ContentLayout";
import useDecodedParams from "Hooks/useDecodedParams";

import Dedicated from "./views/Dedicated";

const getMetricsLink = links => {
  if (!(links instanceof Array)) {
    return false;
  }

  if (links.length < 1) {
    return false;
  }

  const [link] = links;

  if (!link.href || !link.collection || !link.type) {
    return false;
  }

  return link;
};

const Page = () => {
  const { organizationId, projectId, environmentId } = useDecodedParams();
  const environment = useSelector(({ environment }) =>
    environment.getIn(["data", organizationId, projectId, environmentId])
  );

  const link = getMetricsLink(environment?.data._links["#metrics"]);

  if (environment && !link) {
    return (
      <Redirect
        to={getEnvironmentURI(organizationId, projectId, environmentId)}
      />
    );
  }

  if (!link) {
    return null;
  }

  switch (link.type) {
    case "dedicated":
      return (
        <ContentLayout>
          <PageMeta title="Metrics" />
          <Dedicated link={link} />
          <ReportPopup
            id="metrics"
            title={<FormattedMessage id="metrics.report.title" />}
            text={<FormattedMessage id="metrics.report.text" />}
          />
        </ContentLayout>
      );
    default:
      return (
        <Redirect
          to={getEnvironmentURI(organizationId, projectId, environmentId)}
        />
      );
  }
};

export default Page;
