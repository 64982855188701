import styled, { css } from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import Heading3 from "Components/styleguide/Heading3";
import URLOverlay from "../../components/URLOverlay";

export const Wrapper = styled.div``;

export const Header = styled.header`
  display: flex;
  max-width: 100%;
  height: 200px;
  margin: 0;
`;

export const Url = styled(URLOverlay)`
  width: 100%;
`;

export const Details = styled.div`
  min-height: 250px;
  padding: 26px 32px 30px 32px;
  box-sizing: border-box;
  color: ${props => props.theme.sectionHeading};
`;

export const ProjectName = styled(Heading3)`
  margin: 0 0 1px 0;
  line-height: 24px;
`;

export const Info = styled.div`
  display: block;
  margin-top: 15px;
`;

export const Label = styled.label`
  display: block;
  color: ${props => (props.theme.name === "contrast" ? "#4A495E" : "#98a0ab")};
  font-size: 14px;
  line-height: 19px;
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Value = styled.span`
  visibility: hidden;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 18px;
  ${semiBoldAlias};
  color: ${props => props.theme.sectionHeading};

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Infos = styled.div`
  display: grid;
  box-sizing: border-box;
  color: ${props => props.theme.sectionHeading};
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;

  ${props =>
    props.displayValues &&
    css`
      ${Value} {
        visibility: visible;
      }
    `};
`;

export const RegionSuffix = styled.span`
  color: ${props => (props.theme.name === "contrast" ? "#53585f" : "#98a0ab")};
  margin-left: 7px;
  font-size: 14px;
  line-height: 0.8;
`;
