import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import StepTitle from "./StepTitle";

import * as S from "./styles";

const Breadcrumbs = ({ currentStep, goToStep, steps = [] }) => {
  const intl = useIntl();

  const handleClick = step => {
    if (currentStep !== "building") {
      goToStep(step);
    }
  };

  return (
    <S.Wrapper className="breadcrumbs" role="complementary">
      <S.Title>{intl.formatMessage({ id: "new_project" })}</S.Title>

      <S.Steps>
        {steps.map((step, i) => {
          const isActive = step === currentStep;
          return (
            <StepTitle
              key={step}
              isActive={isActive}
              isComplete={i < steps.indexOf(currentStep)}
              step={step}
              onClick={handleClick}
            />
          );
        })}
      </S.Steps>
    </S.Wrapper>
  );
};

Breadcrumbs.propTypes = {
  currentStep: PropTypes.string,
  goToStep: PropTypes.func,
  steps: PropTypes.array
};

export default Breadcrumbs;
