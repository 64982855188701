import styled, { css } from "styled-components";

export const Line = styled.path`
  stroke-linejoin: round;
  stroke-linecap: join;
  shape-rendering: geometricPrecision;
  stroke-width: 1.5;
  fill: none;
  stroke: url(#host-fire);
  opacity: 1;
  cursor: pointer;

  ${({ isAverage }) =>
    isAverage &&
    css`
      stroke: url(#average-fire);
    `};

  ${({ anyActive, isActive }) =>
    anyActive &&
    !isActive &&
    css`
      opacity: 0.3;
    `};
`;
