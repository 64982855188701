import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import { capitalize } from "Libs/utils";

import { DOCS_OWN_DOMAIN_URL } from "Constants/documentationUrls";
import Button from "UI/Button";
import InputField from "Components/fields/InputField";
import ToggleSwitch from "Components/ToggleSwitch";
import Loading from "Components/Loading";
import InfoDialog from "Components/InfoDialog";

import * as S from "./DomainForm.styles";

class DomainForm extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onIsDefaultChange = this.onIsDefaultChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.state = {
      ...this.props.domain,
      isDefault: this.props.isDefault,
      isChanged: false
    };
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
      isChanged: true
    });
    this.props.onChange();
  }

  onNameChange(event) {
    this.onChange("name", event.target.value);
  }

  onIsDefaultChange() {
    const isChecked = this.state.isDefault;

    this.setState(prevState => ({
      isDefault: !prevState.isDefault,
      isChange: true
    }));

    this.onChange("isDefault", !isChecked || "");
  }

  onSave() {
    this.props.onSave(this.state);
  }

  onCancel() {
    this.props.onCancel();
  }

  render() {
    const { intl, name, isLoading, domain, onDelete, isNew } = this.props;
    const isExistingDomain = name ? true : false;

    const shouldRenderSaveCancelButtons = isNew || this.state.isChanged;
    const shouldRenderDeleteButton =
      domain.hasLink && domain.hasLink("#delete");

    const shouldRenderButtonSection =
      shouldRenderSaveCancelButtons || shouldRenderDeleteButton;

    return (
      <S.Layout className={isNew ? "add-form" : ""}>
        <LiveMessage
          message={
            name
              ? intl.formatMessage({ id: "edit_domain" })
              : intl.formatMessage({ id: "add_domain" })
          }
          aria-live="polite"
        />
        <S.DomainFormLayout
          id={isNew ? "domain-add-form" : "domain-edit-form"}
          aria-labelledby="add-domain-heading"
          onSubmit={event => event.preventDefault()}
        >
          <div className="description">
            <InfoDialog
              title="Domain name"
              text="Learn more about configuring your domain name."
              linkText="Learn more"
              to={DOCS_OWN_DOMAIN_URL}
            />
            <span>
              Enter a domain name for this project. Please do not contain www.
              if your <code>routes.yaml</code> file already includes it.
            </span>
          </div>
          <InputField
            label={intl.formatMessage({ id: "domain_name" })}
            id="domain-name-input"
            value={this.state.name || ""}
            onChange={this.onNameChange}
            error={
              typeof this.props.errors === "string"
                ? this.props.errors
                : this.props.errors?.name
            }
            isDisabled={isExistingDomain}
          />
          <ToggleSwitch
            title={intl.formatMessage({ id: "is_default" })}
            description={intl.formatMessage({
              id: "is_default.description"
            })}
            onClick={this.onIsDefaultChange}
            value={this.state.isDefault}
            id="default-domain"
          />
          {isLoading ? (
            <Loading />
          ) : (
            shouldRenderButtonSection && (
              <S.Flex
                justifyContent={
                  shouldRenderSaveCancelButtons ? "space-between" : "flex-end"
                }
              >
                {shouldRenderSaveCancelButtons && (
                  <div>
                    <Button
                      readOnly={isExistingDomain}
                      id="save-domain-btn"
                      type="submit"
                      aria-label={
                        name
                          ? intl.formatMessage({ id: "save" })
                          : intl.formatMessage({ id: "add_domain" })
                      }
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        return this.state.name && this.onSave();
                      }}
                    >
                      {name
                        ? capitalize(intl.formatMessage({ id: "save" }))
                        : capitalize(intl.formatMessage({ id: "add_domain" }))}
                    </Button>
                    <Button
                      id="cancel-domain-btn"
                      variant="secondary"
                      aria-label={intl.formatMessage({ id: "cancel" })}
                      onClick={this.onCancel}
                    >
                      {capitalize(intl.formatMessage({ id: "cancel" }))}
                    </Button>
                  </div>
                )}
                {shouldRenderDeleteButton && (
                  <Button
                    variant="tertiary"
                    id={`project-domain-list-${(domain.name || "").replace(
                      /\./g,
                      ""
                    )}-delete-btn`}
                    type="button"
                    aria-label={intl.formatMessage({ id: "delete" })}
                    onClick={onDelete}
                  >
                    {capitalize(intl.formatMessage({ id: "delete" }))}
                  </Button>
                )}
              </S.Flex>
            )
          )}
        </S.DomainFormLayout>
      </S.Layout>
    );
  }
}

DomainForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isDefault: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  domain: PropTypes.object,
  intl: PropTypes.object,
  isNew: PropTypes.bool,
  onChange: PropTypes.func,
  index: PropTypes.number
};

export default injectIntl(DomainForm);
