import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";

/**
 * Get project's variables
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 *
 */
export const getVariables = createAsyncThunk(
  "app/project/environment/variables",
  async ({ environmentId, projectId }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const variables = await client.getEnvironmentVariables(
      projectId,
      encodeURIComponent(environmentId)
    );
    return variables;
  }
);

/**
 * Create a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {object} environment
 * @param {object} data
 *
 */
export const addVariable = createAsyncThunk(
  "app/project/environment/variable/add",
  async ({ environment, data }, { rejectWithValue }) => {
    if (!environment) return false;

    try {
      const result = await environment.setVariable(
        data.name,
        data.value,
        data.is_json,
        data.is_enabled,
        data.is_inheritable,
        data.is_sensitive
      );

      if ((result.data && result.data.code / 100) === 4) {
        return rejectWithValue({ error: result.detail });
      }

      const variable = await result?.getEntity();
      return variable;
    } catch (err) {
      logger(
        {
          errMessage: err.message,
          environmentId: environment.id
        },
        {
          action: "environmentAddVariable"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Update a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 * @param {object} variable
 * @param {object} data
 *
 */
export const updateVariable = createAsyncThunk(
  "app/project/environment/variable/update",
  async ({ environmentId, projectId, variable, data }, { rejectWithValue }) => {
    const fields = Object.assign(
      {},
      { projectId, environmentId },
      { variableId: variable.id },
      data
    );
    try {
      const result = await variable.update(fields);
      const newVariable = await result?.getEntity();
      return newVariable;
    } catch (err) {
      logger(
        {
          errMessage: err.message,
          environmentId,
          variableId: variable.id
        },
        {
          action: "environmentUpdateVariable"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Delete a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 * @param {object} variable
 *
 */
export const deleteVariable = createAsyncThunk(
  "app/project/environment/variable/delete",
  async ({ variable }) => {
    if (!variable) return false;
    await variable.delete().catch(err => {
      const errMessage = JSON.parse(err);
      logger(
        {
          errMessage,
          variableId: variable.id
        },
        {
          action: "environmentDeleteVariable"
        }
      );
      throw new Error(errMessage.error);
    });
    return variable;
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const variables = createSlice({
  name: "variables",
  initialState: Map({ data: new Map() }),
  reducers: {
    editLine(state, action) {
      return state
        .set("editedLine", action.payload.index)
        .set("isNew", action.payload.isNew)
        .set("status", "idle");
    },
    cancelUpdateVariable(state) {
      return state
        .set("editedLine", false)
        .set("isNew", false)
        .set("status", "idle");
    }
  },
  extraReducers: {
    // GET LIST
    [getVariables.pending]: state => state.set("loading", true),
    [getVariables.fulfilled]: (state, action) => {
      const { environmentId, organizationId, projectId } = action.meta.arg;
      return state
        .set(
          "data",
          action.payload.reduce((list, variable) => {
            return list.setIn(
              [organizationId, projectId, environmentId, variable.id],
              variable
            );
          }, new Map())
        )
        .set("loading", false);
    },
    [getVariables.rejected]: (state, action) => setError(state, action),

    // ADD
    [addVariable.pending]: state =>
      state
        .set("loading", false)
        .set("status", "pending")
        .delete("errors"),
    [addVariable.fulfilled]: (state, action) => {
      const { environment, organizationId, projectId } = action.meta.arg;
      return state
        .setIn(
          [
            "data",
            organizationId,
            projectId,
            environment.id,
            action.payload.id
          ],
          action.payload
        )
        .set("status", "fulfilled");
    },
    [addVariable.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // UPDATE
    [updateVariable.pending]: state =>
      state
        .set("loading", false)
        .set("status", "pending")
        .delete("errors"),
    [updateVariable.fulfilled]: (state, action) => {
      const { environmentId, organizationId, projectId } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, projectId, environmentId, action.payload.id],
          fromJS(action.payload)
        )
        .set("status", "fulfilled");
    },
    [updateVariable.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // DELETE
    [deleteVariable.pending]: state =>
      state.set("loading", true).delete("errors"),
    [deleteVariable.fulfilled]: (state, action) => {
      const { environmentId, organizationId, projectId } = action.meta.arg;
      return state
        .deleteIn([
          "data",
          organizationId,
          projectId,
          environmentId,
          action.payload.id
        ])
        .set("loading", false);
    },
    [deleteVariable.rejected]: (state, action) => setError(state, action)
  }
});

export const { cancelUpdateVariable, editLine } = variables.actions;
export default variables.reducer;
