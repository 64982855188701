import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  color: ${props => props.theme.sectionText};
  .heading-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  h2 {
    margin-bottom: 0;
    line-height: 33px;
    &:first-letter {
      text-transform: none;
    }
  }
  .live {
    display: inline-block;
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "sectionColor")
        : themeHelpers(props.theme, "linkTint")};
    color: ${props =>
      props.theme
        ? props => themeHelpers(props.theme, "buttonColor")
        : "#4786ff"};
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    padding: 0 8px;
    ${semiBoldAlias()};
    border-radius: 2px;
  }
  .label {
    font-size: 14px;
    line-height: 19px;
    color: ${props => props.theme.envLabel};
    display: flex;
    align-items: center;
    ${semiBoldAlias};
    &:first-letter {
      text-transform: uppercase;
    }
    .info-dialog {
      margin-bottom: -2px;
    }
  }
  .value,
  .status {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
    ${semiBoldAlias};
  }
  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    font-size: 14px;
    &.button {
      color: #fff;
      padding: 0 16px;
      font-size: 15px;
    }
  }
  .env-url {
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "sectionColor")
        : themeHelpers(props.theme, "linkTint")};
    margin: 0 -32px -32px;
    width: 100%;
    padding: 0 32px;
    border-radius: 0 0 4px 4px;
    height: 64px;
    display: flex;
    align-items: center;
    svg {
      min-width: 24px;
      path {
        fill: ${props =>
          props.theme.name === "contrast"
            ? props.theme.codeText
            : props.theme.links};
      }
    }
    > a {
      white-space: nowrap;
      display: inline-block;
      padding: 0 8px;
      max-width: calc(100% - 32px);
      text-decoration: none;
      position: relative;
      ${props =>
        props.theme.name === "contrast" && `color: ${props.theme.codeText};`};
      &:hover {
        &:after {
          content: "";
          width: calc(100% - 16px);
          position: absolute;
          height: 1px;
          background: ${props => props.theme.links};
          bottom: 0;
          left: 8px;
          display: inline-block;
        }
      }
      &:focus {
        outline: none;
        border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
        border-radius: 4px;
        box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
        text-decoration: none;
        margin: -1px;
      }
      &:active {
        border: none;
        box-shadow: none;
        text-decoration: underline;
        margin: 0;
      }
    }
    &.inactive {
      background: ${props =>
        props.theme.name === "dark"
          ? props.theme.dark
          : "rgba(201, 208, 228, 0.5)"};
      color: ${props => props.theme.subNavText};
      font-size: 13px;
      ${semiBoldAlias};
      svg {
        margin-right: 16px;
        path {
          fill: ${props => props.theme.subNavText};
        }
      }
    }
  }
`;

export const GridWrapper = styled.div`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 0 !important;
  .grid {
    color: ${props => props.theme.sectionText};
    margin: 0 -32px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
    display: flex;
    background: transparent;
    > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .time-ago,
    .time-date {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8px;
      position: relative;
      ${semiBoldAlias};
    }
    .number {
      font-size: 24px;
    }
    .label {
      margin-bottom: 0;
    }
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  .label {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .subtext {
    margin-top: 0;
  }
  .status {
    display: flex;
    align-items: center;
    ${semiBoldAlias};
    .bullet {
      height: 6px;
      width: 6px;
      border-radius: 6px;
      background: ${props => props.theme.success};
      display: inline-block;
      margin-right: 12px;
    }
    .text {
      display: inline-block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    &.status-inactive {
      .bullet {
        background: #98a0ab;
      }
    }
    &.status-dirty,
    &.status-disabled {
      .bullet {
        background: #ff8359;
      }
    }
  }
`;

export const ExternalEnvLink = styled.span`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.theme.envLabel};
  display: flex;
  align-items: center;
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
  .info-dialog {
    margin-bottom: -2px;
  }
`;

export const RegionSuffix = styled.span`
  color: ${props => (props.theme.name === "contrast" ? "#53585f" : "#98a0ab")};
  margin-left: 7px;
  font-size: 14px;
  line-height: 0.8;
`;

export const Value = styled.div`
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
  ${semiBoldAlias};
`;

export const EnvironmentType = styled.span`
  display: inline-block;
  background: ${props => props.theme.background};
  color: ${props =>
    props.theme
      ? props => themeHelpers(props.theme, "sectionTextLight")
      : "#4786ff"};
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  ${semiBoldAlias()};
  border-radius: 2px;
  margin-right: 4px;
  text-transform: capitalize;
`;
