import ndjson from "can-ndjson-stream";

/**
 * Parses a \n separated string of json objects and combines into a Js array.
 *
 * @param {String} stream \n separated json objects
 * @returns {Array<any>} Array of objets
 */
const streamToArray = async stream => {
  const data = ndjson(stream).getReader();
  const points = [];
  let done = false;

  do {
    let result;
    try {
      result = await data.read();
      if (result.value) {
        points.push(result.value);
      }

      done = result.done;
    } catch (error) {
      done = true;
      throw error;
    }
  } while (!done);

  return points;
};

export default streamToArray;
