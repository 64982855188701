import React from "react";
import { useParams } from "react-router-dom";

import ApiTokenList from "./containers/ApiTokenList";

import ContentLayout from "Components/ContentLayout";

const Page = () => {
  const { organizationId } = useParams();
  return (
    <ContentLayout>
      <ApiTokenList organizationId={organizationId} />
    </ContentLayout>
  );
};

export default Page;
