import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import countryList from "react-select-country-list";
import logger from "Libs/logger";
import { capitalize } from "Libs/utils";

import InputField from "Components/fields/InputField";
import Dropdown from "Components/Dropdown";
import Button from "UI/Button";
import client from "Libs/platform";
import { organizationProfileSelector } from "Reducers/organization/profile";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import { vatCountries } from "Constants/vatCountries";
import {
  AddressWrapper,
  InlineFields,
  Layout,
  PaymentWrapper
} from "./AddressForm.styles";
import Loading from "Components/Loading";
import Error from "Components/Error";

const AddressForm = ({ finalize, organizationId, addressData }) => {
  const intl = useIntl();
  const addressStructure = {
    name_line: "",
    thoroughfare: "",
    premise: "",
    locality: "",
    administrative_area: "",
    postal_code: "",
    country: ""
  };
  /*const username = useSelector(
    state => state.app && state.app.getIn(["me", "username"])
  );
  const fullName = `${useSelector(state =>
    state.profile?.getIn(["data", username, "first_name"])
  )} ${useSelector(state =>
    state.profile?.getIn(["data", username, "last_name"])
  )}`;
  const userId = useSelector(
    state => state.app && state.app.getIn(["me", "id"])
  );
  const companyNameRedux = useSelector(state =>
    state.profile.getIn(["data", username, "company_name"])
  );
  const billingContactRedux = useSelector(state =>
    state.profile.getIn(["data", username, "billing_contact"])
  );
  const vatNumberRedux = useSelector(state =>
    state.profile.getIn(["data", username, "vat_number"])
  );*/
  const profile = useSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  const companyNameRedux = profile?.company_name;
  const billingContactRedux = profile?.billing_contact;
  const vatNumberRedux = profile?.vat_number;
  const fullName = `${profile?.username?.first_name} ${
    profile?.username?.first_name
  }`;

  const [saving, setSaving] = useState(false);
  const [address, setAddress] = useState(addressStructure);
  const [addressProxy, setProxy] = useState();
  const [companyName, setCompanyName] = useState(companyNameRedux);
  const [billingContact, setBillingContact] = useState(billingContactRedux);
  const [originalAddress, setOriginalAddress] = useState(addressStructure);
  const [originalCompanyName, setOriginalCompanyName] = useState(
    companyNameRedux
  );
  const [originalBillingContact, setOriginalBillingContact] = useState(
    billingContactRedux
  );
  const [vatNumber, setVatNumber] = useState(vatNumberRedux);
  const [showButtons, setShowButtons] = useState(false);
  const [error, setError] = useState("");

  const setAddressData = data => {
    const addressObj = {
      name_line: data?.name_line,
      thoroughfare: data?.thoroughfare,
      premise: data?.premise,
      locality: data?.locality,
      administrative_area: data?.administrative_area,
      postal_code: data?.postal_code,
      country: data?.country
    };
    setAddress(addressObj);
    setOriginalAddress(addressObj);
  };

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const orgId = organization?.id;

  useEffect(
    () => {
      const getAddress = async () => {
        setAddressData(addressData);
        setProxy(addressData);
      };
      getAddress();
    },
    [orgId, addressData]
  );
  useEffect(
    () => {
      setBillingContact(billingContactRedux);
      setCompanyName(companyNameRedux);
      setVatNumber(vatNumberRedux);
    },
    [billingContactRedux, companyNameRedux, vatNumberRedux]
  );

  const showVat = country => vatCountries.find(i => i === country);

  const updateAddressField = (value, field) => {
    setAddress(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const onNameChange = e => updateAddressField(e.target.value, "name_line");

  const onVatNumberChange = e => {
    setVatNumber(e.target.value);
  };

  const onCompanyNameChange = e => {
    setCompanyName(e.target.value);
  };

  const onBillingContactChange = e => {
    setBillingContact(e.target.value);
  };

  const onCountryChange = ({ value }) => {
    setShowButtons(true);
    updateAddressField(value, "country");
  };

  const onThoroughfareChange = e => {
    updateAddressField(e.target.value, "thoroughfare");
  };

  const onPremiseChange = e => {
    updateAddressField(e.target.value, "premise");
  };

  const onLocalityChange = e => {
    updateAddressField(e.target.value, "locality");
  };

  const onAdministrativeAreaChange = e => {
    updateAddressField(e.target.value, "administrative_area");
  };

  const onPostalCodeChange = e => {
    updateAddressField(e.target.value, "postal_code");
  };

  const save = async () => {
    setSaving(true);
    const {
      country,
      name_line,
      locality,
      premise,
      administrative_area,
      thoroughfare,
      postal_code
    } = address;
    try {
      const newAddress = await addressProxy.update(
        {
          name_line,
          country,
          locality,
          premise,
          administrative_area,
          thoroughfare,
          postal_code
        },
        orgId
      );
      setAddressData(newAddress.data);

      const updatedProfile = await client.updateOrganizationProfile(orgId, {
        display_name: name_line,
        vat_number: vatNumber,
        company_name: companyName,
        billing_contact: billingContact
      });
      setCompanyName(updatedProfile.company_name);
      setBillingContact(updatedProfile.data.billing_contact);
      setOriginalCompanyName(updatedProfile.company_name);
      setOriginalBillingContact(updatedProfile.data.billing_contact);
      setVatNumber(updatedProfile.data.vat_number);

      setShowButtons(false);
      setSaving(false);

      finalize && finalize();
    } catch (err) {
      setError(err.title);
      logger(err);
      setSaving(false);
    }
  };

  const resetForm = () => {
    setAddress(originalAddress);
    setCompanyName(originalCompanyName);
    setBillingContact(originalBillingContact);
    setVatNumber(vatNumberRedux);
    setShowButtons(false);
  };

  const submitButtonDisabled = !(
    address.name_line &&
    address.country &&
    address.thoroughfare &&
    address.locality &&
    address.administrative_area &&
    address.postal_code
  );

  const {
    name_line,
    country,
    thoroughfare,
    locality,
    premise,
    administrative_area,
    postal_code
  } = address;

  const defaultCountry = countryList()
    .getData()
    .find(i => i.value === country);
  return (
    <Layout>
      <PaymentWrapper>
        {error && <Error>{error}</Error>}
        <AddressWrapper
          id="billing-address-form"
          className="address-form"
          onChange={() => setShowButtons(true)}
        >
          <InputField
            id="name_line"
            name="name_line"
            label={intl.formatMessage({ id: "full_name" })}
            placeholder={intl.formatMessage({ id: "full_name" })}
            className="name_line"
            value={name_line || fullName}
            onChange={onNameChange}
          />
          <InputField
            id="billing_contact"
            name="billing_contact"
            label={intl.formatMessage({ id: "billing_contact_email" })}
            placeholder={intl.formatMessage({ id: "billing_contact_email" })}
            className="billing_contact"
            value={billingContact}
            onChange={onBillingContactChange}
            required={false}
          />
          <div>
            {showVat && (
              <InputField
                id="vat_number"
                name="vat_number"
                label={intl.formatMessage({ id: "vat_number" })}
                placeholder={intl.formatMessage({ id: "vat_number" })}
                value={vatNumber}
                onChange={onVatNumberChange}
                required={false}
              />
            )}
            <Dropdown
              className="field-country"
              options={countryList().getData()}
              name="country"
              onChange={onCountryChange}
              searchable={false}
              value={defaultCountry}
              defaultValue={{ label: defaultCountry, value: defaultCountry }}
              clearable={false}
              label={intl.formatMessage({ id: "country" })}
              placeholder={intl.formatMessage({ id: "country" })}
              fieldType={true}
              required={true}
            />
          </div>
          <InputField
            id="company_name"
            name="company_name"
            label={intl.formatMessage({ id: "company" })}
            placeholder={intl.formatMessage({ id: "company_name" })}
            value={companyName}
            onChange={onCompanyNameChange}
            required={false}
          />
          <InputField
            id="thoroughfare"
            name="thoroughfare"
            label={intl.formatMessage({ id: "billing_address" })}
            placeholder={intl.formatMessage({ id: "address" })}
            className="address"
            value={thoroughfare}
            onChange={onThoroughfareChange}
            required={true}
          />
          <InputField
            id="premise"
            name="premise"
            label={intl.formatMessage({ id: "billing_address2" })}
            placeholder={intl.formatMessage({ id: "address2" })}
            className="address"
            value={premise}
            onChange={onPremiseChange}
            required={false}
          />
          <InputField
            id="locality"
            name="locality"
            label={intl.formatMessage({ id: "city" })}
            placeholder={intl.formatMessage({ id: "city" })}
            className="locality"
            value={locality}
            onChange={onLocalityChange}
          />
          <InlineFields>
            <InputField
              id="administrative_area"
              name="administrative_area"
              label={intl.formatMessage({
                id: "state_province_region"
              })}
              placeholder={intl.formatMessage({
                id: "state_province_region.placeholder"
              })}
              className="administrative_area"
              value={administrative_area}
              onChange={onAdministrativeAreaChange}
            />
            <InputField
              id="postal_code"
              name="postal_code"
              label={intl.formatMessage({ id: "zip_postal_code" })}
              placeholder={intl.formatMessage({
                id: "zip_postal_code.placeholder"
              })}
              className="postal_code"
              value={postal_code}
              onChange={onPostalCodeChange}
            />
          </InlineFields>
        </AddressWrapper>
      </PaymentWrapper>
      {showButtons && (
        <div className="action-buttons-wrapper">
          {saving ? (
            <Loading />
          ) : (
            <div className="action-buttons">
              <Button
                id="update-address-submit"
                onClick={save}
                aria-label={intl.formatMessage({ id: "save" })}
                disabled={submitButtonDisabled}
              >
                {capitalize(intl.formatMessage({ id: "save" }))}
              </Button>
              <Button
                id="create-project-back"
                variant="secondary"
                onClick={resetForm}
                aria-label={intl.formatMessage({ id: "cancel" })}
              >
                {capitalize(intl.formatMessage({ id: "cancel" }))}
              </Button>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

AddressForm.propTypes = {
  name: PropTypes.string,
  organizationId: PropTypes.string,
  finalize: PropTypes.func,
  addressData: PropTypes.object
};

export default AddressForm;
