import styled from "styled-components";
import Refresh from "Icons/RefreshIcon";

export const Layout = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #98a0ab;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  margin-bottom: 8px;
  margin-left: auto;
`;

/** @TODO remove me in case this is not required in the future */
// const spin = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// export const RefreshIcon = styled(Refresh)`
//   ${({ isLoading }) =>
//     isLoading &&
//     css`
//       animation: 1s ${spin} linear infinite;
//     `};
// `;

export const RefreshIcon = Refresh;

export const Text = styled.span``;
