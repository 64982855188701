import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { entities } from "Libs/platform";

import useDecodedParams from "Hooks/useDecodedParams";

import withReducers from "Hocs/withReducers";

import {
  loadConnectedAccounts,
  deleteConnectedAccount
} from "Reducers/organization/settings/connectedAccounts";
import { PROVIDERS } from "Constants/providers";

import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ConnectedAccountLine from "./ConnectedAccountLine";

const OrganizationConnectedAccounts = () => {
  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const currentUser = useSelector(state => state.app?.get("me")).toJS();
  const { organizationId } = useDecodedParams();
  const [targetUsername, setTargetUsername] = useState();

  const connectedAccounts = useSelector(state =>
    state.connectedAccounts?.getIn(["data", targetUsername], new Map())
  );

  const loading = useSelector(state =>
    state.connectedAccounts?.get("loading", false)
  );

  useEffect(() => {
    let isCanceled = false;
    (async () => {
      const shouldLoadOtherUser = currentUser.username !== organizationId;
      if (shouldLoadOtherUser) {
        const otherUser = await entities.AuthUser.getUserByUsername(
          organizationId
        );
        dispatch(loadConnectedAccounts(otherUser));
        if (isCanceled) {
          return;
        }
        setTargetUsername(otherUser.username);
        return;
      }
      dispatch(loadConnectedAccounts(currentUser));
      if (isCanceled) {
        return;
      }
      setTargetUsername(organizationId);
    })();

    return () => (isCanceled = true);
  }, []);

  const disconnectAccount = provider => {
    dispatch(deleteConnectedAccount(targetUsername, provider));
  };

  const openConfirmationModal = provider => {
    setCurrentProvider(provider);
    toggleConfirmationModal(true);
  };

  const currentProviderLabel = PROVIDERS.find(p => p.name === currentProvider)
    ?.label;

  return (
    <React.Fragment>
      <ModalConfirmDelete
        title={formatMessage({ id: "remove_connected_account" })}
        deleteFunction={() => disconnectAccount(currentProvider)}
        isOpen={isConfirmationModalOpen}
        closeModal={() => toggleConfirmationModal(false)}
        itemId={`connected-account-${currentProvider}`}
        deleteText={formatMessage({ id: "yes" })}
        modalSize="small"
        id="connect-accounts-disconnect"
        body={
          <p>
            <FormattedMessage
              id="are_you_sure_disconnect_provider"
              values={{
                provider: currentProviderLabel,
                b: (...chunks) => <b>{chunks}</b> //eslint-disable-line
              }}
            />
          </p>
        }
      />
      {PROVIDERS.map(provider => (
        <ConnectedAccountLine
          key={provider.name}
          isLoading={loading === provider.name}
          provider={provider}
          disconnectAccount={openConfirmationModal}
          connectedAccount={connectedAccounts.get(provider.name)}
        />
      ))}
    </React.Fragment>
  );
};

OrganizationConnectedAccounts.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object
};

export default withReducers({
  connectedAccounts: () =>
    import("Reducers/organization/settings/connectedAccounts")
})(OrganizationConnectedAccounts);
