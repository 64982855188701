import React from "react";
import PropTypes from "prop-types";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentError from "./common/containers/EnvironmentError";
import withProjectRedirect from "../../../common/hocs/withProjectRedirect";

const Environment = ({ children }) => {
  const { projectId, organizationId } = useDecodedParams();

  return (
    <EnvironmentError projectId={projectId} organizationId={organizationId}>
      {children}
    </EnvironmentError>
  );
};

Environment.propTypes = {
  children: PropTypes.node.isRequired
};

export default withProjectRedirect(Environment);
