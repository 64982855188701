import React from "react";
import { useParams } from "react-router-dom";

import ProjectError from "../containers/ProjectError";
import ProjectOverview from "../containers/ProjectOverview";

import withProjectRedirect from "../../../common/hocs/withProjectRedirect";

const Page = () => {
  const { projectId, organizationId } = useParams();

  return (
    <ProjectError projectId={projectId} organizationId={organizationId}>
      <ProjectOverview projectId={projectId} organizationId={organizationId} />
    </ProjectError>
  );
};

export default withProjectRedirect(Page);
