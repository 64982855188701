import React from "react";
import PropTypes from "prop-types";

import ProjectDomainListField from "./containers/ProjectDomainListField";
import ContentLayout from "Components/ContentLayout";

import withReducers from "Hocs/withReducers";

const ProjectSettingsVariable = ({ organizationId, projectId }) => (
  <ContentLayout className="settings">
    <ProjectDomainListField
      projectId={projectId}
      organizationId={organizationId}
    />
  </ContentLayout>
);

ProjectSettingsVariable.propTypes = {
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired
};

export default withReducers({
  project: () => import("Reducers/project"),
  projectDomain: () => import("Reducers/project/settings/domain"),
  subscription: () => import("Reducers/subscription")
})(ProjectSettingsVariable);
