import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import useInput from "Hooks/useInput";

import { getOptions, saveConfig } from "Reducers/project/setup";
import { organizationsMemberOrOwnerOfSelector } from "Reducers/organization";

import PageMeta from "Components/PageMeta";
import Heading2 from "Components/styleguide/Heading2";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import Button from "Components/Button";
import InputField from "Components/fields/InputField";
import RegionPicker from "Containers/RegionPicker";

import Sumup from "../../Sumup";

import * as S from "./styles";
import Dropdown from "Components/Dropdown";

const InfoStep = ({ goToStep }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const [modalIsOpen, setOpenModal] = useState(false);
  const [template, setTemplate] = useState();

  const user = useSelector(({ app }) => app?.get("me", Map())?.toJS());
  const config = useSelector(({ setup }) => setup?.get("config", Map()));
  const templates = useSelector(({ setup }) =>
    setup?.getIn(["catalog", "data"])
  );
  const organizations = useSelector(state =>
    organizationsMemberOrOwnerOfSelector(state)?.toArray()
  );

  const { inputs, handleInputChange, setInputs } = useInput({
    title: config.get("title", ""),
    // Field is hidden, waiting GIT 12
    // defaultBranch: config.get('defaultBranch', 'main'),
    region: config.get("region")?.toJS(),
    organization: {}
  });

  useEffect(() => {
    dispatch(getOptions());
    const focusElement = document.getElementById("projectTitle");
    focusElement && focusElement.focus();
    // If the user have only one org, we hide the dropdown and we choose this one by default
    if (process.env.ENABLE_ORGANIZATION && organizations?.length === 1) {
      const orgData = organizations[0];
      setInputs({
        ...inputs,
        organization: { label: orgData.label, value: orgData.id }
      });
    }
  }, []);

  useEffect(
    () => {
      if (!params.has("template") && !config.has("template")) return;
      const tplURL = params.get("template") || config.get("template");
      const match = tplURL.match(
        /github.*\.com\/(.[^/]*)\/.*\/(.*)\/\.platform.template.yaml/
      );
      if (!match) return;

      const tpl = templates?.toJS().find(elt => elt.template.includes(match[2]))
        ?.info;

      setTemplate({
        image: tpl?.image,
        name: tpl?.name || `${match[1]}/${match[2]}`
      });
    },
    [config?.has("template"), params.has("template")]
  );

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleBuild = () => {
    setOpenModal(false);
    goToStep("building");
  };

  const onRegionChange = region => {
    setInputs(inputs => ({ ...inputs, region }));
  };

  const canSubmit = () => {
    const { title, region } = inputs;
    return title !== "" && region;
  };

  const handleGoBack = e => {
    e?.preventDefault();
    goToStep(config.get("template") ? "template" : "type");
  };

  const handleSubmit = e => {
    e?.preventDefault();
    dispatch(saveConfig({ ...inputs }));

    // TODO: use the getTrial function with organizations when we modify the setup
    if (!user.trial) {
      setOpenModal(true);
    } else {
      goToStep("building");
    }
  };

  const handleOrganizationChange = organization => {
    setInputs({
      ...inputs,
      organization: { label: organization.label, value: organization.value }
    });
  };

  return (
    <>
      {modalIsOpen && (
        <ModalConfirmDelete
          modalSize="medium"
          id="modal-plan-pricing"
          className="modal-plan-pricing"
          isOpen={true}
          deleteText={intl.formatMessage({ id: "continue" })}
          deleteFunction={handleBuild}
          cancelFunction={handleCancel}
          closeModal={handleCancel}
          title={intl.formatMessage({ id: "plan_and_pricing" })}
          body={<Sumup />}
        />
      )}

      <S.Wrapper>
        <PageMeta title={intl.formatMessage({ id: `setup.info.title` })} />

        <S.Header>
          {template?.image && (
            <img alt={`${template.name} logo`} src={template.image} />
          )}
          <Heading2>{intl.formatMessage({ id: `setup.info.title` })}</Heading2>
          {template && <p>{template.name}</p>}
        </S.Header>

        <S.Form>
          <InputField
            id="title"
            className="field"
            label={intl.formatMessage({ id: "project_name" })}
            onChange={handleInputChange}
            value={inputs.title}
            placeholder={intl.formatMessage({ id: "project_name" })}
          />
          <RegionPicker onChange={onRegionChange} region={inputs.region} />
          {/* Field is hidden, waiting GIT 12 */}
          {false && (
            <InputField
              id="defaultBranch"
              className="field"
              required={false}
              label={intl.formatMessage({ id: "production_environment" })}
              onChange={handleInputChange}
              value={inputs.defaultBranch}
            />
          )}
          {process.env.ENABLE_ORGANIZATION &&
            organizations.length > 1 && (
              <Dropdown
                label="Organization"
                options={organizations.map(o => ({
                  label: o.label,
                  value: o.id
                }))}
                onChange={handleOrganizationChange}
                clearable={false}
                fieldType={true}
                required={true}
                defaultText="Select organization"
                value={inputs.organization}
              />
            )}
        </S.Form>

        <S.ActionButtons>
          {!params.has("template") && (
            <Button className="secondary" type="button" onClick={handleGoBack}>
              {intl.formatMessage({ id: "back" })}
            </Button>
          )}

          <Button
            type="submit"
            className="primary"
            onClick={handleSubmit}
            disabled={!canSubmit()}
          >
            {intl.formatMessage({ id: "create_project" })}
          </Button>
        </S.ActionButtons>
      </S.Wrapper>
    </>
  );
};

InfoStep.propTypes = {
  goToStep: PropTypes.func
};

export default InfoStep;
