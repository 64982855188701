import styled from "styled-components";
import InfoDialog from "Components/InfoDialog";

export const FieldDescription = styled.div`
  line-height: 20px;
`;

export const EnvironmentHiddenInfoDialog = styled(InfoDialog)`
  margin-left: 0;
  width: 19px;
  top: 3px;
`;
