import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";

import formatPercentage from "../../../utils/formatPercentage";
import { margin } from "../settings";

import * as S from "./Tooltip.styles";

const calculatePosition = (pointer, tooltip, width, offsetHeight) => {
  const tooltipXMargin = 20;
  const closestDataPointX = pointer[0];
  const pointerY = pointer[1];

  if (!tooltip) {
    return { transform: `translate(-100%, -100%)`, display: "none" };
  }

  const {
    width: tooltipWidth,
    height,
    x: tooltipXPosition
  } = tooltip.getBoundingClientRect();

  const overflowsChartRight =
    width - (closestDataPointX + tooltipWidth + tooltipXMargin) <=
    tooltipXMargin;
  const overflowsWindowRight =
    tooltipXPosition + tooltipWidth >= document.offsetWidth - tooltipXMargin;

  let tooltipX =
    overflowsChartRight || overflowsWindowRight
      ? closestDataPointX - tooltipWidth - tooltipXMargin
      : closestDataPointX + tooltipXMargin;

  let tooltipY =
    height + pointerY > offsetHeight - margin.top * 3
      ? offsetHeight - height - margin.top * 3.4
      : pointerY;

  return { transform: `translate(${tooltipX}px, ${tooltipY}px)` };
};

const Tooltip = ({
  data,
  hosts,
  activeLine,
  tooltipFormatter,
  pointer,
  height,
  width,
  isVisible
}) => {
  const tooltip = useRef();
  const rows = useMemo(
    () => {
      if (!hosts?.length || !data) {
        return [];
      }

      return hosts
        .sort((a, b) => a.localeCompare(b))
        ?.filter(host => data[`${host}@value`])
        ?.map(host => ({
          id: host,
          values: {
            percentage: formatPercentage(data[`${host}@percentage`]),
            value: tooltipFormatter(data[`${host}@value`]),
            host: host === "average" ? "Average" : `Host ${host.slice(0, 13)}`
          }
        }));
    },
    [data, hosts]
  );

  const transform = useMemo(
    () => calculatePosition(pointer, tooltip?.current, width, height),
    [pointer, tooltip?.current, width, height]
  );

  return (
    <S.Layout
      isVisible={isVisible && rows.length}
      style={transform}
      ref={tooltip}
    >
      <S.Table>
        <tbody>
          {rows.map(({ id, values }) => (
            <S.TableRow key={id} isActive={id === activeLine}>
              <td>{values.percentage}</td>
              <td>{values.value}</td>
              <td>{values.host}</td>
            </S.TableRow>
          ))}
        </tbody>
      </S.Table>
    </S.Layout>
  );
};

Tooltip.propTypes = {
  data: PropTypes.object,
  hosts: PropTypes.array,
  activeLine: PropTypes.string,
  tooltipFormatter: PropTypes.func,
  pointer: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  isVisible: PropTypes.bool
};

export default React.memo(Tooltip);
