import { Map, fromJS } from "immutable";

import client from "Libs/platform";
import logger from "Libs/logger";
import StreamWorker from "Libs/stream.worker";

const LOAD_LOG_START = "app/log/load_start";
const LOAD_LOG_SKIP = "app/log/load_skip";
const LOAD_LOG_SUCCESS = "app/log/load_success";
const LOAD_LOG_FAILURE = "app/log/load_failure";
const LOAD_LOG_FRAGMENTS_START = "app/log/load_fragment_start";
const LOAD_LOG_FRAGMENTS_SUCCESS = "app/log/load_fragment_success";

export const loadLogStream = (activity, retryNumber = 0) => async dispatch => {
  dispatch({ type: LOAD_LOG_FRAGMENTS_START, payload: activity.id });

  const worker = new StreamWorker();

  const accessToken = await client.getAccessToken();

  worker.postMessage({
    url: `${activity.getLink("log")}?max_items=0&max_delay=1000`,
    accessToken,
    maxBatchNumber: 1000
  });

  worker.onmessage = async function(e) {
    const data = e.data;
    if (data.error) {
      if (
        retryNumber < 3 &&
        (data.error === 401 || data.error === "Failed to fetch")
      ) {
        // Re-authenticate
        await client.reAuthenticate();

        // Retry
        return dispatch(loadLogStream(activity, ++retryNumber));
      }

      const errorMessage = `${data.error}: on activity ${activity.id}`;
      logger(errorMessage, {
        action: LOAD_LOG_FAILURE
      });
      return dispatch({
        type: LOAD_LOG_FAILURE,
        payload: errorMessage,
        meta: { id: activity.id }
      });
    }

    dispatch({
      type: LOAD_LOG_FRAGMENTS_SUCCESS,
      payload: e.data.fragmentSet,
      meta: { id: activity.id }
    });

    if (data.done) {
      dispatch({ type: LOAD_LOG_SUCCESS, payload: activity.id });
    }
  };
};

export const loadLogFromActivity = activity => async (dispatch, getState) => {
  const streamInProgress = getState().log.getIn(
    ["data", activity.id, "streamInProgress"],
    false
  );
  const streamHasFinished = getState().log.getIn(
    ["data", activity.id, "seal"],
    false
  );

  if (streamHasFinished === false && streamInProgress === false) {
    dispatch({ type: LOAD_LOG_START, payload: activity.id });
    dispatch(loadLogStream(activity));
  } else {
    dispatch({ type: LOAD_LOG_SKIP, payload: activity.id });
  }
};

export default function activityReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_LOG_START:
      return state.setIn(["data", action.payload, "streamInProgress"], true);
    case LOAD_LOG_FRAGMENTS_SUCCESS:
      return state.setIn(["data", action.meta.id, "log"], action.payload);
    case LOAD_LOG_SUCCESS:
      return state
        .setIn(["data", action.payload, "streamInProgress"], false)
        .setIn(["data", action.payload, "seal"], true);
    case LOAD_LOG_FAILURE:
      return state
        .setIn(
          ["data", action.meta.id, "log"],
          state.getIn(
            ["data", action.meta.id, "log"],
            new fromJS([
              {
                data: {
                  message: "Error: Unable to load the log for this activity."
                }
              }
            ])
          )
        )
        .setIn(["data", action.meta.id, "streamInProgress"], false)
        .setIn(["data", action.meta.id, "errors"], action.payload);
    default:
      return state;
  }
}
