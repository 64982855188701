import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Item from "./Item";
import * as S from "./styles";

const NavBar = ({ current, integrations, onSelect }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      {" "}
      <S.Heading>
        {intl.formatMessage({
          id: "integrations.title",
          defaultMessage: "integrations"
        })}
      </S.Heading>
      {integrations.map(elt => (
        <Item
          key={elt.id}
          integration={elt}
          onSelect={() => onSelect(elt.id)}
          isActive={current === elt.id}
        />
      ))}
    </S.Wrapper>
  );
};

NavBar.propTypes = {
  current: PropTypes.string,
  integrations: PropTypes.array,
  onSelect: PropTypes.func
};

export default NavBar;
