import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { OrderedSet } from "immutable";
import { Link, useLocation, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import useMediaQuery from "Hooks/useMediaQuery";

import ButtonAdd from "Components/ButtonAdd";
import Loading from "Components/Loading";

import LoadMore from "../../../../common/components/LoadMore";
import Pane from "../../../../common/components/Pane";
import Filters from "../Filters/";
import NoTickets from "./NoTickets";
import TicketRow from "./Row";
import HeaderCell from "./HeaderCell";

import {
  TableHeading,
  TableFilters,
  TableLayout,
  Table,
  TableHead
} from "./Table.styles";

import { SORT_BY, STATUSES } from "../../../../common/config";

const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH":
      return {
        ...state,
        search: action.value
      };
    case "SORT":
      return {
        ...state,
        direction:
          state.sortBy === action.value && state.direction === "DESC"
            ? "ASC"
            : "DESC",
        sortBy: action.value
      };
    case "SHOW":
      return {
        ...state,
        show: {
          ...state.show,
          ...action.value
        }
      };
    default:
      return state;
  }
};

const get = k => obj => obj[k];
const name = get("name");
const showOnMobile = get("showOnMobile");

const headers = [
  {
    name: "ticket_id",
    showOnMobile: true
  },
  {
    name: "priority",
    showOnMobile: false
  },
  {
    name: "subject",
    showOnMobile: true
  },
  {
    name: "subscription",
    showOnMobile: false
  },
  {
    name: "updated",
    showOnMobile: false
  },
  {
    name: "status",
    showOnMobile: true
  }
];

const TicketTable = ({
  tickets,
  isLoading,
  hasMore,
  loadMore,
  className,
  error,
  onFilterChange
}) => {
  const { organizationId } = useParams();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const widerThan768 = useMediaQuery("(min-width: 768px)");

  const addButtonTextId = widerThan768 ? "tickets.new" : "tickets.new.mobile";
  const headerNames = widerThan768
    ? headers.map(name)
    : headers.filter(showOnMobile).map(name);

  const initialState = {
    search: "",
    show: Object.keys(STATUSES).reduce(
      (show, status) => ({ ...show, [status]: false }),
      {}
    ),
    sortBy: location.state?.sortBy || "status",
    direction: location.state?.sortBy ? "DESC" : "ASC"
  };
  const [filters, dispatch] = useReducer(reducer, initialState);

  const sortFunction = filters.sortBy
    ? SORT_BY[filters.sortBy].sort
    : () => undefined;

  const includesSearch = element =>
    filters.search !== ""
      ? element.subject.toLowerCase().includes(filters.search.toLowerCase())
      : true;

  useEffect(
    () => {
      onFilterChange(filters);
    },
    [filters]
  );

  const visibilityOptions = Object.values(STATUSES)
    .filter(({ inDropdown }) => inDropdown)
    .map(element => ({
      ...element,
      isActive: filters.show[element.id]
    }));

  const sortByOptions = Object.values(SORT_BY)
    .filter(({ inDropdown }) => inDropdown)
    .map(element => ({
      ...element,
      isActive: element.id === filters.sortBy
    }));

  return (
    <Pane hasShadow isOpaque className={className}>
      <TableFilters>
        <TableHeading>
          <FormattedMessage id="tickets.list.header" />
        </TableHeading>

        {!(isLoading && !tickets) && (
          <Filters
            search={filters.search}
            onFilterChange={dispatch}
            visibilityOptions={visibilityOptions}
            sortByOptions={sortByOptions}
          />
        )}
        <ButtonAdd
          as={Link}
          to={`/-/users/${organizationId}/tickets/open`}
          customText={formatMessage({ id: addButtonTextId })}
        />
      </TableFilters>
      {error && <div>{error.message}</div>}
      {isLoading && !tickets ? (
        <Loading iconOnly />
      ) : tickets?.size ? (
        <>
          <TableLayout hasMore={hasMore}>
            <Table>
              <TableHead>
                <tr>
                  {headerNames.map(header => (
                    <HeaderCell
                      key={header}
                      id={header}
                      direction={filters.direction}
                      onClick={dispatch}
                      isActive={filters.sortBy === header}
                    />
                  ))}
                </tr>
              </TableHead>
              <tbody>
                {tickets
                  .filter(element => includesSearch(element))
                  .sort(
                    (a, b) =>
                      filters.direction === "ASC"
                        ? sortFunction(a, b)
                        : sortFunction(b, a)
                  )
                  .map(ticket => (
                    <TicketRow key={ticket.ticket_id} {...ticket} />
                  ))}
              </tbody>
            </Table>
          </TableLayout>

          <LoadMore
            hasMore={hasMore}
            isLoading={isLoading && !!tickets?.size}
            collection={tickets}
            onClick={() => loadMore(filters)}
          />
        </>
      ) : (
        <NoTickets />
      )}
    </Pane>
  );
};

TicketTable.propTypes = {
  tickets: PropTypes.instanceOf(OrderedSet),
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  className: PropTypes.string,
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  error: PropTypes.object,
  onFilterChange: PropTypes.func
};

export default TicketTable;
