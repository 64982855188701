import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import withReducers from "Hocs/withReducers";
import {
  addVariable,
  cancelUpdateVariable,
  deleteVariable,
  editLine,
  getVariables,
  updateVariable
} from "Reducers/project/settings/variable";

import { DOCS_CONFIG_APP_VARIABLES_URL } from "Constants/documentationUrls";

import ButtonAdd from "Components/ButtonAdd";
import EmptyText from "Components/EmptyText";
import InfoDialog from "Components/InfoDialog";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";
import SettingLine from "Components/SettingLine";
import VariableForm from "../../../../../common/components/VariableForm";

import * as S from "./styles";

const getLabels = variable => {
  let labels = [];
  variable.is_json && labels.push("Json");
  variable.is_sensitive && labels.push("Sensitive");
  variable.visible_build && labels.push("Build");
  variable.visible_runtime && labels.push("Runtime");

  return labels.join(", ");
};

const sortListById = list => {
  return list.sort((a, b) => {
    return a.id !== b.id ? (a.id < b.id ? -1 : 1) : 0;
  });
};

const ProjectVariableListField = ({ organizationId, projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [listVariables, setListVariables] = useState(new Map());
  const [isModalLeaveOpen, setIsModalLeaveOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVar, setCurrentVar] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [isChangeIndex, setIsChangeIndex] = useState();
  const [currentIndex, setCurrentIndex] = useState();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const projectVariable = useSelector(
    state => state.projectVariable || new Map()
  );
  const editedLine = projectVariable.get("editedLine");
  const isNewVar = projectVariable.get("isNew");
  const status = projectVariable.get("status");
  const variables = projectVariable
    .getIn(["data", organizationId, projectId], new Map())
    ?.valueSeq()
    ?.toJS();

  useEffect(
    () => {
      dispatch(getVariables({ organizationId, projectId }));
    },
    [projectId]
  );

  useEffect(
    () => {
      if (!isNewVar) setListVariables(sortListById(variables));
    },
    [projectVariable]
  );

  useEffect(
    () => {
      if (status === "fulfilled") {
        setIsChanged(false);
        setIsChangeIndex(false);
        setCurrentIndex(null);
        dispatch(cancelUpdateVariable());
      }
    },
    [status]
  );

  const addNewVariable = () => {
    if (!isNewVar) {
      listVariables.unshift({});
      setListVariables(listVariables);
    }
    dispatch(editLine({ index: 0, isNew: true }));
  };

  const save = data => {
    setIsChanged(false);
    setIsChangeIndex(false);
    setCurrentIndex(null);

    if (isNewVar) {
      dispatch(addVariable({ organizationId, project, data }));
    } else {
      const variable = listVariables.find(elt => elt.id === data.id);
      dispatch(
        updateVariable({
          organizationId,
          projectId,
          variable,
          data
        })
      );
    }
  };

  const handleCancel = () => {
    if (isChanged && !isModalLeaveOpen) {
      setIsModalLeaveOpen(true);
      setIsChanged(true);
    } else {
      if (isNewVar) {
        const list = listVariables.splice(0, 1);
        setListVariables(list);
      }
      setIsChanged(false);
      setIsChangeIndex(false);
      setCurrentIndex(null);

      dispatch(cancelUpdateVariable());
    }
  };

  const expand = index => {
    if (editedLine === index) {
      handleCancel();
    } else {
      if (isNewVar) {
        handleCancel();
        // if  new var form was open, the list had an empty object
        index--;
      }
      if (isChanged && !isModalLeaveOpen) {
        setIsModalLeaveOpen(true);
        setIsChanged(true);
        setIsChangeIndex(true);
      } else {
        dispatch(editLine({ index, isNew: false }));
        setIsChanged(false);
        setIsChangeIndex(false);
      }
      setCurrentIndex(index);
    }
  };

  const openModal = variable => {
    setCurrentVar(variable);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalLeaveOpen(false);
    setIsModalOpen(false);
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project?.title} project-level variables`}
        aria-live="polite"
      />
      <PageMeta title={`Variables | ${project?.title}`} />
      <ButtonAdd
        id="add-new-variable"
        css={{ float: "right" }}
        onClick={addNewVariable}
      />
      <S.Heading2 id="settings-heading">
        {intl.formatMessage({ id: "variables", defaultMessage: "variables" })}
      </S.Heading2>

      <PageDescription>
        <InfoDialog
          title="Learn more"
          linkText="Learn more"
          to={DOCS_CONFIG_APP_VARIABLES_URL}
          text="Set variables at both project or environment levels."
        />
        <p>
          {intl.formatMessage({
            id: "settings.variables.description",
            defaultMessage:
              "Project variables are available for all project environments and during the build process."
          })}
        </p>
        {intl.formatMessage(
          {
            id: "settings.variables.details",
            defaultMessage:
              "• Variables beginning with <code>env:</code> will be exposed as Unix environment variables{br}• Variables beginning with <code>php:</code> will be interpreted as php.ini directives.{br}• All other variables will be part of the environment <code>$PLATFORM_VARIABLES</code> variable"
          },
          {
            br: <br />,
            code: txt => <code>{txt}</code> // eslint-disable-line react/display-name
          }
        )}
      </PageDescription>

      <section aria-labelledby="settings-heading">
        {projectVariable.get("loading", true) ? (
          <Loading />
        ) : (
          <>
            {listVariables.length === 0 ? (
              <EmptyText>
                {intl.formatMessage({
                  id: "settings.variables.empty",
                  defaultMessage: "no project variables."
                })}
              </EmptyText>
            ) : (
              <>
                {listVariables.map((variable, index) => {
                  return (
                    <SettingLine
                      key={`var-${index}-read`}
                      id={`project-variable-list-${variable.name}`}
                      info={
                        <S.VariableInfo>
                          <S.VariableInfoMain>
                            <S.VariableName className="variable-name">
                              {variable.name}
                            </S.VariableName>{" "}
                            ={" "}
                            <S.VariableValue className="variable-value">
                              {variable.is_sensitive ? "*****" : variable.value}
                            </S.VariableValue>
                          </S.VariableInfoMain>
                          <S.VariableInfoLabels>
                            {getLabels(variable)}
                          </S.VariableInfoLabels>
                        </S.VariableInfo>
                      }
                      isNew={!variable.id}
                      addNewTitle={intl.formatMessage({
                        id: "settings.variables.add.title",
                        defaultMessage: "Add variable"
                      })}
                      isOpen={editedLine === index}
                      openText={intl.formatMessage({ id: "edit" })}
                      onClick={() => expand(index)}
                    >
                      {editedLine === index && (
                        <VariableForm
                          key={`var-${variable ? variable.id : "new"}-form`}
                          onSave={save}
                          onCancel={handleCancel}
                          onDelete={() => openModal(variable)}
                          errors={projectVariable.get("errors", {})}
                          isLoading={status === "pending"}
                          variable={variable}
                          isChanged={isChanged}
                          isChangedUpdate={() => setIsChanged(true)}
                        />
                      )}
                    </SettingLine>
                  );
                })}
              </>
            )}
          </>
        )}

        <ModalConfirmDelete
          isOpen={isModalOpen}
          closeModal={closeModal}
          deleteFunction={() =>
            dispatch(
              deleteVariable({
                organizationId,
                projectId,
                variable: currentVar
              })
            )
          }
          itemType="variable"
          itemName={currentVar?.name}
          itemId={currentVar?.name}
        />

        <ModalConfirmLeaveForm
          isOpen={isModalLeaveOpen}
          closeModal={closeModal}
          continueFunction={
            isChangeIndex
              ? () => {
                  expand(currentIndex);
                }
              : () => {
                  handleCancel();
                }
          }
          cancelFunction={closeModal}
        />
      </section>
    </S.Wrapper>
  );
};

ProjectVariableListField.propTypes = {
  organizationId: PropTypes.string,
  projectId: PropTypes.string
};

export default withReducers({
  project: () => import("Reducers/project"),
  projectVariable: () => import("Reducers/project/settings/variable")
})(ProjectVariableListField);
