import React, { useEffect } from "react";
import { connect } from "react-redux";

import { loadProject as loadProjectReducer } from "Reducers/project";
import { deleteSubscription } from "Reducers/subscription";
import { interpolateURL } from "Libs/utils";

const mapStateToProps = (state, props) => {
  const project = state.project.getIn(
    ["data", props.organizationId, props.projectId],
    {}
  );

  return {
    project
  };
};

const mapDispatchToProps = (dispatch, props) => {
  let subscriptionActions = {};
  if (props.subscriptionId || props.subscription) {
    subscriptionActions.deleteSubscription = subscription => {
      if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
        window.location.href = interpolateURL(
          process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
          { projectId: props.projectId }
        );
      } else {
        dispatch(deleteSubscription({ subscription }));
      }
    };
  }

  return {
    loadProject: () => dispatch(loadProjectReducer(props.projectId)),
    ...subscriptionActions
  };
};

function withProject(WrappedComponent) {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(({ projectId, loadProject, ...props }) => {
    useEffect(
      () => {
        loadProject(projectId);
      },
      [projectId]
    );

    return <WrappedComponent {...props} />;
  });
}

export default withProject;
