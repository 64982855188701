import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import moment from "moment";

import { usePalette } from "Libs/theme";

import { Layout, colorDefinitions } from "./Chart.styles";

import useOnResize from "../../hooks/useOnResize";
import { debounce } from "../../utils/debounce";

import applyBrush from "./brush";
import Lines from "./Lines";
import Tooltip from "./Tooltip";
import Ruler from "./Ruler";
import TimeTooltip from "./TimeTooltip";

import { margin, timeFormats } from "./settings";

const isThresholdActive = (points, max, threshold) =>
  points.map(point => (point * 100) / max).some(point => point >= threshold);

const renderYAxis = (data, ref, theme, height, yFormatter) => {
  const max = d3.max(data, point => point.max);
  const THRESHOLD_50 = max / 2;
  const THRESHOLD_75 = (max / 4) * 3;
  const THRESHOLD_87 = (max / 8) * 7;
  const tickValues = [max, THRESHOLD_87, THRESHOLD_75, THRESHOLD_50];
  const svg = d3.select(ref.current).select("svg");

  const yScale = d3
    .scaleLinear()
    .range([height - margin.bottom, margin.top])
    .domain([0, d3.max(data, point => point.max)]);

  svg
    .select(".y-axis__reference-lines")
    .call(
      d3
        .axisRight()
        .scale(yScale)
        .tickValues([...tickValues, 0])
        .tickSize(ref.current.offsetWidth - margin.right - margin.left)
    )
    .call(element => {
      element
        .selectAll(".tick line")
        .attr("stroke", v => {
          if (v === max || v === THRESHOLD_87) {
            return theme.threshold__max;
          } else if (v === THRESHOLD_75) {
            return theme.threshold__warning;
          } else {
            return theme.threshold__none;
          }
        })
        .attr("stroke-dasharray", function(value) {
          return value === THRESHOLD_87 || value === THRESHOLD_75 ? "4 10" : "";
        });
    });

  svg
    .select(".y-axis__ticks")
    .call(
      d3
        .axisRight()
        .scale(yScale)
        .tickValues(tickValues)
        .tickFormat(yFormatter)
        .tickPadding(0)
    )
    .call(axis => axis.selectAll(".tick text").attr("x", 0));

  return yScale;
};

const getXTimeFormat = (from, to) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);
  const diff = momentTo.diff(momentFrom, "seconds");

  if (diff < 5 * 60) {
    return timeFormats.long;
  }

  return timeFormats.short;
};

const renderXAxis = (data, ref, timeframe, width) => {
  const x = d3
    .scaleTime()
    .range([margin.left, width - margin.right])
    .domain([data[0].timestamp, data[data.length - 1].timestamp]);

  let tickInterval;
  let format = timeframe.id
    ? timeFormats.short
    : getXTimeFormat(timeframe.from, timeframe.to);

  if (timeframe.id === "short") {
    tickInterval = d3.timeMinute.every(width > 480 ? 1 : 2);
  } else if (timeframe.id === "medium") {
    tickInterval = d3.timeMinute.every(width > 480 ? 5 : 10);
  } else if (timeframe.id === "long") {
    tickInterval = d3.timeHour.every(width > 480 ? 2 : 3);
  } else {
    if (width < 480) {
      tickInterval = 5;
    } else if (width < 560) {
      tickInterval = 6;
    } else {
      tickInterval = 8;
    }
  }

  const ticks = d3
    .axisTop()
    .scale(x)
    .tickSize(0)
    .ticks(tickInterval)
    .tickFormat(format)
    .tickPadding(0);

  d3.select(ref.current)
    .select("svg .x-axis__container .x-axis__ticks")
    .call(ticks);

  return x;
};

const timestampBisector = d3.bisector(point => point.timestamp).left;
const getCurrentMousePosition = (pointerX, pointerY, data, x) => {
  let dataIndex = timestampBisector(data, x.invert(pointerX)) || 0;
  if (dataIndex === data.length) {
    dataIndex--;
  }
  const currentX = x(data[dataIndex].timestamp);
  const previousX =
    dataIndex === 0 ? currentX : x(data[dataIndex - 1].timestamp);

  const closestDataPointX =
    pointerX - currentX > previousX - pointerX ? currentX : previousX;
  const closestDataPoint =
    data[timestampBisector(data, x.invert(closestDataPointX))];

  return { closestDataPoint, closestDataPointX, pointerX, pointerY };
};

const Chart = ({
  data,
  hosts,
  yFormatter = v => v,
  tooltipFormatter = v => v,
  timeframe = {},
  height = 226,
  onBrush
}) => {
  const theme = usePalette(colorDefinitions);

  const ref = useRef(null);

  const [activeLine, setActiveLine] = useState();
  const [width, setWidth] = useState(() => ref.current?.offsetWidth);
  const [closestDataPoint, setClosestDataPoint] = useState();
  const [pointer, setPointer] = useState();
  const [isBrushing, setIsBrushing] = useState();

  const debouncedSetPointer = useMemo(() => debounce(setPointer), []);
  const debouncedSsetClosestDataPoint = useMemo(
    () => debounce(setClosestDataPoint),
    []
  );
  const setWidthOnResize = useCallback(
    () => setWidth(ref.current.offsetWidth),
    [ref.current]
  );
  useOnResize(setWidthOnResize);

  useEffect(
    () => {
      setWidth(ref.current?.offsetWidth || width);
    },
    [ref.current]
  );

  const [xAxis, yAxis] = useMemo(
    () => {
      if (!data || !ref.current) {
        return [];
      }

      const xAxis = d3
        .scaleTime()
        .range([margin.left, width - margin.right])
        .domain([data[0].timestamp, data[data.length - 1].timestamp]);

      const yAxis = d3
        .scaleLinear()
        .range([height - margin.bottom, margin.top])
        .domain([0, d3.max(data, point => point.max)]);

      return [xAxis, yAxis];
    },
    [ref.current, data, width]
  );

  useEffect(
    () => {
      if (!xAxis) {
        return;
      }
      const [, x1] = xAxis.range();
      const svg = d3.select(ref.current).select("svg");
      applyBrush(
        svg.select(".y-axis__reference-lines"),
        xAxis,
        onBrush,
        () => {
          setIsBrushing(true);
          debouncedSsetClosestDataPoint(undefined);
        },
        event => {
          if (!event.selection) {
            return;
          }
          const [, xPosition] = event.selection;
          const { closestDataPoint } = getCurrentMousePosition(
            xPosition + margin.left,
            0,
            data,
            xAxis
          );

          debouncedSsetClosestDataPoint(closestDataPoint);
        },
        [[margin.left, margin.top], [x1 - margin.right, height - margin.bottom]]
      );
    },
    [data, xAxis, ref.current, onBrush]
  );

  useEffect(
    () => {
      if (!data?.length || !width) {
        return;
      }
      renderYAxis(data, ref, theme, height, yFormatter);
      renderXAxis(data, ref, timeframe, width);
    },
    [data, height, width, timeframe, yFormatter]
  );

  const { isWarningActive, isCriticalActive } = useMemo(
    () => {
      const max = d3.max(data, point => point.max);
      const dataPoints = data
        ?.flatMap(point =>
          Object.entries(point).map(
            ([key, value]) => (key.indexOf("@value") > -1 ? value : undefined)
          )
        )
        .filter(point => typeof point === "number");

      const result = {
        isWarningActive: isThresholdActive(dataPoints, max, 75),
        isCriticalActive: isThresholdActive(dataPoints, max, (100 / 8) * 7)
      };

      return result;
    },
    [data]
  );

  const onMouseLeave = useCallback(
    event => {
      if (!ref.current) {
        return;
      }
      const [x, y] = d3.pointer(event);
      const { left, top, right, bottom } = d3
        .select(ref.current)
        .select(".y-axis__reference-lines")
        .node()
        .getBoundingClientRect();

      if (x <= left || y <= top || x >= right || y >= bottom) {
        debouncedSsetClosestDataPoint(null);
      }
    },
    [ref.current]
  );

  const onMouseEnter = useCallback(
    event => {
      if (!xAxis || !data) {
        return;
      }
      const [xPosition, yPostion] = d3.pointer(event);

      const { closestDataPoint } = getCurrentMousePosition(
        xPosition,
        yPostion,
        data,
        xAxis
      );
      debouncedSsetClosestDataPoint(closestDataPoint);
      debouncedSetPointer([xPosition, yPostion]);
    },
    [data, xAxis]
  );

  const onMouseMove = useCallback(
    event => {
      if (!xAxis || !data) {
        return;
      }
      const [xPosition, yPostion] = d3.pointer(event);

      const {
        closestDataPoint: newClosestdDataPoint
      } = getCurrentMousePosition(xPosition, yPostion, data, xAxis);
      if (
        closestDataPoint?.timestamp?.toString() !==
        newClosestdDataPoint.timestamp.toString()
      ) {
        debouncedSsetClosestDataPoint(newClosestdDataPoint);
        debouncedSetPointer([xPosition, yPostion]);
      }
    },
    [data, xAxis, debouncedSetPointer, debouncedSsetClosestDataPoint]
  );

  const closestDataPointX = useMemo(
    () => {
      if (!xAxis || !closestDataPoint) {
        return undefined;
      }
      return xAxis(closestDataPoint.timestamp);
    },
    [closestDataPoint, xAxis]
  );

  const closestPoint = useMemo(
    () => {
      if (!closestDataPointX || !pointer) return [];
      return [closestDataPointX, pointer[1]];
    },
    [closestDataPointX, pointer]
  );

  const layoutHeight = useMemo(() => ({ height: `${height}px` }), [height]);

  return (
    <Layout style={layoutHeight} ref={ref}>
      <svg
        height={height}
        width={width}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
      >
        <defs>
          <linearGradient id="white" x1="0" y1="0" x2="1" y2="0">
            <stop
              offset="42.71%"
              stopColor={theme.left_gradient}
              stopOpacity={0.9}
            />
            <stop
              offset="65.62"
              stopColor={theme.left_gradient}
              stopOpacity={0}
            />
            <stop
              offset="100%"
              stopColor={theme.left_gradient}
              stopOpacity={0}
            />
          </linearGradient>

          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="average-fire"
            x1="0"
            y1={height - margin.bottom}
            x2="0"
            y2={margin.top}
          >
            <stop offset="0%" stopColor={theme.average_gradient__stop_1} />
            <stop offset="75%" stopColor={theme.average_gradient__stop_1} />
            <stop offset="75%" stopColor={theme.threshold__warning} />
            <stop offset="87.5%" stopColor={theme.threshold__warning} />
            <stop offset="87.5%" stopColor={theme.threshold__max} />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="host-fire"
            x1="0"
            y1={height - margin.bottom}
            x2="0"
            y2={margin.top}
          >
            <stop offset="0%" stopColor={theme.host_gradient__stop_1} />
            <stop offset="75%" stopColor={theme.host_gradient__stop_1} />
            <stop offset="75%" stopColor={theme.threshold__warning} />
            <stop offset="87.5%" stopColor={theme.threshold__warning} />
            <stop offset="87.5%" stopColor={theme.threshold__max} />
          </linearGradient>
        </defs>
        <g
          className="y-axis__reference-lines"
          transform={`translate(${margin.left}, ${margin.top})`}
        >
          {isCriticalActive && (
            <text
              className="label-threshold label-threshold--critical"
              data-role="threshold-critical"
              strokeWidth="0.5"
              x={width - margin.left - margin.right}
              y="10%"
            >
              Critical
            </text>
          )}
          {isWarningActive && (
            <text
              className="label-threshold label-threshold--warning"
              data-role="threshold-warning"
              strokeWidth="0.5"
              x={width - margin.left - margin.right}
              y="20%"
            >
              Warning
            </text>
          )}
        </g>
        <g
          className="x-axis__container"
          transform={`translate(0, ${height - margin.top})`}
        >
          <g className="x-axis__ticks" />
          <TimeTooltip
            isVisible={!!(closestDataPointX && pointer)}
            pointerX={closestDataPointX}
            timestamp={closestDataPoint?.timestamp}
            timeframe={timeframe?.id}
          />
        </g>

        {!isBrushing &&
          closestDataPointX && (
            <Ruler
              y1={height - margin.bottom}
              y2={margin.top}
              transform={`translate(${closestDataPointX}, ${margin.top})`}
            />
          )}

        {xAxis &&
          yAxis && (
            <Lines
              data={data}
              hosts={hosts}
              xAxis={xAxis}
              yAxis={yAxis}
              onActiveChange={setActiveLine}
              transform={`translate(0, ${margin.top})`}
              activeLine={activeLine}
            />
          )}

        <rect
          width="80"
          transform={`translate(0, ${margin.top})`}
          height={height - margin.top - margin.bottom}
          fill="url(#white)"
        />

        <g
          className="y-axis__ticks"
          transform={`translate(${margin.left}, ${margin.top})`}
        />
      </svg>
      <Tooltip
        isVisible={!!(!isBrushing && closestDataPoint && closestPoint)}
        pointer={closestPoint}
        data={closestDataPoint}
        hosts={hosts}
        activeLine={activeLine}
        tooltipFormatter={tooltipFormatter}
        height={height}
        width={width}
      />
    </Layout>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  hosts: PropTypes.array.isRequired,
  yFormatter: PropTypes.func,
  tooltipFormatter: PropTypes.func,
  timeframe: PropTypes.object,
  height: PropTypes.number,
  onBrush: PropTypes.func
};

export default Chart;
