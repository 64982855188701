import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import { capitalize } from "Libs/utils";
import Button from "UI/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import InfoDialog from "Components/InfoDialog";
import LabeledInfo from "Components/LabeledInfo";

import {
  InfoLayout,
  Column,
  ColumnWrapper,
  InfoTable,
  Total,
  Amount
} from "./styles";

class PlanDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onNameChange(e) {
    const name = e.target.value;

    this.setState({
      name
    });
  }

  save() {
    const name = this.state.name;

    this.props.updateTeam({ name });
  }

  editName() {
    this.setState({
      editedName: true
    });
  }

  cancel() {
    this.setState({
      editedName: false
    });
  }

  saveName() {
    this.setState({
      editedName: false
    });
  }

  openModal() {
    this.setState({
      openModal: true
    });
  }

  closeModal() {
    this.setState({
      openModal: false
    });
  }

  render() {
    const { subscription, intl, organizationId } = this.props;

    const subscriptionEditUrl = process.env.ENABLE_CUSTOM_SUBSCRIPTION_URL
      ? subscription && subscription.data._links.plan_uri.href
      : `/-/users/${organizationId}/billing/plan/${subscription?.id}`;

    return (
      <InfoLayout>
        <ColumnWrapper>
          <InfoTable>
            <LabeledInfo
              label="Region"
              value={subscription.project_region_label}
            />
            <LabeledInfo
              label="Environments"
              value={subscription.environments}
            />
            {subscription.big_dev && (
              <LabeledInfo
                label={intl.formatMessage({
                  id: "environment_application_size"
                })}
                value={subscription.big_dev}
              />
            )}
            <LabeledInfo
              label={intl.formatMessage({ id: "plan" })}
              value={subscription.plan}
            />
            <LabeledInfo
              label={intl.formatMessage({ id: "storage" })}
              value={`${subscription.storage / 1024} GB`}
            />
            <LabeledInfo
              label={intl.formatMessage({ id: "users" })}
              value={subscription.user_licenses}
            />
          </InfoTable>
          <Column>
            <Total>
              <span>
                {intl.formatMessage({ id: "total_monthly_cost" })}*{" "}
                <InfoDialog
                  title={intl.formatMessage({ id: "estimated_cost" })}
                  text={intl.formatMessage({
                    id: "billing_system_explanation"
                  })}
                />
              </span>{" "}
              <Amount>{this.props.total}</Amount>
            </Total>
          </Column>
        </ColumnWrapper>
        {subscription && (
          <ButtonWrapper>
            <Button
              as={Link}
              to={{
                pathname: subscriptionEditUrl,
                state: { from: "plans" }
              }}
              aria-label={intl.formatMessage({
                id: "edit_plan"
              })}
            >
              {capitalize(
                intl.formatMessage({
                  id: "edit_plan"
                })
              )}
            </Button>
          </ButtonWrapper>
        )}
      </InfoLayout>
    );
  }
}

PlanDetails.propTypes = {
  subscription: PropTypes.object,
  intl: PropTypes.object,
  organizationId: PropTypes.string,
  updateTeam: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  total: PropTypes.string,
  theme: PropTypes.object
};

export default injectIntl(withRouter(withTheme(PlanDetails)));
