import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import { themeHelpers } from "Libs/theme";

import { HttpAccessControlInputs } from "./styles";
import LoginListField from "Containers/LoginListField";
import Label from "Components/fields/Label";
import Heading6 from "Components/styleguide/Heading6";
import Error from "Components/Error";

const IpTextArea = styled.textarea`
  width: 200px;
  min-height: 50px;
  resize: none;
  font-size: 14px;
  padding: 15px;
  background: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "darkShade")
      : props.theme.inputBg};
  color: ${props => props.theme.inputText};
  border: 1px solid
    ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkShade")
        : props.theme.inputBg};
  &:focus {
    border: 1px solid ${props => themeHelpers(props.theme, "primaryShade")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "primaryShade")};
  }
`;

class EnvironmentSettingsHttpAccess extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      errors,
      basicAuth,
      ipText,
      projectId,
      environment,
      onLoginChange,
      onIpTextAreaChange
    } = this.props;

    return (
      <HttpAccessControlInputs>
        <div className="field">
          {errors?.size > 0 && (
            <Error>
              {Object.values(errors.toJS()).map(value => (
                <>
                  {value}
                  <br />
                </>
              ))}
            </Error>
          )}
          <Heading6>{intl.formatMessage({ id: "logins" })}</Heading6>
          <LoginListField
            errors={errors.toJS()}
            basicAuth={basicAuth}
            onChange={onLoginChange}
            projectId={projectId}
            environmentId={environment.id}
            enabled={
              environment.hasPermission && environment.hasPermission("#edit")
            }
          />
        </div>
        <hr />
        <Heading6>{intl.formatMessage({ id: "ip_addresses" })}</Heading6>
        <div className="description">
          {intl.formatMessage({
            id: "ip_addresses_description1"
          })}
          <br />
          {intl.formatMessage({
            id: "ip_addresses_description2"
          })}
          <br />
          {intl.formatMessage({ id: "for_example" })}:{" "}
          <code>123.45.67.8 deny</code> {intl.formatMessage({ id: "or" })}
          <code>123.45.67.1/32 allow</code>
        </div>
        <div className="field">
          <Label htmlFor="ip-text-area">
            {intl.formatMessage({ id: "ip_addresses" })}
          </Label>
          <div className="access-ips">
            <IpTextArea
              id="ip-text-area"
              name="ip-text-area"
              value={ipText}
              onChange={onIpTextAreaChange}
              disabled={
                !environment.hasPermission && environment.hasPermission("#edit")
              }
              aria-disabled={
                !environment.hasPermission && environment.hasPermission("#edit")
              }
            />
          </div>
        </div>
      </HttpAccessControlInputs>
    );
  }
}

EnvironmentSettingsHttpAccess.propTypes = {
  intl: PropTypes.object,
  errors: PropTypes.object,
  environment: PropTypes.object,
  projectId: PropTypes.string,
  basicAuth: PropTypes.array,
  ipText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onLoginChange: PropTypes.func,
  onIpTextAreaChange: PropTypes.func
};

export default injectIntl(EnvironmentSettingsHttpAccess);
