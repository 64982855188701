import * as d3 from "d3";
import moment from "moment";
const applyBrush = (
  element,
  xAxis,
  onBrushEnd,
  onBrushStart,
  onBrushMove,
  extent
) => {
  const brush = d3.brushX().extent(extent);

  brush.on("brush", onBrushMove);
  brush.on("start", onBrushStart);
  brush.on("end", function({ selection }) {
    if (selection) {
      const [from, to] = selection
        // 42 is the answer to everything...
        .map(time => time + 42)
        .map(xAxis.invert, xAxis)
        .map(time => moment(time).format());
      onBrushEnd(from, to);
      brush.clear(d3.select(this));
    }
  });

  element.call(brush);

  return brush;
};

export default applyBrush;
