import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import IntegrationIcon from "../../../components/IntegrationIcon";
import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./styles";

const IntegrationButton = ({ integration }) => {
  const intl = useIntl();
  const history = useHistory();

  const { category, type } = integration;

  const handleSelect = e => {
    e?.preventDefault();
    history.push(`setup/${type.replace(".", "_")}/configure`);
  };

  return (
    <S.Wrapper>
      <AccessibleTooltip
        tooltipProps={{
          id: `integration-${type}`,
          children: (
            <>
              {intl.formatMessage({ id: `integrations.category.${category}` })}
            </>
          )
        }}
      >
        <S.Button onClick={handleSelect}>
          <IntegrationIcon type={type} />
          <S.Name>
            {intl.formatMessage({ id: `integration.type.${type}` })}
          </S.Name>
        </S.Button>
      </AccessibleTooltip>
    </S.Wrapper>
  );
};

IntegrationButton.propTypes = {
  integration: PropTypes.object.isRequired
};

export default IntegrationButton;
