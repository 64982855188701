import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Button from "UI/Button";
import { capitalize } from "Libs/utils";
import { CUSTOM_DOCS_WCAG_INFO_URL } from "Constants/documentationUrls";

import InputField from "Components/fields/InputField";
import CheckboxField from "Components/fields/CheckboxField";
import RadioField from "Components/fields/RadioField";

import ImageUpload from "../ImageUpload";

import {
  Container,
  Form,
  FormBox,
  ImageBox,
  SectionTitle,
  FieldLink,
  ThemeHelpText,
  InlineHelpText,
  ButtonWrapper
} from "./styles";

import { colorSchemeFields, userDetailFields } from "./options";

import { useUserPicture } from "./useUserPicture";

const BasicFormSettings = props => {
  const intl = useIntl();
  const initialFormValues = {
    first_name: props.first_name,
    last_name: props.last_name,
    username: props.username,
    email: props.resetEmail || props.email,
    website: props.website,
    company: props.company,
    marketing: props.newsletter,
    ui_colorscheme: props.ui_colorscheme,
    picture: props.picture
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [changedValues, setChangedValues] = useState({});
  const userPicture = useUserPicture(formValues.picture);

  useEffect(
    () => {
      const updatedFormValues = {
        first_name: props.first_name,
        last_name: props.last_name,
        username: props.username,
        email: props.emailReset || props.email,
        website: props.website,
        company: props.company,
        marketing: props.marketing,
        ui_colorscheme: props.ui_colorscheme,
        picture: props.picture
      };
      setFormValues(updatedFormValues);
    },
    [props.username, props.picture, props.cacheKey]
  );

  const onFieldChange = (value, fieldName) => {
    const fieldUpdates = {
      ...changedValues,
      [fieldName]: value
    };
    const updatedFormValues = {
      ...formValues,
      ...fieldUpdates
    };
    setFormValues(updatedFormValues);
    setChangedValues(fieldUpdates);
  };

  const onSave = () => {
    props.onSave(changedValues);

    setChangedValues({});
  };

  const onCancel = () => {
    setFormValues(initialFormValues);
    setChangedValues({});
  };

  const isChanged = Object.keys(changedValues).length > 0;

  return (
    <Container>
      <ImageBox>
        <ImageUpload
          picture={userPicture}
          username={props.username}
          onChange={picture => {
            if (picture.size > 1900000) {
              props.onPictureError(
                {
                  message: "exceeding the maximum file size"
                },
                picture
              );
            } else {
              onFieldChange(picture, "picture");
            }
          }}
        />
      </ImageBox>
      <Form>
        <FormBox>
          <SectionTitle>
            {intl.formatMessage({ id: "user_details" })}
          </SectionTitle>
          {userDetailFields.map(value => (
            <InputField
              width="100%"
              onChange={e => onFieldChange(e.target.value, value)}
              key={value}
              id={`organization-${value}`}
              label={intl.formatMessage({ id: value })}
              value={formValues[value]}
            />
          ))}
          {props.emailReset && (
            <InlineHelpText data-test="email-reset-help">
              {intl.formatMessage(
                { id: "reset_email_message" },
                {
                  cd: str => <code>{str}</code>, // eslint-disable-line
                  emailAddress: props.emailReset
                }
              )}
            </InlineHelpText>
          )}
          <FieldLink href={`${process.env.AUTH_URL}/reset-password`}>
            {intl.formatMessage({ id: "reset_password" })}
          </FieldLink>

          <SectionTitle withTopSpacing={true}>
            {intl.formatMessage({ id: "company_details" })}
          </SectionTitle>
          <InputField
            onChange={e => onFieldChange(e.target.value, "website")}
            id="organization-website"
            label={intl.formatMessage({ id: "website" })}
            value={formValues.website}
            placeholder={intl.formatMessage({ id: "enter_url" })}
            required={false}
            width="100%"
          />
          <InputField
            onChange={e => onFieldChange(e.target.value, "company")}
            id="organization-company"
            label={intl.formatMessage({ id: "company_name" })}
            value={formValues.company}
            placeholder={intl.formatMessage({ id: "enter_company_name" })}
            required={false}
            width="100%"
          />
        </FormBox>
        <FormBox>
          <SectionTitle>
            {intl.formatMessage({ id: "notification_settings" })}
          </SectionTitle>
          <CheckboxField
            id="newsletter-label"
            forId="newsletter"
            title={intl.formatMessage({ id: "newsletter" })}
            label={intl.formatMessage({
              id: "newsletter.description"
            })}
            onChange={e => onFieldChange(e.target.checked, "marketing")}
            value={formValues.marketing}
          />
          <SectionTitle border="top">
            {intl.formatMessage({ id: "display_settings" })}
          </SectionTitle>
          <ThemeHelpText>
            {intl.formatMessage(
              { id: "ui_color_scheme_help_text" },
              {
                // eslint-disable-next-line
                cta: str => (
                  <a
                    href={CUSTOM_DOCS_WCAG_INFO_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {str}
                  </a>
                ),
                verb: intl.formatMessage({ id: "learnmore" })
              }
            )}
          </ThemeHelpText>
          {Object.entries(colorSchemeFields).map(([key, value]) => (
            <RadioField
              id={`${key}-label`}
              key={key}
              forId={`${key}`}
              title={intl.formatMessage({ id: value.messageId })}
              label={intl.formatMessage({
                id: value.messageId
              })}
              onChange={() => onFieldChange(key, "ui_colorscheme")}
              value={formValues.ui_colorscheme === key}
              marginBottom="24px"
            />
          ))}
          {isChanged && (
            <ButtonWrapper>
              <Button
                id="save_organization_general_settings_button"
                className="primary"
                onClick={onSave}
                aria-label={intl.formatMessage({ id: "save" })}
              >
                {capitalize(intl.formatMessage({ id: "save" }))}
              </Button>
              <Button
                id="cancel-organization-name-btn"
                onClick={onCancel}
                variant="secondary"
                aria-label={intl.formatMessage({ id: "cancel" })}
              >
                {capitalize(intl.formatMessage({ id: "cancel" }))}
              </Button>
            </ButtonWrapper>
          )}
        </FormBox>
      </Form>
    </Container>
  );
};

// Prop keys for the form need to match
// what they are in the user object from the API
// ex: first_name and not firstName. This
// makes it easier to handling saving the form data.
BasicFormSettings.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  website: PropTypes.string,
  marketing: PropTypes.bool,
  onSave: PropTypes.func,
  newsletter: PropTypes.bool,
  ui_colorscheme: PropTypes.string,
  emailReset: PropTypes.string,
  cacheKey: PropTypes.string,
  resetEmail: PropTypes.string,
  picture: PropTypes.string,
  onPictureError: PropTypes.func
};

export default BasicFormSettings;
