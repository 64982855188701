import styled from "styled-components";
import { semiBoldAlias, makePalette } from "Libs/theme";

export const colorDefinitions = palette => ({
  left_gradient: {
    default: palette.snow,
    dark: palette.ebony
  },
  average_gradient__stop_1: {
    default: palette.slate,
    dark: palette.snow,
    contrast: "#01001a"
  },
  host_gradient__stop_1: {
    default: palette.granite,
    contrast: "#8c94a1"
  },
  threshold__warning: {
    default: palette.gold,
    contrast: "#b88d00"
  },
  threshold__max: {
    default: palette.red,
    contrast: "#e50000"
  },
  threshold__none: {
    default: palette.periwinkleGrey
  },
  text: {
    default: palette.granite,
    dark: palette.periwinkleGrey,
    contrast: palette.ebonyLight
  }
});

const theme = makePalette(colorDefinitions);

export const Layout = styled.div`
  margin-top: 12px;
  position: relative;

  svg {
    text {
      font-size: 10px;
      ${semiBoldAlias()};
      user-select: none;
      line-height: 22px;
      fill: ${theme.text};
    }
  }

  .label-threshold {
    ${semiBoldAlias()};
    font-size: 10px;
    line-height: 22px;
    text-align: right;
    text-transform: lowercase;
    transform-box: fill-box;
    transform-origin: left;
    transform: translateX(-100%);
  }

  .label-threshold--warning {
    fill: ${theme.threshold__warning};
  }

  .label-threshold--critical {
    fill: ${theme.threshold__max};
  }

  .y-axis__ticks {
    .domain,
    .tick line {
      display: none;
      stroke-width: 0.5;
    }
  }
  .y-axis__reference-lines {
    .tick line {
      opacity: 0.6;
      fill: none;
      stroke-width: 1;
    }

    .domain,
    .tick text {
      display: none;
    }
  }

  .x-axis__ticks {
    .domain,
    .tick line {
      display: none;
    }

    .tick text {
      stroke-width: 0.5;
    }
  }

  .selection {
    fill: rgba(71, 134, 255, 0.16);
    stroke: transparent;
    fill-opacity: 1;
  }

  .overlay {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 21'%3E%3Cdefs/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath fill='%23302F45' d='M5.53019 4.24955L10.0466 14.8938c.1737.4093.7553.4053.9232-.0065l1.474-3.6133c.0581-.1425.1786-.2503.3266-.2922l3.9889-1.13024c.4392-.12446.4956-.72411.0873-.92828L6.21408 3.60704c-.41581-.20791-.86548.21455-.68389.64251z'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='.5' d='M9.81643 14.9914L5.30005 4.3472c-.27238-.64194.40212-1.27562 1.02583-.96377L16.9584 8.69967c.6125.30626.5279 1.20573-.131 1.39243l-3.9888 1.1302c-.074.021-.1343.0749-.1633.1461l-1.474 3.6134c-.2519.6176-1.1243.6237-1.38487.0096z'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' width='20.6361' height='20.648' x='.987061' y='.0507812' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix values='0 0 0 0 0.596078 0 0 0 0 0.627451 0 0 0 0 0.670588 0 0 0 0.4 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")
        16 16,
      pointer;
  }
`;
