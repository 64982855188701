import styled from "styled-components";

import { themeHelpers, semiBoldAlias } from "Libs/theme";

import InfoDialog from "Components/InfoDialog";
import Heading3 from "Components/styleguide/Heading3";

export const ScreenshotWrapper = styled.div`
  display: flex;
  max-width: 100%;
  height: 180px;
  margin: 0;
  border-radius: 2px;
`;

export const Details = styled.div`
  padding: 20px 20px 28px 32px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Org = styled.p`
  margin: 7px 0 16px;
  font-size: 12px;
  line-height: 18px;
  ${semiBoldAlias};
  text-transform: uppercase;
  color: ${props => props.theme.envTreeInactiveIcon};
`;

export const ProjectName = styled(Heading3)`
  margin: 0 0 14px;
  line-height: 24px;
`;

export const Region = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  ${semiBoldAlias};
  color: ${props => props.theme.sectionText};
`;

export const RegionSuffix = styled.span`
  margin-left: 5px;
  font-size: 12px;
  ${semiBoldAlias};
  color: ${props => props.theme.envTreeInactiveIcon};
`;

export const Tag = styled.div`
  marginL 0 4px 0 0;
  padding: 2px 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  background: ${props => props.theme.background};
  color: ${props => props.theme.textLight};
`;

export const NoAccessTag = styled(Tag)`
  display: flex;
  justify-content: center;
  align-item: center;
  padding-left: 0px;
`;

export const Trial = styled(Tag)`
  background: ${props => props.theme.bannerInfoBackgroundColor};
  color: ${props => props.theme.bannerInfoColor};
`;

export const WarningDialog = styled(InfoDialog)`
  &,
  &:hover {
    svg {
      > path {
        fill: #eda900 !important;
      }
    }
  }
`;

export const ProjectCardLayout = styled.div`
  max-width: 100%;
  margin: 0;
  cursor: pointer;
  border-radius: 2px;
  width: 100%;
  background: ${props => themeHelpers(props.theme, "sectionColor")};
  .row {
    color: ${props => props.theme.sectionText};
    &:hover .cell.cell-project {
      color: ${props => themeHelpers(props.theme, "primaryColor")};
    }
  }

  &:hover,
  &:focus {
    background: ${props =>
      props.theme.name === "dark"
        ? props.theme.dark
        : themeHelpers(props.theme, "sectionColor")};
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0 6px 44px rgba(152, 160, 171, 0.6)"};
    ${ProjectName} {
      color: ${props => themeHelpers(props.theme, "primaryColor")};
    }
  }

  a {
    text-decoration: none;
  }
`;
