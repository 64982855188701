import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";

import { goToWizardStep, toggleProjectWizard } from "Reducers/project/wizard";

import Heading2 from "Components/styleguide/Heading2";
import SuccessStateIcon from "Components/icons/SuccessStateIcon";

import * as S from "./styles";

const ListSteps = ({ embedded = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { organizationId, projectId } = useDecodedParams();

  const projectWizard = useSelector(state => {
    return state.projectWizard
      ?.getIn(["data", organizationId, projectId], new Map())
      .toJS();
  });

  const handleClose = e => {
    e?.preventDefault();
    dispatch(toggleProjectWizard({ organizationId, projectId }));
  };

  const goToStep = step => {
    dispatch(goToWizardStep({ organizationId, projectId, step }));
  };

  const { completed, data: steps, start } = projectWizard;

  const Wrapper = embedded ? S.WrapperEmbedded : S.Wrapper;

  return (
    <Wrapper>
      {!embedded && (
        <>
          <S.Close onClick={handleClose} id="setup-wizard-close-btn" />
          <Heading2>
            {intl.formatMessage({
              id: `wizard.list.steps.${start ? "start" : "finish"}`
            })}
          </Heading2>
        </>
      )}

      {steps?.map((step, index) => (
        <S.Step
          key={step.id}
          onClick={() => {
            goToStep(index + 1);
          }}
        >
          <S.Ellipsis>
            {`${index + 1}.`}&nbsp;&nbsp;{step.label}
            {!step.required && <S.Optional> (Opt)</S.Optional>}
          </S.Ellipsis>
          {completed &&
            completed[step.id] && <SuccessStateIcon color="#23b7b7" />}
        </S.Step>
      ))}
    </Wrapper>
  );
};

ListSteps.propTypes = {
  embedded: PropTypes.bool
};

export default ListSteps;
