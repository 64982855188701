import React from "react";
import styled from "styled-components";

import { Skeleton, Card, Square } from "Components/Skeleton";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 32px;
  grid-column-gap: 64px;
`;

const SkeletonWrapper = styled.div`
  margin-bottom: 32px;
`;

const ProjectOverviewSkeleton = () => (
  <SkeletonWrapper synchronize>
    <Skeleton synchronize>
      <Card>
        <Card padding="32px">
          <Square height="24px" width="226px" margin="0 0 32px 0" />

          <Grid>
            <Square height="48px" width="150px" />
            <Square height="48px" width="150px" />
            <Square height="48px" width="150px" />
            <Square height="48px" width="150px" />
            <Square height="48px" width="150px" />
            <Square height="48px" width="150px" />
            <Square height="48px" width="150px" />
          </Grid>
        </Card>
        <Square height="64px" width="100%" />
      </Card>
    </Skeleton>
  </SkeletonWrapper>
);

export default ProjectOverviewSkeleton;
