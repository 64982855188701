import React from "react";
import PropTypes from "prop-types";

import CopyableArea from "Components/CopyableArea";
import Layout from "../../../../components/Layout";
import Row from "../../../../components/Row";

import AppInfos from "../../../../../../../../common/components/AppInfos/AppInfos";
import Crons from "../../Crons";
import Workers from "../../Workers";

import * as S from "./styles";
import SshHostList from "./SshHostList";

const AppOverview = ({ metadata, ssh, workers }) => (
  <>
    <Layout>
      <Row gridTemplate="11.2% 11.2% 11.2% 11.2% 11.2% 44%">
        <AppInfos metadata={metadata} workers={workers.size} />
        <S.SshURLList>
          {typeof ssh === "string" && (
            <CopyableArea
              key={ssh}
              id={`ssh-key-${ssh}`}
              content={ssh}
              singleLine={true}
            >
              {ssh}
            </CopyableArea>
          )}
          {ssh instanceof Array && <SshHostList sshList={ssh} />}
        </S.SshURLList>
      </Row>
    </Layout>

    <Crons list={metadata.crons} />
    <Workers list={workers} />
  </>
);

AppOverview.propTypes = {
  metadata: PropTypes.object,
  workers: PropTypes.object,
  ssh: PropTypes.oneOf([PropTypes.string, SshHostList.propTypes.sshList])
};

export default AppOverview;
