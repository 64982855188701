import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import { capitalize } from "Libs/utils";
import Button from "UI/Button";
import Loading from "Components/Loading";
import ButtonWrapper from "Components/ButtonWrapper";

const FormWrapper = styled.form`
  padding: 32px;
  hr {
    background: ${props => props.theme.sectionBorder};
  }
`;

class EnvironmentSettingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl,
      title,
      close,
      save,
      saveText = intl.formatMessage({ id: "save" }),
      children,
      savePermission,
      deletePermission,
      isUpdateLoading,
      itemId,
      cancel,
      hideButtons
    } = this.props;

    return (
      <FormWrapper
        id={`environment-${itemId}-form`}
        aria-labelledby="environment-basics-heading"
      >
        <LiveMessage message={title} aria-live="polite" />
        {isUpdateLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {children}
            {(deletePermission || this.props.save) &&
              !hideButtons && (
                <ButtonWrapper>
                  {savePermission &&
                    this.props.save && (
                      <Button
                        id={`environment-basics-${itemId}-save-btn`}
                        type="submit"
                        aria-label={saveText}
                        onClick={save}
                      >
                        {capitalize(saveText)}
                      </Button>
                    )}
                  {cancel &&
                    this.props.save && (
                      <Button
                        id={`environment-basics-${itemId}-cancel-btn`}
                        variant="secondary"
                        aria-label={intl.formatMessage({ id: "cancel" })}
                        onClick={() => {
                          close();
                          cancel();
                        }}
                      >
                        {capitalize(intl.formatMessage({ id: "cancel" }))}
                      </Button>
                    )}
                  {deletePermission && (
                    <Button
                      use="a"
                      href
                      variant="tertiary"
                      role="button"
                      aria-label={intl.formatMessage({
                        id: "delete_environment"
                      })}
                      id={`environment-basics-${itemId}-delete-btn`}
                      onClick={this.openModal.bind(this)}
                    >
                      {capitalize(
                        intl.formatMessage({
                          id: "delete_environment"
                        })
                      )}
                    </Button>
                  )}
                </ButtonWrapper>
              )}
          </React.Fragment>
        )}
      </FormWrapper>
    );
  }
}

EnvironmentSettingWrapper.propTypes = {
  intl: PropTypes.object,
  title: PropTypes.string,
  close: PropTypes.func,
  save: PropTypes.func,
  saveText: PropTypes.string,
  children: PropTypes.node,
  deletePermission: PropTypes.bool,
  savePermission: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  itemId: PropTypes.string,
  cancel: PropTypes.func,
  hideButtons: PropTypes.bool
};

export default injectIntl(EnvironmentSettingWrapper);
