import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Map } from "immutable";
import { Redirect, useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import ContentLayout from "Components/ContentLayout";
import { ServicesDisplay } from "../../../../common/containers/ServicesDisplay";
import EnvironmentOverview from "../overview/Page";

import ActivitySection from "../../../../common/containers/ActivitySection";
import InfoDropButtons from "../../../../common/containers/InfoDropButtons";
import ListGroup from "Components/ListGroup";
import Heading2 from "Components/styleguide/Heading2";
import { loadDeployment as loadDeploymentAction } from "Reducers/environment/deployment";
import withProjectRedirect from "../../../../common/hocs/withProjectRedirect";
import withReducers from "Hocs/withReducers";

import OverviewSkeleton from "../overview/containers/Overview/OverviewSkeleton";
import ServicesDisplaySkeleton from "../../../../common/components/ServicesDisplaySkeleton";

import * as S from "./styles";

const Detail = ({ children }) => {
  const params = useDecodedParams();
  const router = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [areServicesReady, setAreServicesReady] = useState(false);

  const envIsLoaded = useSelector(
    state => !state.environment.get("loading", true)
  );
  const environment = useSelector(state =>
    state.environment?.getIn(
      ["data", params.organizationId, params.projectId, params.environmentId],
      {}
    )
  );
  const project = useSelector(state =>
    state.project?.getIn(["data", params.organizationId, params.projectId])
  );

  const currentDeployment = useSelector(state =>
    state.deployment?.getIn(
      [
        "data",
        params.organizationId,
        params.projectId,
        params.environmentId,
        "current"
      ],
      new Map()
    )
  );

  const loadDeployment = () =>
    dispatch(
      loadDeploymentAction(
        params.organizationId,
        params.projectId,
        params.environmentId
      )
    );

  useEffect(
    () => {
      if (params.environmentId) {
        loadDeployment();
      }
    },
    [params.environmentId]
  );

  const getEnvUrl = () => {
    const routes = currentDeployment?.get("routes");
    let primaryRoute = "";

    if (routes) {
      // If there is no primary:true, try to be smart and find the first link that is not a redirect
      // If we're still not finding a link, we take the first in the list
      primaryRoute =
        routes.findKey(route => route.get("primary") === true) ||
        routes.findKey(route => route.get("type") !== "redirect") ||
        routes.keySeq().get(0);
    }

    return primaryRoute;
  };

  const envUrl = getEnvUrl();

  if (!environment) {
    return false;
  }

  // Redirect to settings page if env doesn't exist and name matches
  // settings pages [settings, integrations. plan, wait]
  if (envIsLoaded && Object.entries(environment).length === 0) {
    if (
      ["settings", "integrations", "plan", "wait"].includes(
        params.environmentId
      )
    ) {
      const url = `/${params.organizationId}/${params.projectId}/-/${
        params.environmentId
      }`;
      return <Redirect to={url} />;
    }
  }

  return (
    <ContentLayout>
      {project && (
        <InfoDropButtons
          className="buttons"
          router={router}
          project={project}
        />
      )}
      <S.DashboardContainer>
        <aside className="environment-info col-4">
          {!envIsLoaded ? (
            <OverviewSkeleton />
          ) : (
            <ListGroup labelledby="environment-info-heading">
              {children || (
                <EnvironmentOverview
                  router={router}
                  project={project}
                  intl={intl}
                  envUrl={envUrl}
                  {...params}
                />
              )}
            </ListGroup>
          )}

          <ListGroup
            style={{ position: "relative" }}
            className="apps-services"
            aria-labelledby="environment-services-heading"
          >
            {!areServicesReady && <ServicesDisplaySkeleton />}
            <Heading2 id="environment-services-heading">
              {intl.formatMessage({ id: "apps_services" })}
            </Heading2>
            <ServicesDisplay
              height="250px"
              minHeight="250px"
              treePositionY={10}
              maxHeight={220}
              hasInfoTooltip
              onLoadEnd={() => setAreServicesReady(true)}
              {...params}
            />
          </ListGroup>
        </aside>
        <ActivitySection context="environment" />
      </S.DashboardContainer>
    </ContentLayout>
  );
};

Detail.propTypes = {
  children: PropTypes.node
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  deployment: () => import("Reducers/environment/deployment"),
  service: () => import("Reducers/environment/service")
})(withProjectRedirect(Detail));
