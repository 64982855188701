import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import withProject from "./hoc/withProject";
import ProjectMoreLinks from "Components/ProjectMoreLinks";
import { getOwnerInfoLabel } from "Libs/utils";

import {
  InfoLayout,
  Project,
  End,
  DetailLayout,
  Plan,
  CommonSpan
} from "./styles";

const PlanListItem = ({ project = {}, subscription, theme, organizations }) => (
  <InfoLayout color={theme.textLight}>
    <Project>
      <strong>{subscription.project_title || "Untitled Project"}</strong>
    </Project>
    <End>
      <DetailLayout
        hasEditLinks={
          project.hasPermission && project.hasPermission("#subscription_change")
        }
      >
        <Plan>{subscription.plan}</Plan>
        <CommonSpan>
          <strong>{subscription.project_region_label}</strong>
        </CommonSpan>
        <CommonSpan>
          {getOwnerInfoLabel(subscription, organizations?.toJS())}
        </CommonSpan>
      </DetailLayout>
      <ProjectMoreLinks
        iconColor="black"
        id={`${subscription.project_id}-plan-more-link`}
        from="plans"
        project={project}
        subscription={subscription}
        projectId={project?.id}
      />
    </End>
  </InfoLayout>
);

PlanListItem.propTypes = {
  subscription: PropTypes.object,
  organizationId: PropTypes.string,
  project: PropTypes.object,
  projectId: PropTypes.string,
  theme: PropTypes.object,
  organizations: PropTypes.object
};

export default withProject(withTheme(PlanListItem));
