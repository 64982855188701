import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import { ThemeContext } from "styled-components";
import InfiniteScroll from "react-infinite-scroller";

import {
  getOrganizationDescriptionIdFromProject,
  hasSafeRole,
  sortProjectList
} from "Libs/utils";

import Loading from "Components/Loading";
import ProjectCard from "../ProjectCard";
import ProjectsListLayout from "Components/ProjectsListLayout";
import ErrorBoundary from "Components/ErrorBoundary";
import Label from "Components/fields/Label";
import ChevronIcon from "Icons/ChevronIcon";
import Heading2 from "Components/styleguide/Heading2";
import EmptyProjects from "Components/illustrations/EmptyProjects";
import CreateNewProject from "Components/illustrations/CreateNewProject";
import PageMeta from "Components/PageMeta";
import NewProjectButton from "Components/NewProjectButton";

import * as S from "./styles";

const Projects = ({
  organizationId,
  gridLayout,
  sortType,
  setSortType,
  sortOrder,
  setSortOrder,
  setHasProjects
}) => {
  const [page, setPage] = useState(0);
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const me = useSelector(state => state.app?.get("me", new Map()));

  const projects = useSelector(state =>
    state.project?.getIn(
      ["list", organizationId],
      // We don't have organisation yet
      // So props.organizationId is undefined
      state.project?.get("all")
    )
  );

  const search = useSelector(state => state.search?.get("query", ""));
  const organizations = useSelector(state =>
    state.organization?.get("data", new Map())
  );

  useEffect(
    () => {
      if (projects.size > 0) {
        setHasProjects(true);
      }
    },
    [projects?.size]
  );

  const projectFilter = project => {
    const title = project.title;
    const id = project.id;

    if (!search) {
      return true;
    }

    if (id.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }

    if (!title) {
      return false;
    }

    return title.toUpperCase().includes(search.toUpperCase());
  };

  const toggleSort = value => {
    setPage(0);
    let newSortOrder = sortOrder;
    let newSortType = sortType;
    if (sortType === value) {
      if (sortOrder === "ascend") {
        newSortOrder = "descend";
      } else {
        newSortOrder = "ascend";
      }
    } else {
      newSortType = value;
      newSortOrder = "ascend";
    }

    setSortType(newSortType);
    setSortOrder(newSortOrder);
  };

  if (!projects) {
    return false;
  }

  let projectPerPage = 20;
  if (gridLayout === "list") {
    projectPerPage = 20;
  }

  const filteredProjects = projects
    .filter(projectFilter)
    .sort((a, b) => sortProjectList(a, b, { sortOrder, sortType }));

  const user = me?.toJS();

  const loadMore = () => {
    setPage(page + 1);
  };

  const isTrial = (() => {
    if (hasSafeRole(user.roles)) return false;
    const { data } = user;
    return (
      user.trial ||
      (data?.current_trial === null || data?.current_trial?.expiration === null)
    );
  })();

  return (
    <ErrorBoundary>
      <PageMeta title={"Projects"} />
      {projects.size === 0 ? (
        <S.EmptyProjectsListLayout className="no-projects">
          <S.ImageWrapper className="no-projects-image">
            <CreateNewProject />
          </S.ImageWrapper>
          <Heading2>
            {intl.formatMessage({
              id: `create_first_project${isTrial ? ".trial" : ""}`
            })}
          </Heading2>
          <p>
            {intl.formatMessage({ id: "create_first_project_description" })}
          </p>
          <NewProjectButton organizationId={organizationId} user={user} />
        </S.EmptyProjectsListLayout>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={(page + 1) * projectPerPage < filteredProjects.size}
          loader={<Loading iconOnly={true} />}
          initialLoad={false}
        >
          <ProjectsListLayout
            className={`${gridLayout ? "grid" : "list"}${
              filteredProjects.size === 0 ? " no-results" : ""
            }`}
          >
            <LiveMessage
              role="status"
              aria-live="assertive"
              message={
                filteredProjects.size
                  ? `${filteredProjects.size} projects found`
                  : "Sorry, no projects found"
              }
            />
            {!gridLayout && (
              <S.LabelWrapper>
                <Label
                  className={`label label-project${
                    sortType === "name" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  onClick={() => toggleSort("name")}
                  onKeyUp={e => e.keyCode === 13 && toggleSort("name")}
                  as="button"
                >
                  Project name{" "}
                  <ChevronIcon
                    color={sortType === "name" ? theme.links : "#4b6180"}
                    isOpen={sortType === "name" && sortOrder === "descend"}
                    animate={true}
                  />
                </Label>
                <Label
                  className={`label label-owner col-2${
                    sortType === "owner" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  onClick={() => toggleSort("owner")}
                  onKeyUp={e => e.keyCode === 13 && toggleSort("owner")}
                  as="button"
                >
                  Owner{" "}
                  <ChevronIcon
                    color={sortType === "owner" ? theme.links : "#4b6180"}
                    isOpen={sortType === "owner" && sortOrder === "descend"}
                    animate={true}
                  />
                </Label>
                <Label
                  className={`label label-region col-2${
                    sortType === "region" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  onClick={() => toggleSort("region")}
                  onKeyUp={e => e.keyCode === 13 && toggleSort("region")}
                  as="button"
                >
                  Region{" "}
                  <ChevronIcon
                    color={sortType === "region" ? theme.links : "#4b6180"}
                    isOpen={sortType === "region" && sortOrder === "descend"}
                    animate={true}
                  />
                </Label>
                <Label
                  className={`label label-plan col-2${
                    sortType === "plan" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  onClick={() => toggleSort("plan")}
                  onKeyUp={e => e.keyCode === 13 && toggleSort("plan")}
                  as="button"
                >
                  Plan{" "}
                  <ChevronIcon
                    color={sortType === "plan" ? theme.links : "#4b6180"}
                    isOpen={sortType === "plan" && sortOrder === "descend"}
                    animate={true}
                  />
                </Label>
                <Label
                  className={`label label-id col-2${
                    sortType === "id" && sortOrder === "descend"
                      ? " descending"
                      : ""
                  }`}
                  onClick={() => toggleSort("id")}
                  onKeyUp={e => e.keyCode === 13 && toggleSort("id")}
                  as="button"
                >
                  ID{" "}
                  <ChevronIcon
                    color={sortType === "id" ? theme.links : "#4b6180"}
                    isOpen={sortType === "id" && sortOrder === "descend"}
                    animate={true}
                  />
                </Label>
              </S.LabelWrapper>
            )}
            {filteredProjects
              .slice(0, (page + 1) * projectPerPage)
              .map(project => (
                <ProjectCard
                  key={project.id}
                  gridLayout={gridLayout}
                  projectId={project.id}
                  project={project}
                  organizationId={getOrganizationDescriptionIdFromProject(
                    project,
                    organizations?.toJS()
                  )}
                  organizations={organizations}
                  url={`/${getOrganizationDescriptionIdFromProject(
                    project,
                    organizations?.toJS()
                  )}/${project.id}`}
                />
              ))}
            {filteredProjects.size === 0 && (
              <S.EmptyProjectsListLayout>
                <S.ImageWrapper>
                  <EmptyProjects />
                </S.ImageWrapper>
                <Heading2>Sorry, no projects found</Heading2>
                <p>Try adjusting your search</p>
              </S.EmptyProjectsListLayout>
            )}
          </ProjectsListLayout>
        </InfiniteScroll>
      )}
    </ErrorBoundary>
  );
};

Projects.propTypes = {
  organizationId: PropTypes.string,
  gridLayout: PropTypes.bool,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func,
  setHasProjects: PropTypes.func
};

export default Projects;
