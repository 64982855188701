import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";
import useLocalForage from "Hooks/useLocalForage";

import CopyableArea from "Components/CopyableArea";
import ExpandIcon from "Components/icons/ExpandIcon";
import Label from "Components/fields/Label";
import Loading from "Components/Loading";
import ModalWrapper from "Components/Modal";
import DropdownMultiPick from "Components/DropdownMultiPick";

import * as s from "../styles";

const Services = ({ filterImageType }) => {
  const intl = useIntl();
  // eslint-disable-next-line no-unused-vars
  const [selectedServices, setSelectedServices, _, isLoading] = useLocalForage(
    "wizardconfig"
  );

  const { projectId } = useDecodedParams();

  const [modalOpen, setModal] = useState(false);
  const [services, setServices] = useState();
  const [template, setTemplate] = useState("");
  const [list, setList] = useState([]);

  const config = useSelector(state =>
    state.projectWizard?.getIn(["config", "data"])?.toJS()
  );
  const configLoading = useSelector(state =>
    state.projectWizard?.getIn(["config", "loading"], false)
  );
  const registry = useSelector(state =>
    state.projectWizard?.getIn(["registry", "data"])?.toJS()
  );
  const registryLoading = useSelector(state =>
    state.projectWizard?.getIn(["registry", "loading"], false)
  );

  useEffect(
    () => {
      if (registryLoading || !registry) return;
      const keys = Object.keys(registry) || [];
      const values = keys.reduce((acc, cu) => {
        if (
          registry[cu]?.name &&
          !registry[cu].runtime &&
          filterImageType(cu)
        ) {
          acc.push({ value: cu, label: registry[cu].name });
        }
        return acc;
      }, []);
      setList(values);
    },
    [Object.keys(registry).length]
  );

  useEffect(
    () => {
      if (isLoading || configLoading) return;
      let listSelected = [];
      if (selectedServices && selectedServices[projectId]) {
        listSelected = selectedServices[projectId];
      }
      setServices(listSelected);
    },
    [isLoading, configLoading]
  );

  useEffect(
    () => {
      if (!services) return;
      updateTemplate();
      setSelectedServices({
        ...selectedServices,
        ...{ [projectId]: services }
      });
    },
    [services]
  );

  const updateTemplate = () => {
    const serviceTpl = services.reduce((acc, cu) => {
      const service = window.atob(config[cu.value].service);
      acc += `${service}\n\n`;
      return acc;
    }, "");
    setTemplate(serviceTpl);
  };

  const handleChange = elt => {
    let listServices = [...services];
    if (listServices.some(item => item.value === elt.value)) {
      listServices = listServices.filter(item => item.value !== elt.value);
    } else {
      listServices.push(elt);
    }
    setServices(listServices);
  };

  return (
    <>
      <s.Wrapper>
        {!registryLoading && !configLoading && list.length > 0 ? (
          <DropdownMultiPick
            defaultText={
              template?.label
                ? template.label
                : intl.formatMessage({ id: "select_services" })
            }
            onChange={handleChange}
            value={services}
            options={list}
            maxHeight="186px"
          />
        ) : (
          <Loading />
        )}

        <s.FileExampleLabelWrapper>
          <Label>
            {intl.formatMessage({ id: "example_file" })}: services.yaml
          </Label>
          <s.ExpandButton onClick={() => setModal(!modalOpen)}>
            <ExpandIcon />
          </s.ExpandButton>
        </s.FileExampleLabelWrapper>

        <CopyableArea
          id="services-yaml"
          className={template ? "" : "empty-copy"}
          content={
            template
              ? template
              : intl.formatMessage({ id: "yaml_service_default" })
          }
          iconAbove={true}
          linkStyle={true}
        >
          {template
            ? template
            : intl.formatMessage({ id: "yaml_service_default" })}
        </CopyableArea>
      </s.Wrapper>

      <ModalWrapper
        isOpen={modalOpen}
        closeModal={() => setModal(!modalOpen)}
        modalClass="modal-build-log modal-large"
        title={`${intl.formatMessage({
          id: "example_file"
        })}: services.yaml`}
        copyText={template}
      >
        <pre>
          {template
            ? template
            : intl.formatMessage({ id: "yaml_service_default" })}
        </pre>
      </ModalWrapper>
    </>
  );
};

Services.propTypes = {
  filterImageType: PropTypes.func
};

export default Services;
