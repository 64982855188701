import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";

import PageMeta from "Components/PageMeta";
import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";

const OrganizationSettingsLayout = styled.div`
  margin-top: 37px;
  background-color: transparent;
`;

const OrganizationSettings = ({ children }) => {
  const intl = useIntl();
  const { organizationId } = useDecodedParams();
  const { pathname } = useLocation();
  const { push } = useHistory();

  return (
    <>
      <PageMeta title={intl.formatMessage({ id: "settings.documenttitle" })} />
      <NavBar
        push={push}
        projectId={""}
        organizationId={organizationId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>
        <OrganizationSettingsLayout className="settings-content">
          {children}
        </OrganizationSettingsLayout>
      </PageLayout>
    </>
  );
};

OrganizationSettings.propTypes = {
  children: PropTypes.node
};

export default OrganizationSettings;
