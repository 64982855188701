import client from "Libs/platform";

/**
 * Gets the current access token, it automatically reauthenticates if needed.
 *
 * @async
 * @returns {Promise<string>} the access token
 */
const getAccessToken = async () => {
  let accessToken = await client.getAccessToken();

  const currentDate = Math.round(new Date().getTime() / 1000.0);
  const expirationDate = accessToken.expires;

  if (expirationDate !== -1 && currentDate >= expirationDate) {
    accessToken = await client.reAuthenticate();
  }

  return accessToken;
};

export default getAccessToken;
