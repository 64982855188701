import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import SetupRoutes from "./setup/Routes";
import ProjectRoutes from "./project/Routes";
import ProjectsRoutes from "./organizationProjects/Routes";
import SettingsRoutes from "./settings/Routes";
import OrganizationError from "../common/containers/OrganizationError";
import BillingRoutes from "./billing/Routes";

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <OrganizationError>
      <Switch>
        <Route path={`${path}/-/settings`}>
          {process.env.ENABLE_ORGANIZATION ? (
            <SettingsRoutes />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path={`${path}/-/billing`}>
          {process.env.ENABLE_ORGANIZATION ? (
            <BillingRoutes />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path={`${path}?/create-project/:step?`}>
          <SetupRoutes />
        </Route>
        <Route path={`${path}/:projectId`}>
          <ProjectRoutes />
        </Route>
        <Route path={path}>
          {process.env.ENABLE_ORGANIZATION ? (
            <ProjectsRoutes />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
      </Switch>
    </OrganizationError>
  );
};

export default Routes;
