import styled, { css } from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.aside`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Heading = styled(Heading2)`
  line-height: 33px;
  margin-top: 0;
  margin-bottom: 16px;
`;

export const Name = styled.span`
  flex: 1;
  ${semiBoldAlias};
  font-size: 15px;
  line-height: 33px;
  color: ${({ theme }) => theme.sectionMoment};
  text-transform: capitalize;
`;

export const Id = styled.span`
  align-self: center;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: ${({ theme }) => theme.sectionMoment};
`;

export const Item = styled.article`
  display: flex;
  width: 100%;
  padding: 0 24px 0 16px;
  height: 48px;
  align-items: center;
  background: ${({ theme, isActive }) => {
    if (theme.name === "dark" && isActive) return theme.dark;
    return theme.sectionBg;
  }};
  border-radius: 2px;
  box-sizing: border-box;
  flex-direction: row;
  cursor: pointer;
  svg {
    margin-right: 16px;
    opacity: 0.75;
  }

  & + article {
    margin-top: 8px;
  }

  &:hover {
    background: ${props =>
      props.theme.name === "dark" ? props.theme.dark : props.theme.sectionBg};
    box-shadow: 0 4px 8px
      ${props =>
        props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      box-shadow: 0 4px 8px
        ${props =>
          props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};

      svg {
        opacity: 1;
      }
      ${Name} {
        color: ${theme.primary};
      }
    `};
`;
