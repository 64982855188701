import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import InlineBanner from "Components/InlineBanner";

import useDimensions from "../../hooks/useDimensions";
import isSplitArchitecture from "../../utils/isSplitArchitecture";

import Split from "../Split";
import DedicatedContainer from "../../containers/Dedicated";
import { timeframes } from "../../settings";

const Dedicated = ({ link }) => {
  const now = moment();
  const query = useMemo(
    () => ({
      stream: {
        collection: link?.collection,
        stream: "metrics"
      },
      range: {
        to: now.format(),
        from: now.subtract(timeframes[0]?.range, "seconds").format()
      }
    }),
    [timeframes[0], link]
  );

  const [dimensions, isLoading, error] = useDimensions(link?.href, query);

  const isSplit = isSplitArchitecture(dimensions);

  if (isLoading) {
    return false;
  }

  if (error) {
    return (
      <InlineBanner padding="16px" level="error">
        {error.message}
      </InlineBanner>
    );
  }

  return isSplit ? (
    <Split link={link} dimensions={dimensions} />
  ) : (
    <DedicatedContainer link={link} dimensions={dimensions} />
  );
};

Dedicated.propTypes = {
  link: PropTypes.shape({
    collection: PropTypes.string,
    href: PropTypes.string
  })
};

export default Dedicated;
