import React from "react";
import { injectIntl } from "react-intl";
import { IbanElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import client from "Libs/platform";
import logger from "Libs/logger";

import Button from "Components/Button";
import InputField from "Components/fields/InputField";
import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";

import { Layout, Disclaimer, NewCardWrapper } from "./SepaSection.styles";
import { withTheme } from "styled-components";

class SepaSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "" };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
  }

  async handleSubmit(ev) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    const { email } = this.props;
    const { name } = this.state;
    if (this.props.edit) {
      this.setState({
        isLoading: true
      });
      try {
        const sourceData = {
          type: "sepa_debit",
          // Hardcoded currency -- SEPA transactions must be in EUR.
          currency: "eur",
          owner: {
            name,
            email
          },
          mandate: {
            // Automatically send a mandate notification email to your customer
            // once the source is charged.
            notification_method: "email"
          }
        };

        const response = await this.props.stripe.createSource(sourceData);

        // organizationId is the organization.id here
        const data = await client.addOrganizationPaymentSource(
          this.props.organizationId,
          "stripe_sepa_debit",
          response.source.id
        );

        this.setState({
          isLoading: false,
          error: null
        });

        this.props.onSuccess && this.props.onSuccess(data);
      } catch (err) {
        logger(err);
        this.setState({
          isLoading: false,
          error: err
        });
      }
    } else {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value
    });
  }

  render() {
    const { name, error } = this.state;
    const {
      intl,
      number,
      onEdit,
      onCancel,
      hideButtons,
      edit,
      theme
    } = this.props;

    return (
      <Layout>
        {error && (
          <Error>
            {error.message ||
              error.description ||
              JSON.stringify(error, null, 4)}
          </Error>
        )}
        <Disclaimer>
          <InfoDialog
            title={intl.formatMessage({ id: "bank_account_payments" })}
            text={intl.formatMessage({ id: "sepa_disclamer" })}
          />
          <span>{intl.formatMessage({ id: "sepa_disclamer_short" })}</span>
        </Disclaimer>
        <form aria-labelledby="edit-card" id="stripe-payment-form">
          {!edit && number ? (
            <React.Fragment>
              <LiveMessage
                message="click update credit card to edit"
                aria-live="polite"
              />
              <InputField
                label="credit card number"
                className="card-number"
                isDisabled={true}
                value={number && number.replace(/X/g, "•").replace(/-/g, " ")}
              />
              <div>
                <Button
                  onClick={onEdit}
                  type="button"
                  aria-label={intl.formatMessage({ id: "update_bank_account" })}
                >
                  {intl.formatMessage({ id: "update_bank_account" })}
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <NewCardWrapper className="new-card">
              <LiveMessage
                message={intl.formatMessage({ id: "bank_account_payments" })}
                aria-live="polite"
              />
              <InputField
                id="name_line"
                name="name_line"
                label={intl.formatMessage({ id: "full_name" })}
                placeholder={intl.formatMessage({ id: "full_name" })}
                className="name_line"
                value={name}
                onChange={this.onNameChange}
              />
              <div>
                <Label>
                  {intl.formatMessage({ id: "bank_account_number" })}{" "}
                  <RequiredTag />
                </Label>
                <IbanElement
                  supportedCountries={["SEPA"]}
                  className="stripe-card"
                  style={{
                    base: {
                      fontSize: "14px",
                      iconColor: theme.inputText,
                      color: theme.inputText,
                      "::placeholder": {
                        fontSize: "14px",
                        color: theme.inputPlaceholder
                      }
                    }
                  }}
                  iconStyle="solid"
                />
              </div>
              {!hideButtons && (
                <React.Fragment>
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_submit"
                    className="primary"
                    onClick={this.handleSubmit}
                  >
                    {intl.formatMessage({ id: "save_changes" })}
                  </Button>
                  {number && (
                    <Button
                      type="button"
                      aria-label={intl.formatMessage({ id: "save_changes" })}
                      id="stripe_cardsection_cancel"
                      className="secondary"
                      onClick={onCancel}
                    >
                      {"cancel"}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </NewCardWrapper>
          )}
        </form>
      </Layout>
    );
  }
}

SepaSection.propTypes = {
  email: PropTypes.string,
  number: PropTypes.string,
  organizationId: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  theme: PropTypes.object
};

export default withTheme(injectStripe(injectIntl(SepaSection)));
