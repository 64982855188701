import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Button } from "@platformsh/ui-kit";

import * as S from "./styles";

const PlanNav = ({ organizationId, subscription }) => {
  const intl = useIntl();

  const subscriptionEditUrl = process.env.ENABLE_CUSTOM_SUBSCRIPTION_URL
    ? subscription && subscription.data._links.plan_uri.href
    : `/-/users/${organizationId}/billing/plan/${subscription.id}`;

  const isSubscriptionEditExternal = /^https?:\/\//.test(subscriptionEditUrl);

  return (
    <S.Container>
      <Button
        as={Link}
        to={{
          pathname: subscriptionEditUrl,
          state: { from: "project_settings" }
        }}
        external={isSubscriptionEditExternal}
      >
        {intl.formatMessage({ id: "edit_plan" })}
      </Button>
    </S.Container>
  );
};

PlanNav.propTypes = {
  organizationId: PropTypes.string,
  subscription: PropTypes.object
};

export default PlanNav;
