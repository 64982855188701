import { useEffect, useState } from "react";

import getDimensions from "../api/getDimensions";

const useDimensions = (chorusURL, query) => {
  const [dimensions, setDimensions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(
    () => {
      let isCanceled = false;
      if (chorusURL && query) {
        (async () => {
          setIsLoading(true);
          try {
            const data = await getDimensions(chorusURL, query);
            if (isCanceled) {
              return;
            }
            setDimensions(data);
          } catch (error) {
            if (isCanceled) {
              return;
            }
            setError(error);
          }
          setIsLoading(false);
        })();
      }

      return () => (isCanceled = true);
    },
    [chorusURL, query.stream.collection]
  );

  return [dimensions, isLoading, error];
};

export default useDimensions;
