import React, { useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import * as S from "./LastUpdate.styles";

const formatInterval = interval => {
  moment.updateLocale("en", {
    relativeTime: {
      past: "%s ago"
    }
  });

  const isSeconds = interval < 60;
  const format = isSeconds ? "s" : "m";
  const unit = isSeconds ? "seconds" : "minutes";

  const value = moment({ hour: 0, minute: 0, second: 0 })
    .seconds(interval)
    .format(format);

  return [value, unit];
};

const getLastUpdatedAt = lastDataPoint => moment(lastDataPoint).fromNow();

const LastUpdated = ({ interval, lastDataPoint, isCustomTimeframe }) => {
  const [time, unit] = useMemo(() => formatInterval(interval), [interval]);
  const lastUpdated = useMemo(() => getLastUpdatedAt(lastDataPoint), [
    lastDataPoint
  ]);

  return (
    <S.Layout>
      {!isCustomTimeframe && <S.RefreshIcon />}

      <S.Text isLowercase={!isCustomTimeframe}>
        {!isCustomTimeframe && <>{lastUpdated}, </>}
        <FormattedMessage
          id={`metrics.timeframe.info.${unit}`}
          values={{ time }}
        />
      </S.Text>
    </S.Layout>
  );
};

LastUpdated.propTypes = {
  interval: PropTypes.number,
  lastDataPoint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isCustomTimeframe: PropTypes.bool
};

export default LastUpdated;
