import React from "react";
import PropTypes from "prop-types";

import ProjectVariableListField from "./containers/ProjectVariableListField";
import ContentLayout from "Components/ContentLayout";

import withReducers from "Hocs/withReducers";

const ProjectSettingsVariable = ({ organizationId, projectId }) => (
  <ContentLayout className="settings">
    <ProjectVariableListField
      projectId={projectId}
      organizationId={organizationId}
    />
  </ContentLayout>
);

ProjectSettingsVariable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default withReducers({
  projectVariable: () => import("Reducers/project/settings/variable")
})(ProjectSettingsVariable);
