import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { injectIntl } from "react-intl";
import momentjs from "moment";

import EmptyProjects from "Components/illustrations/EmptyProjects";
import Heading2 from "Components/styleguide/Heading2";
import NewProjectButton from "Components/NewProjectButton";

import OrderItemList from "../../components/OrderItemList";

import * as S from "./styles";

class BillingListField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.expand = this.expand.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadOrders();
  }

  orderDate(date) {
    return momentjs(date).format("D MMMM YYYY");
  }

  expand(id) {
    if (this.state.expandedItem === id) {
      return this.setState({ expandedItem: null });
    }

    this.setState({ expandedItem: id });
  }

  render() {
    const {
      intl,
      previous,
      recurring,
      ordersAreLoading,
      ordersById,
      loadOrder,
      organizationId,
      user
    } = this.props;
    const orderDetails = ordersById.toJS();

    return (
      <S.Layout>
        {!ordersAreLoading && (recurring?.size > 0 || previous?.size > 0) ? (
          <>
            <OrderItemList
              orders={recurring.valueSeq()}
              // @todo: translate this.
              title="Recurring Platform.sh subscription"
              loadOrder={loadOrder}
              isLoading={ordersAreLoading}
              expand={this.expand}
              orderDetails={orderDetails}
              expandedItem={this.state.expandedItem}
              titleFilter={this.props.titleFilter}
              organizationId={organizationId}
            />
            <OrderItemList
              orders={previous.valueSeq()}
              // @todo: translate this.
              title="Previous Orders"
              loadOrder={loadOrder}
              isLoading={ordersAreLoading}
              expand={this.expand}
              orderDetails={orderDetails}
              expandedItem={this.state.expandedItem}
              titleFilter={this.props.titleFilter}
              organizationId={organizationId}
            />
          </>
        ) : (
          <S.EmptyWrapper>
            <S.EmptyImage>
              <EmptyProjects />
            </S.EmptyImage>
            <Heading2>
              {intl.formatMessage({
                id: "billing.invoices.list.empty.title",
                defaultMessage: "You have no invoices"
              })}
            </Heading2>
            <p>
              {intl.formatMessage({
                id: "billing.invoices.list.empty.description",
                defaultMessage:
                  "Find monthly invoices for any projects that you own here after you create a project."
              })}
            </p>
            <NewProjectButton organizationId={organizationId} user={user} />
          </S.EmptyWrapper>
        )}
      </S.Layout>
    );
  }
}

BillingListField.propTypes = {
  intl: PropTypes.object,
  organizationId: PropTypes.string,
  previous: PropTypes.object,
  recurring: PropTypes.object,
  titleFilter: PropTypes.string,
  loadOrder: PropTypes.func,
  loadOrders: PropTypes.func,
  ordersAreLoading: PropTypes.bool,
  ordersById: ImmutablePropTypes.map,
  user: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const orders = state.organizationOrder.getIn(
    ["data", props.organizationId],
    Map()
  );

  return {
    previous: orders.get("previous", Map()),
    recurring: orders.get("recurring", Map()),
    ordersAreLoading: state.organizationOrder.get("loading", false),
    ordersById: state.organizationOrder.get("byId", Map()),
    user: state.app.get("me", Map())?.toJS()
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadOrders: () =>
    import("Reducers/organization/order").then(reducer =>
      dispatch(reducer.loadOrganizationOrders(props.organizationId))
    ),
  loadOrder: orderId =>
    import("Reducers/organization/order").then(reducer =>
      dispatch(reducer.loadOrganizationOrder(props.organizationId, orderId))
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BillingListField));
