import React from "react";

import EnvironmentVariableListField from "./containers/EnvironmentVariableListField";
import ContentLayout from "Components/ContentLayout";

import withReducers from "Hocs/withReducers";

const EnvironmentSettingsVariable = () => {
  return (
    <ContentLayout className="settings">
      <EnvironmentVariableListField />
    </ContentLayout>
  );
};

export default withReducers({
  environmentVariable: () => import("Reducers/environment/settings/variable")
})(EnvironmentSettingsVariable);
