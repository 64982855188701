import styled from "styled-components";

export const DashboardContainer = styled.div`
  width: 100%;
  > section,
  > aside > section {
    padding: 32px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .environment-info,
  .apps-services {
    box-sizing: border-box;
    padding-bottom: 14px;
  }
  .environment-activity-wrapper {
    padding: 32px 32px 16px;
    margin: 0;
    box-sizing: border-box;
    h2 {
      margin-bottom: 31px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .environment-info {
      overflow: visible;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      section + section {
        margin-left: 32px;
      }
    }
    .apps-services {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    .environment-info.col-4 {
      display: inline-block;
      margin-right: 32px;
      flex-grow: 1;
      max-width: 437px;
      min-width: auto;
      section + section {
        margin-left: 0;
      }
    }
  }
`;
