import React from "react";
import PropTypes from "prop-types";

import Button from "UI/Button";
import Loading from "Components/Loading";
import { formatAsCurrency } from "Libs/utils";
import { Link } from "react-router-dom";
import {
  ChevronIcon,
  HeaderSS,
  LineItemSS,
  LineItemsListSS,
  OrderDetailSS,
  OrderInfoSS,
  OrderInfoBlockSS,
  OrderInfoHeaderSS,
  OrderInfoLineSS,
  OrderPreTotalSS,
  OrderPreTotalCurrencySS,
  OrderPreTotalItemSS,
  OrderPreTotalLabelSS,
  OrderTotalSS,
  OrderTotalAmountSS,
  OrderTotalsContainerSS,
  OrderTotalInfoSS,
  OrderTotalLabelSS,
  PDFButtonSS,
  ProjectTitleSS,
  ProjectTotalSS,
  TotalSS
} from "./OrderDetail.styles";

const OrderDetail = ({
  loading,
  order,
  billingCycle,
  showProjects,
  organizationId,
  showPDF
}) => {
  const items = lineItemsByOrder(order);

  if (loading) {
    return <Loading />;
  }

  return (
    <OrderDetailSS>
      <HeaderSS>
        {showProjects && (
          <>
            <div>Projects</div>
            <TotalSS>Total</TotalSS>
          </>
        )}
      </HeaderSS>
      {order && (
        <>
          <LineItems
            items={items}
            currency={order.components.base_price.currency}
            link={{
              pathname: `/-/users/${organizationId}/billing/history/${
                order.id
              }`,
              state: { from: "invoices" }
            }}
          />
          <OrderTotals
            components={order.components}
            currency={order.components.base_price.currency}
          />
          <OrderInfo
            order={order}
            billingCycle={billingCycle}
            showPDF={showPDF}
          />
        </>
      )}
    </OrderDetailSS>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.object,
  loading: PropTypes.bool,
  billingCycle: PropTypes.string,
  showProjects: PropTypes.bool,
  organizationId: PropTypes.string,
  showPDF: PropTypes.bool
};

const LineItems = ({ items, currency, link }) => {
  return (
    <LineItemsListSS>
      {Object.keys(items).map(subscriptionId => (
        <LineItem
          key={subscriptionId}
          title={items[subscriptionId].title}
          total={lineItemsTotal(items[subscriptionId].line_items, currency)}
          link={link}
        />
      ))}
    </LineItemsListSS>
  );
};

LineItems.propTypes = {
  items: PropTypes.object,
  currency: PropTypes.string,
  link: PropTypes.string
};

const LineItem = ({ title, total, link }) => {
  return (
    <LineItemSS>
      <ProjectTitleSS>{title || "Untitled Project"}</ProjectTitleSS>
      <ProjectTotalSS>{total}</ProjectTotalSS>
      <Link to={link}>
        <ChevronIcon />
      </Link>
    </LineItemSS>
  );
};

LineItem.propTypes = {
  title: PropTypes.string,
  total: PropTypes.string,
  link: PropTypes.string
};

const OrderTotals = ({ components, currency }) => {
  return (
    <OrderTotalsContainerSS>
      <OrderPreTotalSS>
        {Object.keys(components).map(component => (
          <OrderPreTotalItemSS key={components[component].display_title}>
            <OrderPreTotalLabelSS>
              {components[component].display_title}
            </OrderPreTotalLabelSS>

            <OrderPreTotalCurrencySS>
              {formatAsCurrency(
                components[component].amount,
                components[component].currency
              )}
            </OrderPreTotalCurrencySS>
          </OrderPreTotalItemSS>
        ))}
      </OrderPreTotalSS>
      <OrderTotalSS>
        <OrderTotalLabelSS>Order Total</OrderTotalLabelSS>
        <OrderTotalInfoSS>
          *This amount is subject to change based on account activity
        </OrderTotalInfoSS>
        <OrderTotalAmountSS>
          {orderTotal(components, currency)}
        </OrderTotalAmountSS>
      </OrderTotalSS>
    </OrderTotalsContainerSS>
  );
};

OrderTotals.propTypes = {
  components: PropTypes.object,
  currency: PropTypes.string
};

const OrderInfo = ({ order, billingCycle, showPDF }) => {
  const showPDFButton = showPDF && order.invoice_url;

  return (
    <OrderInfoSS>
      <OrderInfoBlockSS>
        <OrderInfoHeaderSS>Billing Information</OrderInfoHeaderSS>
        <OrderInfoLineSS>{order.address.name_line}</OrderInfoLineSS>
        <OrderInfoLineSS>{order.address.country}</OrderInfoLineSS>
      </OrderInfoBlockSS>
      {order.vat_number && (
        <OrderInfoBlockSS>
          <OrderInfoHeaderSS>EU VAT INFO: {order.vat_number}</OrderInfoHeaderSS>
        </OrderInfoBlockSS>
      )}

      <OrderInfoBlockSS>
        <OrderInfoHeaderSS>Billing cycle</OrderInfoHeaderSS>
        <OrderInfoLineSS>{billingCycle}</OrderInfoLineSS>
      </OrderInfoBlockSS>
      {showPDFButton && (
        <PDFButtonSS>
          <Button as="a" href={`${order.invoice_url}?download=1`}>
            Download PDF
          </Button>
        </PDFButtonSS>
      )}
    </OrderInfoSS>
  );
};

OrderInfo.propTypes = {
  order: PropTypes.object,
  billingCycle: PropTypes.string,
  showPDF: PropTypes.bool
};

function lineItemsByOrder(order) {
  let lineItemsByOrder;
  if (order && order.line_items) {
    lineItemsByOrder = order.line_items.reduce((items, item) => {
      if (!items[item.subscription_id]) {
        items[item.subscription_id] = { title: item.project, line_items: [] };
      }
      items[item.subscription_id].line_items.push(item);
      return items;
    }, {});
  }

  return lineItemsByOrder;
}

function orderTotal(components, currency) {
  const total = Object.keys(components).reduce((total, component) => {
    return total + components[component].amount;
  }, 0);

  return formatAsCurrency(total, currency);
}

function lineItemsTotal(lineItems, currency) {
  const total = lineItems.reduce((total, li) => {
    return total + li.total_price;
  }, 0);

  return formatAsCurrency(total, currency);
}

export default OrderDetail;
