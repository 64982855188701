import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";
import { LiveMessage } from "react-aria-live";

import { DOCS_GLOSSARY_INACTIVE_ENVIRONMENT_URL } from "Constants/documentationUrls";
import Grid from "Components/Grid";
import ErrorBoundary from "Components/ErrorBoundary";
import Heading2 from "Components/styleguide/Heading2";
import IconLink from "Icons/IconLink";
import IconLinkBroken from "Icons/IconLinkBroken";
import ButtonLink from "Components/ButtonLink";
import InfoDialog from "Components/InfoDialog";
import { checkGoLive, httpStatusDisplay, getRegionLabel } from "Libs/utils";

import PageMeta from "Components/PageMeta";
import AccessibleTooltip from "Components/AccessibleTooltip";

import {
  RegionSuffix,
  GridWrapper,
  Layout,
  Label,
  Value,
  ExternalEnvLink,
  ItemWrapper,
  EnvironmentType
} from "./styles";

const EnvironmentOverview = ({
  intl,
  environment,
  project,
  projectId,
  environmentId,
  organizationId,
  region,
  envUrl
}) => {
  const formatDate = date => {
    return moment(date).format("D MMM Y, kk:mm");
  };

  if (!environment) {
    return false;
  }

  const httpAccess = httpStatusDisplay(environment);
  const goLive = checkGoLive(project);
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "%d second",
      ss: "%d seconds",
      m: "%d minute",
      mm: "%d minutes",
      h: "%d hour",
      hh: "%d hours",
      d: "%d day",
      dd: "%d days",
      M: "%d month",
      MM: "%d months",
      y: "%d year",
      yy: "%d years"
    }
  });
  const regionLabel = getRegionLabel(region);

  return (
    <Layout>
      <div className="heading-wrapper">
        <PageMeta title={`${environment?.title} | ${project?.title}`} />
        <Heading2 id="environmnet-info-heading">{environment.title}</Heading2>
        <div>
          {environment.type && (
            <EnvironmentType>{environment.type}</EnvironmentType>
          )}
          {environment.is_main &&
            goLive &&
            (goLive === "live" ? (
              <span className="live">Live</span>
            ) : goLive === "development" ? (
              <ButtonLink
                to={project && project.data && project.data.plan_uri}
                className="go-live"
                text="Go live"
                external={true}
                blank={false}
              />
            ) : goLive === "no-permission" ? (
              ""
            ) : (
              <ButtonLink
                to={`/${organizationId}/${projectId}/-/settings/domains`}
                className="go-live"
                text="Go live"
              />
            ))}
        </div>
      </div>
      <div className="label">{!environment.is_main && "Development"}</div>
      <ErrorBoundary>
        <LiveMessage
          message={`${environment.title} overview`}
          aria-live="polite"
        />
        <GridWrapper>
          <Grid>
            <ItemWrapper className="environment-status">
              <div
                className={`status status-${environment.status}`}
                aria-labelledby="environment-overview-status"
              >
                <span className="bullet" />
                <span className="text">
                  {environment.status === "active" && "Active"}
                  {environment.status === "dirty" && "Building"}
                  {environment.status !== "active" &&
                    environment.status !== "dirty" &&
                    environment.status}
                </span>
              </div>
              <div className="label" id="environment-overview-status">
                Status{" "}
                <InfoDialog
                  title="Status"
                  text="To edit the status of this environment, review your general settings."
                  to={`/${organizationId}/${projectId}/${encodeURIComponent(
                    environmentId
                  )}/settings`}
                  linkText="View settings"
                  to2={DOCS_GLOSSARY_INACTIVE_ENVIRONMENT_URL}
                  linkText2="Learn more"
                />
              </div>
            </ItemWrapper>
            <ItemWrapper className="environment-access">
              <div
                className={`status status-${
                  httpAccess ? "enabled" : "disabled"
                }`}
                aria-labelledby="environment-overview-http-access"
              >
                {httpAccess ? "Enabled" : "Disabled"}
              </div>
              <div className="label" id="environment-overview-http-access">
                HTTP access
              </div>
            </ItemWrapper>
          </Grid>
          <Grid>
            <ItemWrapper className="environment-deployed">
              <div
                className="time-date"
                aria-labelledby="environment-overview-activity"
              >
                {formatDate(environment.last_active_at)}
              </div>
              <div className="label" id="environment-overview-activity">
                {intl.formatMessage({ id: "last_activity" })}
              </div>
            </ItemWrapper>

            <ItemWrapper className="environment-backup">
              {environment?.last_backup_at ? (
                <div
                  className="time-date"
                  aria-labelledby="environment-overview-backup"
                >
                  {formatDate(environment.last_backup_at)}
                </div>
              ) : (
                <div
                  className="time-date no-backup"
                  aria-labelledby="environment-overview-backup"
                >
                  {intl.formatMessage({ id: "no_backup" })}
                </div>
              )}
              <div className="label" id="environment-overview-backup">
                {intl.formatMessage({ id: "last_backup" })}
              </div>
            </ItemWrapper>
          </Grid>
          <Grid>
            <ItemWrapper className="environment-region">
              <div
                className="region-name"
                aria-labelledby="environment-overview-region"
              >
                <div className="value">
                  <span className="region-label">
                    {regionLabel?.title}
                    <RegionSuffix>{regionLabel?.suffix}</RegionSuffix>
                  </span>
                </div>
                <div className="label" id="environment-overview-region">
                  Region{/* {intl.formatMessage({ id: "machine_name" })} */}
                </div>
              </div>
            </ItemWrapper>
            <ItemWrapper>
              <div>
                <div
                  className="value"
                  aria-labelledby="environment-overview-machine-name"
                >
                  {environment.machine_name}
                </div>
                <div className="label" id="environment-overview-machine-name">
                  {intl.formatMessage({ id: "machine_name" })}
                </div>
              </div>
            </ItemWrapper>
          </Grid>
          <Grid>
            <ItemWrapper>
              <Value aria-labelledby="environment-overview-is-hidden-from-search-engines">
                {intl.formatMessage({
                  id: environment.restrict_robots ? "hidden" : "visible"
                })}
              </Value>
              <Label id="environment-overview-is-hidden-from-search-engines">
                {intl.formatMessage({ id: "search_engine_index" })}
              </Label>
            </ItemWrapper>
          </Grid>
        </GridWrapper>
        {environment.status === "inactive" ? (
          <div className="env-url inactive">
            <IconLinkBroken />
            Environment inactive
            <InfoDialog
              title="URL unavailable"
              text="This environment does not have a URL as the status is inactive. You can update your status in general settings."
              to={`/${organizationId}/${projectId}/${encodeURIComponent(
                environmentId
              )}/settings`}
              linkText="View settings"
              to2={DOCS_GLOSSARY_INACTIVE_ENVIRONMENT_URL}
              linkText2="Learn more"
            />
          </div>
        ) : (
          <div className="env-url">
            <IconLink />
            <AccessibleTooltip
              as="a"
              href={envUrl}
              rel="noopener noreferrer"
              target="_blank"
              tooltipProps={{
                id: "environment-url-full",
                children: envUrl
              }}
            >
              <ExternalEnvLink>{envUrl}</ExternalEnvLink>
            </AccessibleTooltip>
          </div>
        )}
      </ErrorBoundary>
    </Layout>
  );
};

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);

  return {
    environment,
    isLoading: state.environment.get("loading"),
    region: state.region?.getIn(["byUrl", props.project?.region], "")
  };
};

EnvironmentOverview.propTypes = {
  projectId: PropTypes.string,
  environmentId: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  environment: PropTypes.object,
  project: PropTypes.object,
  region: PropTypes.string,
  envUrl: PropTypes.string
};

export default connect(mapStateToProps)(injectIntl(EnvironmentOverview));
