import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import Error from "Components/LargeInlineError";
import PageMeta from "Components/PageMeta";

import * as S from "./styles";

const OrganizationSettings = ({ children }) => {
  const intl = useIntl();
  const { organizationId } = useParams();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [noEdit, setNoEdit] = useState();

  const username = useSelector(state => state.app?.getIn(["me", "username"]));

  useEffect(() => {
    if (username !== organizationId) {
      const noEdit = intl.formatMessage(
        {
          id: "no_edit_or_view"
        },
        {
          // eslint-disable-next-line react/display-name, react/no-multi-comp
          editType: intl.formatMessage({ id: "billing_details" })
        }
      );
      setNoEdit(noEdit);
    }
  }, []);

  return (
    <>
      <PageMeta title={intl.formatMessage({ id: "billing.documenttitle" })} />
      <NavBar
        push={push}
        projectId={""}
        organizationId={organizationId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>
        {noEdit ? (
          <S.ErrorBlock>
            <Error>{noEdit}</Error>
          </S.ErrorBlock>
        ) : (
          children
        )}
      </PageLayout>
    </>
  );
};

OrganizationSettings.propTypes = {
  children: PropTypes.node
};

export default OrganizationSettings;
