import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import Page from "./Page";
import GeneralSettingsPage from "./general/Page";
import AccessSettingsPage from "./access/Page";
import VariablesSettingsPage from "./variables/Page";

const Routes = () => {
  const match = useRouteMatch();

  return (
    <Page>
      <Route exact path={`${match.path}/access`}>
        <AccessSettingsPage />
      </Route>
      <Route exact path={`${match.path}/variables`}>
        <VariablesSettingsPage />
      </Route>
      <Route exact path={`${match.path}`}>
        <GeneralSettingsPage />
      </Route>
    </Page>
  );
};

export default Routes;
