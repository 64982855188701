import styled, { css } from "styled-components";

import { makePalette } from "Libs/theme";

const theme = makePalette(palette => ({
  tooltip_color: {
    default: palette.ebonyLight,
    dark: palette.snow
  },
  tooltip_background: {
    default: palette.snow,
    dark: palette.ebony
  }
}));

export const Layout = styled.g`
  opacity: 0;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `};
`;

export const Text = styled.text`
  && {
    fill: ${theme.tooltip_color};
  }
  stroke-width: 0.5;
`;

export const Background = styled.rect`
  fill: ${theme.tooltip_background};
`;
