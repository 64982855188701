import styled, { css } from "styled-components";
import { themeHelpers } from "Libs/theme";

export const EnvironmentServicesLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 7px;
  .service-tree-wrapper {
    background: none;
    margin-right: 32px;
    .services-tree {
      background: ${props => themeHelpers(props.theme, "sectionColor")};
    }
  }

  @media (max-device-width: 900px), (max-width: 900px) {
    display: flex;
    flex-direction: column-reverse;

    .service-tree-wrapper {
      background: none;
      margin-right: 0px;
      margin-top: 32px;
    }

    & > div {
      width: 100%;
      margin: 0px;
    }
  }

  ${props =>
    props.theme.name !== "dark" &&
    css`
      path.edgePath {
        stroke: #fff;
      }
    `};
`;

export const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
`;
