import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import momentjs from "moment";

import withReducers from "Hocs/withReducers";

import { entities } from "Libs/platform";
import { capitalize } from "Libs/utils";

import {
  getApiTokens,
  expandNewApiTokenForm,
  cancelAddNew,
  addApiToken,
  deleteApiToken,
  closeTokenBanner
} from "Reducers/apiToken";

import { DOCS_CLI_API_TOKENS_URL } from "Constants/documentationUrls";
import useMediaQuery from "Hooks/useMediaQuery";
import Button from "UI/Button";
import ButtonAdd from "Components/ButtonAdd";
import SettingLine from "Components/SettingLine";
import Loading from "Components/Loading";
import Error from "Components/Error";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import InfoIcon from "Icons/InfoIcon";
import ApiTokenForm from "../../components/ApiTokenForm";
import ApiTokenBanner from "../../components/ApiTokenBanner";

import * as S from "./styles";

const ApiTokenList = ({ organizationId }) => {
  // @todo: throw error if there is no access to user data.
  const dispatch = useDispatch();

  const [isDeleteModalOpen, toggleDeleteModal] = useState();
  const [tokenToDelete, setTokenToDelete] = useState();
  const [userIdForTokens, setUserIdForTokens] = useState();

  const apiToken = useSelector(({ apiToken }) => apiToken);
  const app = useSelector(({ app }) => app);
  const intl = useIntl();
  const theme = useTheme();
  const buttonText = useMediaQuery("(min-width: 768px)")
    ? "Create API Token"
    : "Create";
  const {
    loading,
    errors,
    canAddNew,
    newApiToken,
    currentlySavingToken
  } = apiToken.toJS();
  const tokenList = apiToken?.getIn(["data", organizationId]);
  const currentUserId = app?.getIn(["me", "id"]);
  const username = app?.getIn(["me", "username"]);
  const openAddNewForm = () => {
    dispatch(expandNewApiTokenForm());
  };
  const closeAddNewForm = () => dispatch(cancelAddNew());
  // THis should work now?
  const addToken = tokenName =>
    dispatch(
      addApiToken({ name: tokenName, userId: userIdForTokens, organizationId })
    );
  const deleteToken = tokenId =>
    dispatch(
      deleteApiToken({ tokenId, organizationId, userId: currentUserId })
    );
  const closeBanner = () => dispatch(closeTokenBanner());
  const isOtherUsersList = username !== organizationId;

  useEffect(
    () => {
      let isCanceled = false;
      (async () => {
        if (!tokenList) {
          if (isOtherUsersList) {
            const otherUser = await entities.AuthUser.getUserByUsername(
              organizationId
            );
            dispatch(getApiTokens({ userId: otherUser.id, organizationId }));
            if (isCanceled) {
              return;
            }
            setUserIdForTokens(otherUser.id);
            return;
          }
          dispatch(getApiTokens({ userId: currentUserId, organizationId }));
          if (isCanceled) {
            return;
          }
          setUserIdForTokens(currentUserId);
        }
      })();

      return () => (isCanceled = true);
    },
    [organizationId]
  );

  const existingTokenNames = tokenList
    ?.valueSeq()
    .map(token => token.name)
    .toJS();

  const deleteApiTokenTitle =
    intl.formatMessage({ id: "delete" }) +
    " " +
    intl.formatMessage({ id: "api_token" });

  return (
    <S.Container notificationVisible={newApiToken}>
      {newApiToken && (
        <>
          <ApiTokenBanner
            name={newApiToken.name}
            token={newApiToken.token}
            onClose={closeBanner}
          />
        </>
      )}
      <S.HeaderSection>
        <S.PageTitle>
          <FormattedMessage id="api_tokens" />
        </S.PageTitle>
        {!isOtherUsersList && (
          <ButtonAdd
            id="add-new-api-token"
            customText={buttonText}
            onClick={openAddNewForm}
          />
        )}
      </S.HeaderSection>
      <S.HelpTextIntro>
        <S.HelpInfo
          icon={<InfoIcon />}
          iconColor={theme.envTreeInactiveIcon}
          text={intl.formatMessage(
            {
              id: "api_token_info_text"
            },
            {
              cta: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={DOCS_CLI_API_TOKENS_URL}
                >
                  {intl.formatMessage({ id: "documentation" })}
                </a>
              )
            }
          )}
        />{" "}
        <FormattedMessage id="api_token_help_text" />
      </S.HelpTextIntro>
      {errors && <Error>{errors}</Error>}
      {loading && <Loading type={intl.formatMessage({ id: "api_tokens" })} />}
      {canAddNew && (
        <SettingLine
          info={intl.formatMessage({ id: "api_token_create" })}
          openText="New"
          isOpen={true}
          onClick={() => {}}
          noToggle={false}
        >
          <ApiTokenForm
            addToken={addToken}
            cancel={closeAddNewForm}
            currentlySavingToken={currentlySavingToken}
            existingTokens={existingTokenNames}
          />
        </SettingLine>
      )}
      <S.TokenList>
        {tokenList &&
          tokenList.valueSeq().map(token => (
            <SettingLine
              key={token.id}
              info={
                <S.TokenMeta>
                  <S.TokenName>{token.name}</S.TokenName>
                  <S.TokenCreatedDate>
                    {momentjs(token.created_at).format("DD MMM YYYY")}
                  </S.TokenCreatedDate>
                </S.TokenMeta>
              }
              noToggle={true}
              closedStyle
              customAction={
                <Button
                  // data-uikit allows us to exclude this button
                  // from picking legacy nested CSS rules
                  data-uikit
                  variant="link"
                  onClick={() => {
                    setTokenToDelete(token);
                    toggleDeleteModal(true);
                  }}
                >
                  {capitalize(intl.formatMessage({ id: "delete" }))}
                </Button>
              }
            />
          ))}
      </S.TokenList>
      {tokenToDelete && (
        <ModalConfirmDelete
          isOpen={isDeleteModalOpen}
          closeModal={toggleDeleteModal}
          deleteFunction={() => deleteToken(tokenToDelete?.id)}
          itemType={intl.formatMessage({ id: "api_tokens" })}
          title={deleteApiTokenTitle}
          body={intl.formatMessage(
            { id: "api_token_delete_warning" },
            { tokenName: tokenToDelete?.name }
          )}
          deleteText={deleteApiTokenTitle}
          itemName={tokenToDelete?.name}
          itemId={tokenToDelete?.id}
        />
      )}
    </S.Container>
  );
};

ApiTokenList.propTypes = {
  intl: PropTypes.object,
  organizationId: PropTypes.string
};

export default withReducers({ apiToken: () => import("Reducers/apiToken") })(
  ApiTokenList
);
