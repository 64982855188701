import React from "react";
import { useParams } from "react-router-dom";

import ContentLayout from "Components/ContentLayout";
import SshKeyListField from "./containers/SshKeyListField";

import withReducers from "Hocs/withReducers";

const Page = () => {
  const { organizationId } = useParams();

  return (
    <ContentLayout id="settings-members" className="settings">
      <SshKeyListField organizationId={organizationId} />
    </ContentLayout>
  );
};

export default withReducers({
  organization: () => import("Reducers/organization"),
  userSshKey: () => import("Reducers/organization/settings/sshKey")
})(Page);
