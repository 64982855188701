import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import Title from "Components/styleguide/Heading2";

export const Wrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
  .setting-line-info {
    width: 100%;
  }

  .setting-content {
    width: calc(100% - 60px);
  }
`;

export const VariableInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VariableInfoMain = styled.div`
  width: calc(100% - 344px);
  max-width: calc(100% - 344px);
  min-width: calc(100% - 344px);
  display: flex;
  align-items: center;
`;
export const VariableName = styled.div`
  margin-right: 4px;
`;

export const VariableValue = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-left: 4px;
`;

export const VariableInfoLabels = styled.div`
  color: ${props => props.theme.settingBarLabel};
  font-size: 13px;
  min-width: 314px;
  ${semiBoldAlias};
`;

export const Heading2 = styled(Title)`
  margin-bottom: 16px;
`;
