import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const SettingLineWrapper = styled.div`
  .setting-line {
    display: flex;
    flex-direction: row;
    .setting-content {
      width: 75%;
    }
    .action {
      width: 60px;
      margin-left: auto;
    }
  }
  .variable-info {
    display: flex;
    align-items: center;
  }
  .variable-info-main {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .variable-name {
    margin-right: 4px;
  }
  .variable-value {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-left: 4px;
  }
  .inherited,
  .labels {
    color: ${props => props.theme.settingBarLabel};
    font-size: 13px;
    ${semiBoldAlias};
  }
  .inherited {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
  .info-dialog {
    margin-right: 8px;
    margin-left: 0;
    display: inline-flex;
    .dialog > div {
      width: 300px;
    }
    div:nth-of-type(2) {
      text-align: left;
      width: auto;
    }
  }
  .disabled {
    margin-left: 8px;
    ${semiBoldAlias};
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
  svg > path {
    fill: ${props => props.theme.envLabel};
  }
`;
