import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Heading2 from "Components/styleguide/Heading2";

import IntegrationIcon from "../../../components/IntegrationIcon";
import * as S from "./styles";

const Header = ({ integration }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <IntegrationIcon type={integration.type} />
      <Heading2>
        {intl.formatMessage({ id: `integration.type.${integration.type}` })}
      </Heading2>

      {integration.category && (
        <S.Kind>
          {intl.formatMessage({
            id: `integrations.category.${integration.category}`,
            defaultMessage: integration.category
          })}
        </S.Kind>
      )}
    </S.Wrapper>
  );
};

Header.propTypes = {
  integration: PropTypes.object
};

export default Header;
